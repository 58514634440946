import { NavLink } from 'react-router-dom';
import {
    colorNeutralsBlack,
    colorNeutralsWhite,
    colorPrimaryMerBlue,
    colorPrimaryMerTurquoise,
    font,
    screenWidthMedium,
    transitionSnappy,
} from '~styles/variables';
import Icon from '~components/clickables/Icon';
import React from 'react';
import styled from '@emotion/styled';

type NavigationLinkProps = {
    className?: string;
    exact?: boolean;
    heading: string;
    icon: React.ReactNode;
    target?: string | undefined;
    title?: string;
    to: string | { pathname: string; search: string };
};

export default function NavigationLink(props: NavigationLinkProps): JSX.Element {
    const { to, title, heading, icon, target, exact } = props;
    return (
        <NavigationLinkContainer to={to} title={title ?? heading} target={target} end={exact}>
            <Icon>{icon}</Icon>
            <span className={'title'}>{heading}</span>
        </NavigationLinkContainer>
    );
}

export const NavigationLinkContainer = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 1rem;

    padding: 1.25rem 1rem;
    cursor: pointer;
    position: relative;

    color: ${colorNeutralsBlack};
    outline: none;
    text-decoration: none;
    transition: color ${transitionSnappy};

    width: 100%;

    & .icon {
        flex: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & svg {
            height: 24px;
            width: 24px;
            fill: currentColor;
            transition: all ${transitionSnappy};
        }
    }

    & .title {
        flex: 1;
        font-size: ${font.size.m};
        line-height: ${font.lineHeight.m};
        font-family: 'SharpSans Semibold', sans-serif;
    }

    &:hover {
        color: ${colorPrimaryMerBlue};
        background: ${colorNeutralsWhite};
    }

    &:focus {
        outline: none;
    }

    &.active {
        color: ${colorPrimaryMerBlue};

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            background-color: ${colorPrimaryMerTurquoise};
        }
    }

    @media screen and (max-width: ${screenWidthMedium}) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        &.active:before {
            left: auto;
            right: 0;
        }
    }
`;
