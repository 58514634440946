import { useMemo } from 'react';
import { useUser } from '~contexts/User';
import ApiError from '~classes/ApiError';
import ISubscription from '~interfaces/Subscription';
import useApi from '~contexts/Api';
import useSWR from 'swr';

export type UseOrganizationSubscriptionsResponse = {
    activeSubscriptions: ISubscription[];
    error: ApiError | undefined;
    loading: boolean | undefined;
    subscriptions: ISubscription[];
};

export type UseOrganizationSubscriptionsProps = {
    organizationId?: string;
};

const BASE_PATH = '/organizations';

export default function useOrganizationSubscriptions(
    props?: UseOrganizationSubscriptionsProps,
): UseOrganizationSubscriptionsResponse {
    const { activeOrganizationId } = useUser();
    const { organizationId = activeOrganizationId } = props ?? {};
    const { get } = useApi();

    const path = useMemo(() => (organizationId ? `${BASE_PATH}/${organizationId}/subscriptions` : null), []);

    const {
        data: subscriptions = [],
        error,
        isValidating: loading,
    } = useSWR<ISubscription[], ApiError>(path, get, {
        revalidateOnFocus: false,
        errorRetryCount: 0,
    });

    const activeSubscriptions = useMemo(() => {
        const currentDate = new Date();
        return subscriptions.filter((subscription) => {
            const validToDate = subscription.validTo
                ? new Date(new Date(subscription.validTo).setHours(23, 59, 59))
                : undefined;
            return validToDate ? validToDate > currentDate : true;
        });
    }, [subscriptions]);

    return {
        activeSubscriptions,
        subscriptions,
        error,
        loading,
    };
}
