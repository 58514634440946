import {
    colorIllustrationLightBlue,
    colorNeutralsBlack,
    colorNeutralsWhite,
    colorOnWhiteLighterGrey,
    colorPrimaryMerBlue,
    font,
    readingWidth,
    screenWidthSmall,
    transitionSnappy,
} from '~styles/variables';
import { css } from '@emotion/react';

export const GlobalStyle = css`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        font-size: 16px;
        // Fix for iPhone X
        padding: env(safe-area-inset);
    }

    body {
        background-color: ${colorNeutralsWhite};
        color: ${colorNeutralsBlack};
        font-size: 1rem;
        font-family: 'SharpSans Medium', sans-serif;
        font-weight: ${font.weight.regular};
        -webkit-font-smoothing: antialiased;

        &.ReactModal__Body--open {
            overflow: hidden;
        }
    }

    .ReactModal__Overlay--after-open {
        z-index: 9999;
        overflow-y: auto;
    }

    h1,
    h2,
    h3,
    h4 {
        font-weight: normal;
    }

    h1,
    .styled-h1 {
        margin-bottom: 2.5rem;
        font-family: 'SharpSans Bold', sans-serif;
        font-size: calc(1vw + 1.3rem);
        line-height: calc(1vw + 2rem);
        @media screen and (min-width: ${screenWidthSmall}) {
            font-size: ${font.size.xxl};
            line-height: 3rem;
        }
    }

    h2 {
        margin-bottom: 0.5rem;
        font-size: ${font.size.xl};
        font-family: 'SharpSans Bold', sans-serif;
    }

    h3,
    h2.styled-h3 {
        margin-bottom: 1rem;
        font-size: ${font.size.l};
        line-height: ${font.size.l};
        font-family: 'SharpSans Semibold', sans-serif;
    }

    h4 {
        font-size: ${font.size.m};
    }

    p {
        margin-bottom: 1rem;
        max-width: ${readingWidth};
        line-height: ${font.lineHeight.l};
    }

    a {
        color: ${colorPrimaryMerBlue};
        transition: all ${transitionSnappy};

        &:hover {
            background-color: ${colorOnWhiteLighterGrey};
        }

        &:focus {
            outline: 4px solid ${colorIllustrationLightBlue};
        }
    }

    strong {
        font-weight: normal;
        font-family: 'SharpSans Semibold', sans-serif;
    }

    .reading-max-width {
        max-width: ${readingWidth};
    }

    a.button-link {
        text-decoration: none;
    }

    .link-button {
        text-decoration: none;
    }
`;
