import { ELanguage } from '~@types';
import en_aftermarketOrdering from '~locales/en/aftermarketOrdering.json';
import en_common from '~locales/en/translation.json';
import en_organization from '~locales/en/organization.json';
import en_payment from '~locales/en/payment.json';
import en_productOrder from '~locales/en/productOrder.json';
import en_user from '~locales/en/user.json';
import en_verification from '~locales/en/verification.json';
import no_aftermarketOrdering from '~locales/no/aftermarketOrdering.json';
import no_common from '~locales/no/translation.json';
import no_organization from '~locales/no/organization.json';
import no_payment from '~locales/no/payment.json';
import no_productOrder from '~locales/no/productOrder.json';
import no_user from '~locales/no/user.json';
import no_verification from '~locales/no/verification.json';
import sv_aftermarketOrdering from '~locales/sv/aftermarketOrdering.json';
import sv_common from '~locales/sv/translation.json';
import sv_organization from '~locales/sv/organization.json';
import sv_payment from '~locales/sv/payment.json';
import sv_productOrder from '~locales/sv/productOrder.json';
import sv_user from '~locales/sv/user.json';
import sv_verification from '~locales/sv/verification.json';

export const defaultNS = 'common';
export const resources = {
    [ELanguage.NO]: {
        aftermarketOrdering: no_aftermarketOrdering,
        common: no_common,
        organization: no_organization,
        user: no_user,
        verification: no_verification,
        payment: no_payment,
        productOrder: no_productOrder,
    },
    [ELanguage.SV]: {
        aftermarketOrdering: sv_aftermarketOrdering,
        common: sv_common,
        organization: sv_organization,
        user: sv_user,
        verification: sv_verification,
        payment: sv_payment,
        productOrder: sv_productOrder,
    },
    [ELanguage.EN]: {
        aftermarketOrdering: en_aftermarketOrdering,
        common: en_common,
        organization: en_organization,
        user: en_user,
        verification: en_verification,
        payment: en_payment,
        productOrder: en_productOrder,
    },
} as const;
