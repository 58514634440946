import { EMembershipType } from '~interfaces/Benefits';
import { EOrganizationClassification } from '~interfaces/Organization';
import { ERefundPeriod } from '~interfaces/Refund';
import { ERoleId } from '~interfaces/Invitation';
import { NavigationSection } from '~interfaces/Navigation';
import { ORGANIZATION_NAVIGATION_SECTIONS, USER_NAVIGATION_SECTIONS } from '~@constants/navigation';
import { useMemo } from 'react';
import { useUser } from '~contexts/User';
import useMemberships from '~hooks/useMemberships';
import useOrganizationRefunds from '~hooks/useOrganizationRefunds';
import useOrganizationSubscriptions from './useOrganizationSubscriptions';
import useSubscriptions from '~hooks/useSubscriptions';

type UseNavigationSectionsResponse = {
    organizationNavigation?: {
        showSubscriptions?: boolean;
    };
    sections: NavigationSection[];
    userNavigation?: {
        showBenefits?: boolean;
        showSubscriptions?: boolean;
    };
};

export default function useNavigationSections(): UseNavigationSectionsResponse {
    const { activeRole } = useUser();
    const { refunds: refundsCurrent } = useOrganizationRefunds({ refundPeriod: ERefundPeriod.CURRENT });
    const { refunds: refundsPrevious } = useOrganizationRefunds({
        refundPeriod:
            activeRole?.organizationClassification === EOrganizationClassification.COMPANY
                ? ERefundPeriod.PREVIOUS
                : undefined,
    });

    const { subscriptions: organizationSubscriptions } = useOrganizationSubscriptions();
    const { memberships } = useMemberships();
    const { subscriptions: userSubscriptions } = useSubscriptions();

    const isOrganizationAdminRole = useMemo(
        () =>
            activeRole?.roleId === ERoleId.ADMINISTRATOR &&
            [
                EOrganizationClassification.COMPANY,
                EOrganizationClassification.PUBLIC,
                EOrganizationClassification.COOPERATIVE,
            ].includes(activeRole.organizationClassification),
        [activeRole],
    );

    const userNavigation: UseNavigationSectionsResponse['userNavigation'] = useMemo(
        () => ({
            showSubscriptions:
                (memberships?.filter((m) => m.type !== EMembershipType.COOP)?.length ?? 0) > 0 ||
                userSubscriptions?.length > 0,
            showBenefits: !(
                activeRole?.roleId === ERoleId.MEMBER &&
                [
                    EOrganizationClassification.COMPANY,
                    EOrganizationClassification.PUBLIC,
                    EOrganizationClassification.COOPERATIVE,
                ].includes(activeRole.organizationClassification)
            ),
        }),
        [activeRole?.organizationClassification, activeRole?.roleId, memberships, userSubscriptions?.length],
    );
    const organizationNavigation: UseNavigationSectionsResponse['organizationNavigation'] = useMemo(() => ({}), []);

    const sections = useMemo(() => {
        if (!isOrganizationAdminRole)
            return (memberships?.filter((m) => m.type !== EMembershipType.COOP)?.length ?? 0) > 0 ||
                userSubscriptions?.length > 0
                ? USER_NAVIGATION_SECTIONS
                : USER_NAVIGATION_SECTIONS.filter((menu) => menu.url !== '/account/subscriptions');

        const refunds =
            (refundsCurrent && refundsCurrent.length > 0) ||
            (refundsPrevious && refundsPrevious.length > 0) ||
            refundsCurrent.reduce((acc, refund) => acc + refund.revenueSharingPercentage, 0) > 0 ||
            refundsPrevious.reduce((acc, refund) => acc + refund.revenueSharingPercentage, 0) > 0;

        const refundFilteredMenu = !refunds
            ? ORGANIZATION_NAVIGATION_SECTIONS.filter((menu) => menu.url !== '/organization/account/refund')
            : ORGANIZATION_NAVIGATION_SECTIONS;

        return organizationSubscriptions?.length > 0
            ? refundFilteredMenu
            : refundFilteredMenu.filter((menu) => menu.url !== '/organization/account/subscriptions');
    }, [
        isOrganizationAdminRole,
        refundsCurrent,
        refundsPrevious,
        organizationSubscriptions,
        userSubscriptions,
        memberships,
    ]);

    return {
        sections,
        userNavigation,
        organizationNavigation,
    };
}
