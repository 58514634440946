import { EMembershipType, IMembership } from '~interfaces/Benefits';
import { ETrackingEvent } from '~@types/tracking';
import { SWR_CONFIG_RETRY_LONG } from '~@constants/swr';
import { useUser } from '~contexts/User';
import ApiError from '~classes/ApiError';
import ApiResponse from '~interfaces/ApiResponse';
import useAnalytics from '~hooks/useAnalytics';
import useApi from '~contexts/Api';
import useSWR, { mutate } from 'swr';

const SEND_COOP_ID_WITH_MEMBERSHIP_REQUESTS = process.env.REACT_APP_SEND_COOP_ID_WITH_MEMBERSHIP_REQUESTS === 'true';

type UseMembershipsResponse = {
    add: (type: EMembershipType) => Promise<void>;
    connect: (
        type: EMembershipType,
        membershipNumber: string,
        coopToken?: string,
        coopId?: string,
    ) => Promise<ApiResponse>;
    error?: ApiError;
    loading: boolean;
    memberships?: IMembership[];
    remove: (type: EMembershipType, coopToken?: string, coopId?: string) => Promise<void>;
};

const BASE_PATH = '/users/me/benefits/memberships';

export default function useMemberships(): UseMembershipsResponse {
    const { post, del, get } = useApi();
    const { trackGA4Event } = useAnalytics();
    const { isAuthenticated } = useUser();

    const {
        data: memberships,
        error,
        isValidating: loading,
        mutate: mutateMemberships,
    } = useSWR<IMembership[], ApiError>(isAuthenticated ? BASE_PATH : null, get, SWR_CONFIG_RETRY_LONG);

    /**
     * Adds (creates) a new membership of given type to user account
     * @param type
     */
    async function add(type: EMembershipType) {
        await post(`${BASE_PATH}?membershipType=${type}`, null);
        await mutateMemberships();
        await mutate('/users/me/subscriptions');
        trackGA4Event(ETrackingEvent.MEMBERSHIP_ADD, { value: type });
    }

    async function connect(
        type: EMembershipType,
        membershipNumber: string,
        coopToken?: string,
        coopId?: string,
    ): Promise<ApiResponse> {
        const result = await post(
            `${BASE_PATH}?membershipNumber=${membershipNumber}&membershipType=${type}${
                coopToken ? '&coopToken=' + coopToken : ''
            }${coopId && SEND_COOP_ID_WITH_MEMBERSHIP_REQUESTS ? '&coopId=' + coopId : ''}`,
            {},
        );
        await mutateMemberships();
        await mutate('/users/me/subscriptions');
        trackGA4Event(ETrackingEvent.MEMBERSHIP_ADD, { value: type });
        return result;
    }

    async function remove(type: EMembershipType, coopToken?: string) {
        await del(`${BASE_PATH}?membershipType=${type}${coopToken ? '&coopToken=' + coopToken : ''}`);
        await mutateMemberships();
        await mutate('/users/me/subscriptions');
        trackGA4Event(ETrackingEvent.MEMBERSHIP_REMOVE, { value: type });
    }

    return {
        memberships,
        error,
        loading,
        add,
        connect,
        remove,
    };
}
