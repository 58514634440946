import Address from '~interfaces/Address';
import IUser from '~interfaces/User';
import IUserRole from '~interfaces/UserRoles';

export enum EOrganizationPaymentType {
    CARD = 'CARD',
    EHF = 'EHF',
    E_INVOICE = 'E_INVOICE',
    NONE = 'NONE',
    POSTAL = 'POSTAL',
}

export enum EOrganizationType {
    BUSINESS = 'BUSINESS',
    BUSINESS_LARGE = 'BUSINESS_LARGE',
    BUSINESS_PLUS = 'BUSINESS_PLUS',
    UNDEFINED = 'UNDEFINED',
    USER = 'USER',
}

export enum EOrganizationClassification {
    COMPANY = 'COMPANY', // Private company... we think
    COOPERATIVE = 'COOPERATIVE',
    FAMILY = 'FAMILY', // Family network
    MANUFACTURER_VENDOR = 'MANUFACTURER_VENDOR',
    PRIVATE = 'PRIVATE', // Private person... (yes as an organization classification)
    PUBLIC = 'PUBLIC', // Public company?  We can't be sure yet...
    SERVICE_PARTNER = 'SERVICE_PARTNER',
}

export enum EOrganizationStatus {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    DELETE_REQUESTED = 'DELETE_REQUESTED',
}

export interface IOrganization {
    businessAddress: {
        addressLine1: string;
        addressLine2?: string;
        city: string;
        country: string;
        postalCode: string;
    };
    businessPhone?: string;
    ehfNumber: string;
    email: string;
    id: string;
    invoiceAddress: {
        addressLine1: string;
        addressLine2: string;
        city: string;
        country: string;
        postalCode: string;
    };
    invoiceEmail: string;
    invoiceReference: string;
    name: string;
    organizationClassification: EOrganizationClassification;
    organizationNumber?: string;
    organizationSettings: {
        emailConsented?: boolean;
        termsAccepted?: boolean;
    };
    organizationType: EOrganizationType;
    paymentType: EOrganizationPaymentType;
    status: EOrganizationStatus;
}

export default interface IOrganizationSummary {
    businessAddress: Partial<Address>;
    businessPhone: string;
    email: string;
    id: string;
    invoiceAddress: Partial<Address>;
    invoiceReference: string;
    name: string;
    organizationClassification: EOrganizationClassification;
    organizationType: EOrganizationType;
    paymentType: EOrganizationPaymentType.NONE;
}

export interface IOrganizationMember {
    email: string;
    fullName: string;
    phoneNumber: string;
    roles: IUserRole[];
    userId: string;
}

export type OrganizationContact = Pick<IUser, 'firstName' | 'lastName' | 'privatePhone' | 'email' | 'paymentType'> & {
    invitationId?: string;
    privateAddress: Pick<Address, 'addressLine1' | 'postalCode' | 'city' | 'country'>;
    userSettings: Pick<IUser['userSettings'], 'termsAccepted' | 'emailConsented' | 'language'>;
};
