import { EOrganizationClassification } from '~interfaces/Organization';
import { ERefundPeriod } from '~interfaces/Refund';
import { INavigationGroup, INavigationGroupLink } from '~components/navigation/NavigationGroup';
import { useTranslation } from 'react-i18next';
import { useUser } from '~contexts/User';
import IconBenefits from '~components/icons/IconBenefits';
import IconFacilityManagement from '~components/icons/IconFacilityManagement';
import IconNetwork from '~components/icons/IconNetwork';
import IconOverview from '~components/icons/IconOverview';
import IconStatistics from '~components/icons/IconStatistics';
import NavigationGroupList from '~components/navigation/NavigationGroupList';
import NavigationLink from '~components/navigation/NavigationLink';
import React, { useMemo } from 'react';
import useOrganizationChargelocations from '~hooks/useOrganizationChargelocations';
import useOrganizationRefunds from '~hooks/useOrganizationRefunds';

const FACILITY_STASTISTICS_HUB = process.env.REACT_APP_FACILITY_STASTISTICS_HUB === 'true';
const FACILITY_MANAGEMENT_HUB = process.env.REACT_APP_FACILITY_MANAGEMENT_HUB === 'true';

export default function OrganizationNavigation(): JSX.Element {
    const { t } = useTranslation(['organization']);
    const { activeRole } = useUser();
    const { chargelocations } = useOrganizationChargelocations();
    const { refunds: refundsCurrent } = useOrganizationRefunds({ refundPeriod: ERefundPeriod.CURRENT });
    const { refunds: refundsPrevious } = useOrganizationRefunds({
        refundPeriod:
            activeRole?.organizationClassification === EOrganizationClassification.COMPANY
                ? ERefundPeriod.PREVIOUS
                : undefined,
    });

    const showFacilityStatistics = chargelocations?.length && FACILITY_STASTISTICS_HUB;
    const showFacilityManagement = chargelocations?.length && FACILITY_MANAGEMENT_HUB;
    const showFacility = showFacilityManagement || showFacilityStatistics;
    const showRefunds =
        (refundsCurrent && refundsCurrent.length > 0) ||
        (refundsPrevious && refundsPrevious.length > 0) ||
        refundsCurrent.reduce((acc, refund) => acc + refund.revenueSharingPercentage, 0) > 0 ||
        refundsPrevious.reduce((acc, refund) => acc + refund.revenueSharingPercentage, 0) > 0;

    const navigationGroups: INavigationGroup[] = useMemo(() => {
        return [
            {
                title: t('organization:navigation.group.customerRelations'),
                icon: <IconNetwork />,
                links: [
                    {
                        to: '/organization/account/profile',
                        title: t('organization:navigation.profileOrganization'),
                    },
                    {
                        to: '/organization/account/payment',
                        title: t('organization:navigation.payments'),
                    },
                    {
                        to: '/organization/rfid',
                        title: t('organization:navigation.rfids'),
                    },
                    {
                        to: '/organization/network',
                        title: t('organization:navigation.network'),
                    },
                    {
                        to: '/organization/account/subscriptions',
                        title: t('organization:navigation.subscriptions'),
                    },
                ],
            },
            showFacility
                ? {
                      title: t('organization:navigation.group.chargingFacility'),
                      icon: <IconFacilityManagement />,
                      links: [
                          showFacilityStatistics
                              ? {
                                    to: '/organization/statistics',
                                    title: t('organization:navigation.facilityStatistics'),
                                }
                              : undefined,
                          showFacilityManagement
                              ? {
                                    to: '/organization/management/overview',
                                    title: t('organization:navigation.facilityManagement'),
                                }
                              : undefined,
                          showRefunds
                              ? {
                                    to: '/organization/account/refund',
                                    title: t('organization:navigation.refunds'),
                                }
                              : undefined,
                      ],
                  }
                : undefined,
        ]
            .map((g) => (g ? { ...g, links: g.links.filter((l): l is INavigationGroupLink => !!l) } : undefined))
            .filter((g): g is INavigationGroup => !!g);
    }, [showFacility, showFacilityManagement, showFacilityStatistics, showRefunds, t]);

    return (
        <>
            <NavigationLink
                to={'/organization'}
                title={t('organization:page.home.navigation.title')}
                heading={t('organization:page.home.navigation.heading')}
                icon={<IconOverview />}
                exact
            />
            <NavigationLink
                to={'/organization/charging-journey'}
                title={t('organization:page.chargingJourney.navigation.title')}
                heading={t('organization:page.chargingJourney.navigation.heading')}
                icon={<IconStatistics />}
            />
            <NavigationLink
                to={'/organization/benefits'}
                title={t('organization:navigation.benefits')}
                heading={t('organization:navigation.benefits')}
                icon={<IconBenefits />}
            />
            <NavigationGroupList groups={navigationGroups} />
        </>
    );
}
