import { SWRConfiguration } from 'swr';

const REACT_APP_SLOW_DOWN_SWR_RETRIES = process.env.REACT_APP_SLOW_DOWN_SWR_RETRIES === 'true';

export const nonExponentialErrorRetry: SWRConfiguration['onErrorRetry'] = (
    error,
    key,
    config,
    revalidate,
    { retryCount },
) => {
    if (config.errorRetryCount && retryCount >= config.errorRetryCount) return;
    setTimeout(() => void revalidate({ retryCount }), config.errorRetryInterval);
};

const SWR_SUPER_SLOW_RETRY: SWRConfiguration = {
    revalidateOnFocus: false,
    shouldRetryOnError: true,
    errorRetryCount: 120,
    errorRetryInterval: 5000,
    onErrorRetry: nonExponentialErrorRetry,
};

export const SWR_CONFIG_DEFAULT: SWRConfiguration = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
};

export const SWR_CONFIG_RETRY_SHORT: SWRConfiguration = REACT_APP_SLOW_DOWN_SWR_RETRIES
    ? SWR_SUPER_SLOW_RETRY
    : {
          revalidateOnFocus: false,
          shouldRetryOnError: true,
          errorRetryCount: 5,
          errorRetryInterval: 6000,
          onErrorRetry: nonExponentialErrorRetry,
      };

export const SWR_CONFIG_RETRY_MEDIUM: SWRConfiguration = REACT_APP_SLOW_DOWN_SWR_RETRIES
    ? SWR_SUPER_SLOW_RETRY
    : {
          revalidateOnFocus: false,
          shouldRetryOnError: true,
          errorRetryCount: 20,
          errorRetryInterval: 6000,
          onErrorRetry: nonExponentialErrorRetry,
      };

export const SWR_CONFIG_RETRY_LONG: SWRConfiguration = REACT_APP_SLOW_DOWN_SWR_RETRIES
    ? SWR_SUPER_SLOW_RETRY
    : {
          revalidateOnFocus: false,
          shouldRetryOnError: true,
          errorRetryCount: 40,
          errorRetryInterval: 6000,
          onErrorRetry: nonExponentialErrorRetry,
      };
