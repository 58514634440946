import { useTranslation } from 'react-i18next';
import FullPageWorkingIndicator from '~components/indicators/FullPageWorkingIndicator';
import React, { useEffect } from 'react';

type ExternalRedirectProps = {
    to: string;
};

export default function ExternalRedirect(props: ExternalRedirectProps): JSX.Element {
    const { to } = props;
    const { t } = useTranslation(['common']);
    useEffect(() => {
        if (to) window.location.href = to;
    }, [to]);
    return <FullPageWorkingIndicator text={t('busy.loading.page')} />;
}
