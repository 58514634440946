import { EOrganizationClassification } from '~interfaces/Organization';
import { ERoleId } from '~interfaces/Invitation';
import { EllipsisText } from '~components/layout';
import { UserRoleLink } from '~components/navigation/NavigationRoleLinks';
import {
    colorIllustrationLighterBlue,
    colorNeutralsWhite,
    colorOnWhiteDarkGrey,
    colorOnWhiteLightGrey,
    colorPrimaryMerBlue,
    font,
    screenWidthMedium,
    screenWidthSmall,
} from '~styles/variables';
import { useModal } from '~contexts/Modal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '~contexts/User';
import AppGenericLinks from '~components/navigation/AppGenericLinks';
import React, { useCallback, useMemo } from 'react';
import RoleLink from '~components/roles/RoleLink';
import RoleSelectModal from '~components/roles/RoleSelectModal';
import styled from '@emotion/styled';
import useOrganization from '~hooks/useOrganization';

export enum EAccountNavigationVariant {
    NORMAL = 'normal',
    SIMPLE = 'simple',
}

type AccountNavigationProps = {
    toggleUserNavigation?: () => void;
    userEmail?: string;
    userName: string;
    variant?: EAccountNavigationVariant;
};

/*
LOGIC: Is supper oddly complex

  1. A resident role is not treated as selectable its only shown as an additional membership in the users network page
  2. If a user only has a single member role, we don't display any roles
  3. If a user only has a single family admin role, we don't display any roles
  4. If a user has admin roles we display them as selectable and show a selectable base user role
  4. If a user has admin roles and a family role, we show the admin roles as selectable and show the selectable family role in place of the base user role

 */

export default function AccountNavigation(props: AccountNavigationProps): JSX.Element {
    const { userName, userEmail, toggleUserNavigation, variant = EAccountNavigationVariant.NORMAL } = props;
    const { t } = useTranslation(['common']);
    const navigate = useNavigate();
    const { roles, setActiveRoleId, activeRole } = useUser();
    const { organization } = useOrganization({ organizationId: activeRole?.organizationId });

    const smbRole = useMemo(() => roles?.find((r) => r.roleId === ERoleId.SMB) ?? undefined, [roles]);

    // Users can have multiple non family admin roles
    const adminRoles = useMemo(
        () =>
            roles?.filter(
                (r) =>
                    r.roleId === ERoleId.ADMINISTRATOR &&
                    r.organizationClassification !== EOrganizationClassification.FAMILY,
            ) ?? [],
        [roles],
    );

    // Users should only ever have 1 family admin role
    const familyAdminRole = useMemo(
        () =>
            roles?.find(
                (r) =>
                    r.roleId === ERoleId.ADMINISTRATOR &&
                    r.organizationClassification === EOrganizationClassification.FAMILY,
            ),
        [roles],
    );

    // Users should only ever have 1 member role
    const memberRole = useMemo(() => roles?.find((r) => r.roleId === ERoleId.MEMBER), [roles]);

    const activeAdminRole = useMemo(
        () =>
            activeRole &&
            activeRole.roleId === ERoleId.ADMINISTRATOR &&
            activeRole.organizationClassification !== EOrganizationClassification.FAMILY
                ? activeRole
                : undefined,
        [activeRole],
    );

    const changeRole = (userRoleId?: string) => {
        const role = roles?.find((r) => r.userRoleId === userRoleId);
        setActiveRoleId(userRoleId);
        toggleUserNavigation?.();
        navigate(
            role?.roleId === ERoleId.ADMINISTRATOR &&
                [
                    EOrganizationClassification.COMPANY,
                    EOrganizationClassification.PUBLIC,
                    EOrganizationClassification.COOPERATIVE,
                ].includes(role?.organizationClassification)
                ? '/organization'
                : '/',
        );
    };

    const hasMultipleAdminRoles = adminRoles.length > 1;

    const handleModal = useModal();

    const openRolesModal = useCallback(() => {
        toggleUserNavigation?.();
        handleModal?.open?.(<RoleSelectModal variant={variant} toggleUserNavigation={toggleUserNavigation} />);
    }, [handleModal, toggleUserNavigation, variant]);

    return (
        <Container className={variant}>
            <div>
                {variant === EAccountNavigationVariant.NORMAL ? (
                    smbRole ? (
                        <UserInfo>
                            <UserNameMenu>{organization?.name}</UserNameMenu>
                            {userEmail && <EllipsisText>{organization?.email}</EllipsisText>}
                        </UserInfo>
                    ) : (
                        <UserInfo>
                            <UserNameMenu>{userName}</UserNameMenu>
                            {userEmail && <EllipsisText>{userEmail}</EllipsisText>}
                        </UserInfo>
                    )
                ) : null}
                {smbRole ? null : familyAdminRole ? (
                    <RoleLink changeRole={changeRole} role={familyAdminRole} variant={variant} />
                ) : memberRole ? (
                    <RoleLink changeRole={changeRole} role={memberRole} variant={variant} />
                ) : (
                    <UserRoleLink changeRole={changeRole} variant={variant} />
                )}
                {hasMultipleAdminRoles && activeAdminRole ? (
                    <RoleLink changeRole={changeRole} role={activeAdminRole} variant={variant} />
                ) : null}
                {hasMultipleAdminRoles ? (
                    <SelectRoleContainer>
                        <SelectRoleLink onClick={openRolesModal}>{t('userNavigation.links.roles')}</SelectRoleLink>
                    </SelectRoleContainer>
                ) : (
                    adminRoles?.map((r) => (
                        <RoleLink key={r.userRoleId} changeRole={changeRole} role={r} variant={variant} />
                    ))
                )}
                <TextNavLinksContainer>
                    {variant === EAccountNavigationVariant.NORMAL ? <AppGenericLinks /> : null}
                </TextNavLinksContainer>
            </div>
        </Container>
    );
}

const Container = styled.nav`
    &.normal {
        position: fixed;
        //padding: 0 1rem 1rem 1rem;

        box-shadow: 0 4px 4px rgba(33, 33, 33, 0.08);

        z-index: 500;
        background-color: ${colorNeutralsWhite};
        overflow: hidden;

        top: 4rem;
        right: calc(1rem - 2px);
        padding: 0 0 1rem 0;
        width: 22rem;
        height: auto;
        border: 1px solid ${colorOnWhiteDarkGrey};
        border-radius: 2px;
    }

    &.simple {
        width: 100%;
        overflow: hidden;
    }

    & .links {
        padding: 1rem 0 0 0;
        border-top: 1px solid ${colorOnWhiteLightGrey};
        list-style: none;
        text-align: right;

        a {
            padding: 1rem 1rem;
        }

        & .logout {
            margin-top: 1rem;
            padding-top: 2rem;
            border-top: 1px solid ${colorOnWhiteLightGrey};
        }
    }

    a,
    a button {
        display: block;
        width: 100%;
        text-decoration: none;
        color: ${colorPrimaryMerBlue};
        text-align: left;
        font-family: 'SharpSans Semibold', sans-serif;

        &:hover {
            text-decoration: underline;
        }
    }

    > a {
        &:focus {
            button {
                background-color: ${colorIllustrationLighterBlue};
            }
        }
    }

    .desktop-only {
        display: none;
        visibility: hidden;
        @media screen and (min-width: ${screenWidthSmall}) {
            display: block;
            visibility: visible;
        }
    }
`;

const UserInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 1.5rem 0 1rem;
    font-size: ${font.size.s};
    line-height: ${font.lineHeight.xl};

    @media screen and (min-width: ${screenWidthSmall}) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
`;

const UserNameMenu = styled.span`
    display: block;
    visibility: visible;
    font-family: 'SharpSans Bold', sans-serif;
    font-size: 1.2rem;
`;

export const TextNavLinksContainer = styled.div`
    padding: 1rem 0 0 0;
    border-top: 1px solid ${colorOnWhiteLightGrey};
    list-style: none;
    text-align: right;

    a {
        padding: 1rem 1rem;
    }

    & .logout {
        margin-top: 1rem;
        padding-top: 2rem;
        border-top: 1px solid ${colorOnWhiteLightGrey};
    }

    a,
    a button {
        display: block;
        width: 100%;
        text-decoration: none;
        color: ${colorPrimaryMerBlue};
        text-align: left;
        font-family: 'SharpSans Semibold', sans-serif;

        &:hover {
            text-decoration: underline;
        }
    }

    > a {
        &:focus {
            button {
                background-color: ${colorIllustrationLighterBlue};
            }
        }
    }

    @media screen and (max-width: ${screenWidthMedium}) {
        a {
            padding-left: 1.5rem;
        }
    }
`;

const SelectRoleContainer = styled(TextNavLinksContainer)`
    padding-bottom: 1rem;
`;

const SelectRoleLink = styled.a`
    cursor: pointer;
`;
