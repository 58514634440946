import ApiError from '~classes/ApiError';
import ICreditCard from '~interfaces/CreditCard';
import useApi from '~contexts/Api';
import useSWR from 'swr';

type UseOrganizationPaymentCardsProps = {
    organizationId?: string;
};

type UseOrganizationPaymentCardsResponse = {
    cards: ICreditCard[] | null;
    error?: ApiError;
    loading?: boolean;
};

export const BASE_PATH = '/gk-paymentcard/getpaymentcarddetail';

export default function useOrganizationPaymentCards(
    props: UseOrganizationPaymentCardsProps,
): UseOrganizationPaymentCardsResponse {
    const { organizationId } = props;
    const { get } = useApi();

    const {
        data: cards = null,
        error,
        isValidating: loading,
    } = useSWR<ICreditCard[] | void, ApiError>(
        // We create a custom key using the organizationId, so we don't override the users get payment card detail request
        organizationId ? `/${organizationId}${BASE_PATH}` : null,
        () =>
            get(BASE_PATH, {
                omitVersion: true,
                headers: { organizationId: organizationId ?? 'organizationId not supplied' },
            }),
        {
            revalidateOnFocus: false,
        },
    );

    return {
        cards,
        error,
        // send back undefined on no organizationId to help ui determine real loading state
        loading: !organizationId ? undefined : loading,
    };
}
