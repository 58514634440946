import { colorNeutralsWhite, font, screenWidthSmall } from '~styles/variables';
import Busy from '~components/indicators/Busy';
import BusyElectricity from '~components/indicators/BusyElectricity';
import BusyVehicle from '~components/indicators/BusyVehicle';
import React, { memo, useEffect, useState } from 'react';
import Stepper, { StepperStep } from '~components/layout/Stepper';
import styled from '@emotion/styled';

export enum EWorkingIndicatorGraphic {
    LOGO,
    ELECTRICITY,
    VEHICLE,
}

type FullPageWorkingIndicatorProps = {
    fixed?: boolean;
    graphic?: EWorkingIndicatorGraphic;
    scrollText?: string[];
    stepperSteps?: StepperStep[];
    text?: string;
};

function FullPageWorkingIndicator(props: FullPageWorkingIndicatorProps): JSX.Element {
    const { text, scrollText, graphic = EWorkingIndicatorGraphic.LOGO, stepperSteps, fixed } = props;
    const [activeScrollText, setActiveScrollText] = useState<number>(0);

    useEffect(() => {
        let timeout: number | undefined;
        if (scrollText?.length)
            timeout = setTimeout(() => {
                setActiveScrollText(activeScrollText >= scrollText.length - 1 ? 0 : activeScrollText + 1);
            }, 4500) as unknown as number;

        return () => {
            if (timeout) clearInterval(timeout);
        };
    }, [scrollText, activeScrollText]);

    useEffect(() => {
        if (fixed) document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [fixed]);

    return (
        <FullPageContainer className={fixed ? 'fixed' : undefined}>
            {stepperSteps ? (
                <StepperContainer>
                    <Stepper steps={stepperSteps} />
                </StepperContainer>
            ) : null}
            {graphic === EWorkingIndicatorGraphic.LOGO ? <Busy /> : null}
            {graphic === EWorkingIndicatorGraphic.ELECTRICITY ? <BusyElectricity /> : null}
            {graphic === EWorkingIndicatorGraphic.VEHICLE ? <BusyVehicle /> : null}
            {text && <p>{text}</p>}
            {scrollText ? (
                <ScrollTextContainer>
                    {scrollText.map((text, i) => (
                        <div key={`scroll-text-${i}`} className={activeScrollText === i ? 'active' : 'inactive'}>
                            <p>{text}</p>
                        </div>
                    ))}
                </ScrollTextContainer>
            ) : null}
        </FullPageContainer>
    );
}

export const FullPageContainer = styled.div`
    background-color: ${colorNeutralsWhite};
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 6999;

    > p {
        margin-top: 1em;
    }

    &.fixed {
        position: fixed;
    }
`;

const ScrollTextContainer = styled.div`
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        80% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes scrollOut {
        0% {
            top: 0;
            opacity: 1;
        }
        35% {
            opacity: 0;
        }
        50% {
            top: -150%;
        }
        100% {
            top: 100%;
        }
    }

    @keyframes scrollIn {
        0% {
            top: 100%;
            opacity: 0;
        }
        100% {
            opacity: 1;
            top: 0;
        }
    }

    position: relative;
    height: 3rem;
    width: 320px;
    overflow: hidden;
    opacity: 0;
    animation: fadeIn 1.2s forwards linear 0s;
    margin: 3rem 0 0 0;

    div {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        animation: scrollOut 1.2s forwards linear 0s;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 0;

        &.active {
            animation: scrollIn 0.5s forwards linear 0s;
            top: 0;
        }
        p {
            margin: 0;
            font-size: ${font.size.l};
            font-weight: ${font.weight.semiBold};
            line-height: ${font.lineHeight.l};
        }
    }

    &.fixed div {
        position: fixed;
    }
`;

const StepperContainer = styled.div`
    position: absolute;
    top: 3.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    @media screen and (max-width: ${screenWidthSmall}) {
        top: 5.5rem;
    }
`;

export default memo(FullPageWorkingIndicator);
