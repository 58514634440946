import { ELanguage } from '~@types';
import { defaultNS, resources } from '~@types/i18nextResources';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

i18n.use(initReactI18next)
    .init({
        ns: ['common', 'organization', 'user', 'verification', 'payment', 'productOrder', 'aftermarketOrdering'],
        defaultNS,
        resources,
        lng: ELanguage.NO,
        fallbackLng: ELanguage.NO,
        keySeparator: '.',
        interpolation: { escapeValue: false },
    })
    .catch((error) => console.error((error as Error).message));

// noinspection JSUnusedGlobalSymbols
export default i18n;
