import { SWR_CONFIG_RETRY_LONG } from '~@constants/swr';
import ApiError from '~classes/ApiError';
import ISubscription from '~interfaces/Subscription';
import useApi from '~contexts/Api';
import useSWR from 'swr';

export type UseSubscriptionsResponse = {
    error: ApiError | undefined;
    loading: boolean | undefined;
    subscriptions: ISubscription[];
};

const BASE_PATH = '/users/me/subscriptions';

export default function useSubscriptions(): UseSubscriptionsResponse {
    const { get } = useApi();

    const {
        data: subscriptions = [],
        error,
        isValidating: loading,
    } = useSWR<ISubscription[], ApiError>(BASE_PATH, get, SWR_CONFIG_RETRY_LONG);

    return {
        subscriptions,
        error,
        loading,
    };
}
