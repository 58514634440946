{
    "translationsFor": "Mer Portal Swedish",
    "form": {
        "input": {
            "inviteeEmail": {
                "invalid": "",
                "required": ""
            },
            "rfidSerialNumber": {
                "invalid": "",
                "required": "",
                "label": ""
            },
            "rfidDescription": {
                "invalid": "",
                "label": ""
            },
            "memberNumber": {
                "invalid": "",
                "required": ""
            },
            "useSameInformation": {
                "label": ""
            },
            "firstName": {
                "label": "",
                "invalid": "",
                "required": ""
            },
            "lastName": {
                "label": "",
                "required": ""
            },
            "privatePhone": {
                "label": "",
                "invalid": "",
                "required": ""
            },
            "email": {
                "label": "",
                "invalid": "",
                "required": ""
            },
            "addressLine1": {
                "label": "",
                "invalid": "",
                "required": ""
            },
            "postalCode": {
                "label": "",
                "invalid": "",
                "required": ""
            },
            "city": {
                "label": ""
            },
            "country": {
                "label": "",
                "required": ""
            },
            "useSameAddress": {
                "label": ""
            },
            "installationInstructions": {
                "label": "",
                "help": ""
            },
            "lastname": {
                "invalid": ""
            },
            "region": {
                "invalid": "",
                "required": ""
            },
            "vin": {
                "invalid": "",
                "required": ""
            },
            "volvoOrder": {
                "invalid": "",
                "required": ""
            },
            "vehicleLicencePlate": {
                "invalid": "",
                "label": ""
            },
            "vehicleDescription": {
                "required": "",
                "invalid": "",
                "label": ""
            },
            "paymentCardNumber": {
                "label": "",
                "invalid": ""
            },
            "paymentCardExpiry": {
                "label": "",
                "invalid": ""
            },
            "paymentCardCvc": {
                "label": "",
                "invalid": ""
            },
            "termsAccepted": {
                "required": "",
                "label": "",
                "labelLink": ""
            },
            "emailConsented": {
                "label": ""
            },
            "rfidChoice": {
                "required": "",
                "none": {
                    "label": ""
                },
                "order": {
                    "label": ""
                },
                "existing": {
                    "label": ""
                }
            }
        },
        "messages": {
            "input": {
                "required": "",
                "invalid": "",
                "unknown": ""
            }
        },
        "heading": {
            "contactInfo": "Kontaktinformasjon",
            "myVehicle": "",
            "charger": "",
            "consent": "",
            "choosePaymentMethod": "",
            "invoiceEmail": "",
            "paymentEhf": "",
            "companyInfo": "",
            "companyContactInfo": "",
            "paymentCard": ""
        },
        "description": {
            "charger": "",
            "choosePaymentMethod": "",
            "invoiceFee": "",
            "paymentCard": ""
        }
    },
    "modal": {
        "addFamily": {
            "heading": "Lägg till familjemedlem",
            "bodyConsent": "Du kan lägga till upp till fyra familjemedlemmar. Om personen du bjuder in är kund hos Mer, kommer de att erbjudas att anslutas till ditt konto. Om de inte är kunder hos Mer kommer de att bjudas in att skapa ett nytt konto som redan är kopplat till dig.",
            "bodyNoConsent": "Tillsammans är vi Mer! Bjud in upp till fyra medlemmar så att du kan spara volymrabatt snabbare och få allt på en faktura.",
            "info": "När de har accepterat länken eller skapat en användare kommer du att debiteras för deras avgift på nästa faktura.",
            "checkboxLabel": "Jag godkänner att betala för dessa användare",
            "buttonState1": "Skicka inbjudningar via e-post",
            "textInputLabel": "E-postadresse",
            "buttonAddAddresses": "Lägg till fler e-postadresser",
            "buttonState2": "Skicka inbjudningar"
        },
        "close": "Stänga",
        "addChip": {
            "heading": "Legg til brikke",
            "body": "Här kan du lägga till chip från Mer och våra partners. Kom ihåg att välja rätt leverantör i listan.",
            "button": "Aktivera chip"
        },
        "cancel": "Avbryt",
        "editChip": {
            "heading": "RFID",
            "body": "Privat laddningschip för",
            "buttonMain": "Spara ändringar",
            "buttonSecondary": "Ta bort den här chip från mitt konto"
        },
        "orderChip": {
            "heading": "Beställ ny chip",
            "linkTitle": "Gå till profilsidan för att ändra personlig information. Du går ut ur det här flödet",
            "link": "Ändra personlig information",
            "dropdownLabel": "Antall chip",
            "info": "Pris för VARIABEL er VARIABEL kr, du kommer att debiteras på din nästa faktura",
            "button": "Beställ nu"
        },
        "removeFamily": {
            "heading": "Ta bort familjemedlem",
            "body": "Är du säker på att du vill ta bort länken till",
            "info": "VARIABEL NAMN FÖR FAMILJEMEDLEM har laddat for VARIABLEL SUM NOK i VARIABEL MÅNAD, beløpet vil bli belastet deg via faktura VARIABEL NËSTE MÅNAD",
            "button": "Ta bort länk"
        },
        "removeOwnFamilyMembership": {
            "heading": "Fjerne familietilkobling?",
            "body": "Är du säker på att du vill säga upp ditt medlemskap i familjen VARIABEL FAMILIENAVN?",
            "info": "Du har laddat for VARIABEL X NOK i VARIABEL MÅNAD,beloppet debiteras Admin Family via faktura VARIABEL MÅNAD",
            "button": "Ta bort mig som medlem"
        }
    },
    "general": {
        "numberOf": "",
        "shortMonth": "mnd",
        "currency": "kr",
        "minutes": "minuter",
        "error": {
            "fetchingData": "",
            "tryAgain": "Prøv igjen"
        },
        "busy": {
            "fetchingData": ""
        },
        "carousel": {
            "next": "Nästa",
            "prev": "Tidigare",
            "selected": "Vald",
            "itemNumber": "Bild nummer"
        },
        "learnMore": "",
        "zeroPrice": ""
    },
    "userNavigation": {
        "roles": {
            "admin": "Administratör",
            "user": "Användare",
            "member": "Medlem av"
        },
        "links": {
            "userProfile": "Min brukerprofil",
            "faq": "FAQ",
            "support": "Support"
        },
        "closeMenu": "Lukk meny",
        "toggleMenu": "Åpne/lukke meny",
        "logOut": "Logg ut",
        "more": "Mer"
    },
    "page": {
        "myBenefits": {
            "coupons": {
                "addDiscount": {
                    "heading": "Rabattkoder",
                    "body": "Du kan lägga till dina rabattkoder i fältet nedan",
                    "buttonText": "Aktivera rabattkod"
                },
                "coop": {
                    "blueBody": "Genom att aktivera ditt Coop-medlemskap på Mer kan du få 8% rabatt betalt som en bonus på Coop - varje gång du laddar på en av Mers offentliga laddstationer!",
                    "link": "Du kan bli medlem på coop.no",
                    "note": "Du hittar numret på ditt medlemskort (de senaste 10 siffrorna), i Coop-appen eller på Min sida på coop.no",
                    "orderNow": "Slutför aktiveringen",
                    "cancel": "Avbryt",
                    "cancelBonus": "Avsluta bonusbesparingar",
                    "heading": "laddningsfördel",
                    "subHeading": "Coop medlemsbonus",
                    "memberBody": "Tack för att du utnyttjar medlemsbonusen. Du kan se mer information om dina bonusbesparingar på Coop.",
                    "buttonTextActive": "Avsluta medlemskapet",
                    "body": "Som medlem i Coop får du 8% bonus varje gång du laddar din elbil på våra laddstationer.",
                    "buttonText": "Bli medlem"
                },
                "couponAmount": {
                    "validUntil": "Giltig till",
                    "stackHeading": "Totalt för dina aktiva kuponger",
                    "body": "Du kan använda alla eller delar av dessa på alla våra laddstationer. Vi har över 220 laddstationer i Norge, vilket gör det enkelt att ladda längs vägen.",
                    "stackSubHeading": "Aktiva kuponger",
                    "leftOf": "kvar av",
                    "period": "Faktureringsperiod"
                },
                "discount": {
                    "validUntil": "Giltig till",
                    "body": "Du får den här fördelen utöver dina andra kuponger när du laddar i vårt laddningsnätverk."
                },
                "freeCharge": {
                    "freeChargeMonthly": "Gratis laddning varje månad",
                    "freeCharge": "Gratis laddning",
                    "monthlyBody": "Gratis laddning varje månad. Du som äger din egen laddare får 15 minuters kostnadsfri laddning varje månad. Du kan använda dessa minuter på vilken laddare som helst i vårt laddningsnätverk.",
                    "oneTimeBody": "If you own your own charger, you'll get 60 minutes free charging."
                }
            },
            "heading": "Mina fördelar"
        },
        "chargingJourney": {
            "chargeDetailRecords": {
                "price": "Pris",
                "total": "Total",
                "saved": "Spart",
                "type": "Type",
                "effect": "kWh",
                "avgEffect": "Meddeleffekt",
                "parkingLot": "Park. plass",
                "heading": "",
                "location": "Plats",
                "date": "Dato",
                "chargingTime": "Laddningstid",
                "paid": "Betald"
            },
            "heading": "Min laddningsresa",
            "subHeading": "Laddningshistorik",
            "downloadButtonText": "Ladda ner"
        },
        "home": {
            "monthlyCharging": {
                "heading": "Månadlig ladding"
            },
            "lastChargingSessions": {
                "heading": "Senaste laddningssessionerna"
            },
            "aside": {
                "heading1": "Nytt för dig",
                "heading2": "Dina aktiva fördelar"
            },
            "heading": "Velkommen til Mer",
            "nameInMenu": "Översikt"
        },
        "myChargers": {
            "heading": "Mina laddare",
            "myChargerItem": {
                "graphTitle": "Konsumentöversikt",
                "listHeadings": {
                    "charingCode": "Laddningskod",
                    "location": "Plats",
                    "parkingLot": "Parkeringsplats",
                    "modell": "Modell",
                    "maxEffect": "Max effekt",
                    "status": "Status",
                    "cooperativesPrice": "Bostadsrättsföreningens pris",
                    "pricePerKwh": "NOK per kWh"
                },
                "kpis": {
                    "totalThisMonth": "Totalt denna månad",
                    "totalChargingTime": "Total laddningstid",
                    "totalCost": "Total kostnad"
                }
            }
        },
        "contact": {
            "heading": ""
        },
        "faq": {
            "heading": ""
        },
        "noMatch": {
            "heading": "Fant ikke siden - 404"
        },
        "orderPayment": {
            "stepper": {
                "step1": "",
                "step2": "",
                "step3": ""
            },
            "heading": "",
            "title": "",
            "delivery": {
                "title": "",
                "deliveryMethod": "",
                "addressTitle": ""
            },
            "payment": {
                "title": ""
            },
            "completeOrder": "",
            "orderSummary": {
                "title": "",
                "orderValue": "",
                "delivery": "",
                "standardInstallation": "",
                "totalCost": "",
                "taxIncluded": "",
                "discount": "",
                "editOrder": "",
                "proceedButton": ""
            },
            "selectCharger": {
                "heading": "",
                "taxIncluded": ""
            },
            "operatingServices": {
                "heading": "",
                "listItem1": "",
                "listItem2": "",
                "listItem3": "",
                "listItem4": ""
            },
            "productDetails": {
                "technicalSpecifications": "",
                "installationInformation": ""
            }
        },
        "orderComplete": {
            "heading": "",
            "yourOrderNumber": "",
            "confirmation": "",
            "title": "",
            "message1": "",
            "message2": "",
            "goToHub": ""
        },
        "privacy": {
            "heading": ""
        },
        "account": {
            "subpage": {
                "info": {
                    "heading": ""
                },
                "rfid": {
                    "heading": "Laddningschip och nätverk",
                    "introduction": "Här har du en översikt över alla dina laddningschips. Du kan också beställa mer om du behöver det",
                    "rfidTags": "Laddningschips",
                    "orderNewTag": "Beställ ett nytt laddningschip",
                    "addExistingTag": "Lägg til ny laddningschip",
                    "rfidTag": {
                        "status": {
                            "active": "Aktiv"
                        },
                        "edit": "Redigera"
                    }
                },
                "payment": {
                    "heading": "Betaling og faktura"
                },
                "subscriptions": {
                    "heading": "Prenumerationer",
                    "other": "Andre prenumerationer"
                },
                "settings": {
                    "heading": "Kontoinställningar",
                    "login": {
                        "yourLogin": "Din inloggning:",
                        "introduction": "Mer använder Auth0 för att ge dig en säker inloggning. Besök Auth0 för att ändra din inloggningsinformation",
                        "changeLogin": "Ändra inloggningsinformation vid Auth0"
                    },
                    "language": {
                        "heading": "Språk för portalen"
                    },
                    "agreement": {
                        "heading": "Enas"
                    },
                    "deleteAccount": {
                        "heading": "Radera konto",
                        "button": "Radera konto"
                    },
                    "deleteFamilyAccount": {
                        "info": "Du har X aktiva medlemmar i ditt nätverk. Innan du tar bort ditt konto måste du ta bort aktiva länkar i laddare och nätverk."
                    },
                    "personalData": {
                        "heading": "Integritet",
                        "introduction": "Här kan du ladda ner alla dina personuppgifter tillsammans.",
                        "downloadAccountData": "Ladda ner alle kontoens ladedata (.csv)",
                        "downloadInvoices": "Ladda ner alle fakturor (.csv)",
                        "downloadUserData": "Ladda ner all brukerdata (.csv)"
                    }
                }
            }
        },
        "invitation": {
            "error": {
                "message": ""
            },
            "invalid": {
                "message": ""
            },
            "expired": {
                "message": ""
            }
        },
        "registration": {
            "subpage": {
                "userService": {
                    "heading": ""
                },
                "memberService": {
                    "heading": ""
                },
                "companyContact": {
                    "heading": ""
                },
                "companyPayment": {
                    "heading": ""
                },
                "company": {
                    "heading": ""
                },
                "userPayment": {
                    "heading": ""
                },
                "user": {
                    "heading": ""
                }
            }
        },
        "terms": {
            "linkLeadIn": "",
            "link": "",
            "heading": ""
        }
    },
    "label": {
        "kpi": {
            "totalLastMonth": "",
            "totalChargingTime": "",
            "totalCost": "",
            "totalSavings": "",
            "chargedIn": "Ladet i"
        },
        "userType": {
            "private": ""
        },
        "input": {
            "rfidState": {
                "none": "",
                "order": "",
                "existing": ""
            },
            "rfidSuppliers": {
                "mer": ""
            },
            "rfidSupplier": "",
            "rfidSerialNumber": "",
            "rfidDescription": "",
            "vehicleRegistration": "",
            "vehicleName": "",
            "firstName": "",
            "lastName": "",
            "phoneNumber": "",
            "email": "",
            "receiveOffersAndNewsViaEmail": "",
            "paymentMethod": {
                "card": "",
                "invoice": "",
                "ehf": ""
            },
            "paymentCardNumber": "",
            "paymentCardExpiry": "",
            "paymentCardCVC": "",
            "emailConfirmation": "",
            "companyNumber": "",
            "companyName": "",
            "companyPublic": "",
            "address": "",
            "postCode": "",
            "postArea": "",
            "country": ""
        }
    },
    "footer": {
        "heading": {
            "customerSupport": "",
            "downloadTheApp": ""
        }
    },
    "link": {
        "getInTouch": "",
        "faq": "",
        "terms": "",
        "privacy": "",
        "title": {
            "home": "Hem",
            "profile": ""
        }
    },
    "payment": {
        "error": {
            "unknown": "",
            "existing": "",
            "cancelled": "",
            "error": "",
            "refused": ""
        },
        "challenge": {
            "redirect": "",
            "challenge": "",
            "identify": ""
        },
        "success": {
            "existing": "",
            "authorised": "",
            "pending": "",
            "received": ""
        },
        "label": {
            "existingCard": "",
            "updatePaymentMethod": ""
        }
    },
    "order": {
        "error": {
            "unknown": "",
            "missingProduct": ""
        }
    },
    "btn": {
        "text": {
            "saveChanges": "",
            "nextStep": "",
            "completeRegistration": "",
            "backToPayment": "",
            "goToContactPerson": "",
            "goToPayment": ""
        }
    }
}
