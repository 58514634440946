import { IdToken } from '@auth0/auth0-spa-js/src/global';

export default interface Auth0User {
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    locale: string;
    name: string;
    nickname: string;
    picture: string;
    sub: string;
    updated_at: string;
}

export enum EAuth0Provider {
    APPLE = 'apple',
    AUTH0 = 'auth0',
    FACEBOOK = 'facebook',
    GOOGLE = 'google-oauth2',
    SMS = 'sms',
}

export enum EAuth0IdentityMigrationAction {
    FORCE = 'force',
    NONE = 'none',
    PROMPT = 'prompt',
}

export enum EAuth0ScreenHint {
    LINK_EMAIL = 'link',
    LINK_SMS = 'link_sms',
}

export interface IAuth0FetchedUser {
    created_at: string;
    email?: string;
    email_verified?: boolean;
    identities: IAuth0Identity[];
    last_ip: string;
    last_login: string;
    logins_count: number;
    name: string;
    nickname: string;
    picture: string;
    updated_at: string;
    user_id: string;
    user_metadata: Record<string, string | number | boolean | null>;
}

export interface IAuth0Identity {
    connection: string;
    email?: string;
    email_verified?: boolean;
    isSocial: boolean;
    profileData: {
        email?: string;
        email_verified?: boolean;
        name?: string;
        phone_number?: string;
    };
    provider: EAuth0Provider;
    user_id: string;
}

export interface IAuth0SuggestedLinkAccount {
    email?: string;
    email_verified?: boolean;
    name: string;
    picture: string;
    provider: EAuth0Provider;
}

export interface IAuth0IdToken extends IdToken {
    ['https://mer.no/email']?: string;
    ['https://mer.no/email_verified']?: boolean;
    ['https://mer.no/identities']?: IAuth0Identity[];
    ['https://mer.no/identity_migration_action']?: EAuth0IdentityMigrationAction;
    ['https://mer.no/identity_migration_url']?: string;
    ['https://mer.no/suggest_link']?: boolean;
    ['https://mer.no/suggest_link_accounts']?: IAuth0SuggestedLinkAccount[];
}

export enum EAuthLoginReturnPath {}

export enum EAuthLogoutReturnPath {}

export const LOGIN_RETURN_PATHS: Record<EAuthLoginReturnPath, string> = {};
export const LOGOUT_RETURN_PATHS: Record<EAuthLogoutReturnPath, string> = {};
