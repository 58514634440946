import { busyIndicatorGreyTheme } from '~styles/variables';
import React from 'react';
import styled from '@emotion/styled';

const Box = styled.div<{ size: string; theme: { from: string; to: string } }>`
    @keyframes a1 {
        0% {
            height: 100%;
        }
        100% {
            height: 67%;
        }
    }

    @keyframes a2 {
        0% {
            height: 100%;
        }
        100% {
            height: 150%;
        }
    }

    @keyframes a3 {
        0% {
            height: 100%;
        }
        100% {
            height: 200%;
        }
    }

    width: ${(props) => props.size};
    height: ${(props) => props.size};
    display: flex;
    overflow-y: hidden;
    background-color: ${(props) => props.theme.to};

    > div {
        flex-grow: 1;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    > div:first-of-type {
        background: linear-gradient(180deg, ${(props) => props.theme.from}, ${(props) => props.theme.to} 75%);
        animation-name: a1;
        animation-duration: 1200ms;
    }

    > div:nth-of-type(2) {
        background: linear-gradient(180deg, ${(props) => props.theme.from}, ${(props) => props.theme.to} 50%);
        animation-name: a2;
        animation-duration: 800ms;
    }

    > div:last-child {
        background: linear-gradient(180deg, ${(props) => props.theme.from}, ${(props) => props.theme.to} 25%);
        animation-name: a3;
        animation-duration: 1000ms;
    }
`;

type Props = {
    size?: string;
    theme?: { from: string; to: string };
};

export default function Busy({ size = '64px', theme = busyIndicatorGreyTheme }: Props): JSX.Element {
    return (
        <Box size={size} theme={theme}>
            <div />
            <div />
            <div />
        </Box>
    );
}
