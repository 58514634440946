import { EAccountNavigationVariant } from '~components/navigation/AccountNavigation';
import { EOrganizationClassification } from '~interfaces/Organization';
import { ERoleId } from '~interfaces/Invitation';
import { EllipsisText } from '~components/layout';
import { colorPrimaryMerBlue, font } from '~styles/variables';
import { useModal } from '~contexts/Modal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '~contexts/User';
import CrossIcon from '~assets/icons/cross.svg';
import React, { useMemo, useState } from 'react';
import RoleLink from '~components/roles/RoleLink';
import TextInput from '~components/forms/TextInput';
import styled from '@emotion/styled';

export type RoleSelectModalProps = {
    toggleUserNavigation?: () => void;
    variant?: EAccountNavigationVariant;
};

export default function RoleSelectModal(props: RoleSelectModalProps): JSX.Element | null {
    const { toggleUserNavigation, variant } = props;
    const { t } = useTranslation(['common']);
    const { roles, setActiveRoleId, merUser } = useUser();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const navigate = useNavigate();
    const handleModal = useModal();

    const adminRoles = useMemo(
        () =>
            roles?.filter(
                (r) =>
                    r.roleId === ERoleId.ADMINISTRATOR &&
                    r.organizationClassification !== EOrganizationClassification.FAMILY,
            ) ?? [],
        [roles],
    );

    const filteredRoles = useMemo(() => {
        return searchTerm.length > 0
            ? adminRoles?.filter(
                  (r) =>
                      r.organizationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      r.roleName.toLowerCase().includes(searchTerm.toLowerCase()),
              )
            : adminRoles;
    }, [searchTerm, adminRoles]);

    const changeRole = (userRoleId?: string) => {
        const role = roles?.find((r) => r.userRoleId === userRoleId);
        setActiveRoleId(userRoleId);
        toggleUserNavigation?.();
        handleModal?.close?.();
        navigate(
            role?.roleId === ERoleId.ADMINISTRATOR &&
                [
                    EOrganizationClassification.COMPANY,
                    EOrganizationClassification.PUBLIC,
                    EOrganizationClassification.COOPERATIVE,
                ].includes(role?.organizationClassification)
                ? '/organization'
                : '/',
        );
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };
    const userEmail = merUser?.email;
    const userName = `${merUser?.firstName} ${merUser?.lastName}`;

    return (
        <>
            <UserInfo>
                <UserNameMenu>{userName}</UserNameMenu>
                {userEmail && <EllipsisText>{userEmail}</EllipsisText>}
            </UserInfo>
            <TextInput
                name={'search'}
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder={t('userNavigation.select.searchPlaceholder')}
                autoFocus={true}
                adornment={
                    searchTerm?.length > 0 ? (
                        <ClearSearchIcon onClick={() => setSearchTerm('')}>
                            <CrossIcon />
                        </ClearSearchIcon>
                    ) : undefined
                }
            />
            {filteredRoles?.map((r) => (
                <RoleLink key={r.userRoleId} changeRole={changeRole} role={r} variant={variant} />
            ))}
        </>
    );
}

const ClearSearchIcon = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 0.25rem;
    cursor: pointer;

    & svg {
        position: static;
        width: auto;
        height: auto;
        padding-right: 0.5rem;
        fill: ${colorPrimaryMerBlue};
    }
`;

const UserInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 0 1rem 0;
    font-size: ${font.size.s};
    line-height: ${font.lineHeight.xl};
`;

const UserNameMenu = styled.h2`
    display: block;
    visibility: visible;
    font-family: 'SharpSans Bold', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 0 !important;
`;
