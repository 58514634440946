import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '~contexts/Auth';
import FullPageWorkingIndicator from '~components/indicators/FullPageWorkingIndicator';
import React, { ReactElement, useEffect, useState } from 'react';
import useQueryParams from '~hooks/useQueryParams';

type LoginPageState = {
    loginHint?: string;
    next?: string;
    screenHint?: string;
};

export default function LoginPage(): ReactElement {
    const { state: locationState } = useLocation() as { state: LoginPageState };
    const queryParams = useQueryParams<LoginPageState>();
    const { isAuthenticated, isLoading, auth0Login } = useAuth();
    const [performingLoginRequest, setPerformingLoginRequest] = useState(false);

    const next = locationState?.next ?? queryParams?.next ?? '/';

    useEffect(() => {
        if (!performingLoginRequest) {
            setPerformingLoginRequest(true);
            auth0Login({
                ...queryParams,
                ...locationState,
            });
        }
    }, [auth0Login, locationState, performingLoginRequest, queryParams]);

    return !isLoading && isAuthenticated ? <Navigate to={next} /> : <FullPageWorkingIndicator />;
}
