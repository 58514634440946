import { useUser } from '~contexts/User';
import ApiError from '~classes/ApiError';
import IChargeLocation from '~interfaces/ChargeLocation';
import useApi from '~contexts/Api';
import useSWR from 'swr';

export type UseOrganizationRefundsResponse = {
    chargelocations: IChargeLocation[];
    error: ApiError | undefined;
    loading: boolean | undefined;
};

export type UseOrganizationRefundsProps = {
    organizationId?: string;
};

const BASE_PATH = '/organizations';

export default function useOrganizationChargelocations(
    props?: UseOrganizationRefundsProps,
): UseOrganizationRefundsResponse {
    const { activeOrganizationId } = useUser();
    const { organizationId = activeOrganizationId } = props ?? {};
    const { get } = useApi();

    const {
        data: chargelocations = [],
        error,
        isValidating: loading,
    } = useSWR<IChargeLocation[], ApiError>(
        organizationId ? `${BASE_PATH}/${organizationId}/chargelocations` : null,
        get,
        {
            revalidateOnFocus: false,
            errorRetryCount: 0,
        },
    );

    return {
        chargelocations,
        error,
        loading,
    };
}
