import ICreditCard from '~interfaces/CreditCard';

export function isCardExpiring(card?: ICreditCard): boolean | undefined {
    if (!card) return undefined;
    const monthsUntilExpiry = calculateMonthsUntilExpiry(card.CardExpiryDate);
    return monthsUntilExpiry === 1;
}

export function isCardExpired(card?: ICreditCard): boolean | undefined {
    if (!card) return undefined;
    const monthsUntilExpiry = calculateMonthsUntilExpiry(card.CardExpiryDate);
    return monthsUntilExpiry <= 0;
}

function calculateMonthsUntilExpiry(expiryString: string) {
    // Parse the expiry date string
    const month = parseInt(expiryString.substring(2, 4));
    const year = parseInt(expiryString.substring(0, 2));

    // Create a date object for the first day of the month after the expiry month
    const expiryDate = new Date(year + 2000, month, 1);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in months
    let months = (expiryDate.getFullYear() - currentDate.getFullYear()) * 12;
    months -= currentDate.getMonth() + 1;
    months += expiryDate.getMonth();

    return months;
}
