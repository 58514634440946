import { colorIllustrationBlue, colorIllustrationLighterBlue } from '~styles/variables';
import React from 'react';
import styled from '@emotion/styled';

const Box = styled.div<{ size: string }>`
    @keyframes a1 {
        0% {
            height: 0;
        }
        100% {
            height: 80%;
        }
    }

    width: ${(props) => props.size};
    height: ${(props) => props.size};
    display: flex;
    overflow-y: hidden;
    border-radius: 50%;
    position: relative;

    & svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        #lightning-overlay {
            fill: ${colorIllustrationLighterBlue};
        }
    }

    span {
        position: absolute;
        bottom: 10%;
        left: 50%;
        width: 50%;
        transform: translateX(-50%);
        height: 0;
        z-index: 0;
        background-color: ${colorIllustrationBlue};
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-name: a1;
        animation-duration: 2000ms;
    }
`;

type BusyElectricityProps = {
    size?: string;
};

export default function BusyElectricity(props: BusyElectricityProps): JSX.Element {
    const { size = '128px' } = props;
    return (
        <Box size={size}>
            <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 300 300">
                <path
                    id="lightning-overlay"
                    fill="#FFFFFF"
                    d="M0,0v300h300V0H0z M133.8,252.2l5-91.1l0-0.1l6.5-7.8c1-0.8,0.2-2.5-1.1-2.1l-4.9,1l-39.2,9.5l15.7-103.4
	l47-10.4l-6.8,51.4l0,0.1l-6.6,5.7c-0.7,0.5-0.2,1.7,0.6,1.5l5.2-1l44.6-7.6L133.8,252.2z"
                />
            </svg>
            <span />
        </Box>
    );
}
