import { LayoutWithoutNav } from '~components/routeControl/RouteLayouts';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FullPageWorkingIndicator from '~components/indicators/FullPageWorkingIndicator';
import React, { Suspense, lazy } from 'react';

const CoopAuthResponseHandler = lazy(() => import('./pages/public/CoopAuthResponseHandler'));

export const publicPaths = ['/memberships/*'];

export default function RoutesPublic(): JSX.Element {
    const { t } = useTranslation(['common']);
    return (
        <Suspense fallback={<FullPageWorkingIndicator text={t('busy.loading.page')} />}>
            <Routes>
                <Route element={<LayoutWithoutNav />}>
                    <Route path={':intention?/coop'} element={<CoopAuthResponseHandler />} />
                </Route>
            </Routes>
        </Suspense>
    );
}
