export const appendQueryParams = (
    url: string,
    params: Record<string, string | number | boolean | number[] | string[]>,
): string => {
    const query = Object.entries(params)
        .map(([key, value]) => (value ? `${key}=${Array.isArray(value) ? value.join(',') : value}` : undefined))
        .filter((q) => !!q)
        .join('&');
    return `${url}${query ? `?${query}` : ''}`;
};
