import { EOrganizationClassification } from '~interfaces/Organization';
import { ERoleId } from '~interfaces/Invitation';
import {
    colorIllustrationLightBlue,
    colorNeutralsWhite,
    colorOnWhiteLighterGrey,
    colorPrimaryMerBlue,
    font,
    screenWidthMedium,
    screenWidthMini,
    screenWidthSmall,
} from '~styles/variables';
import { initialsFromOrganizationName, initialsFromUser } from '~utils/formats';
import { useTranslation } from 'react-i18next';
import { useUser } from '~contexts/User';
import AccountNavigation from '~components/navigation/AccountNavigation';
import CloseIcon from '~assets/icons/close.svg';
import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import useOrganization from '~hooks/useOrganization';

type UserProfileProps = {
    showUserMenu?: boolean | undefined;
};

export default function UserProfile(props: UserProfileProps): JSX.Element | null {
    const { showUserMenu } = props;
    const { merUser, isRegistered, activeRole } = useUser();
    const [userNavigationIsOpen, setUserNavigationIsOpen] = useState<boolean>(false);
    const domRef = useRef<HTMLDivElement>();

    function toggleUserNavigation(): void {
        setUserNavigationIsOpen(!userNavigationIsOpen);
    }

    useEffect(() => {
        function handler(e: MouseEvent) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!domRef.current?.contains(e.target)) setUserNavigationIsOpen(false);
        }

        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);

    const currentRoleComponent = useMemo(() => {
        switch (activeRole?.organizationClassification) {
            case EOrganizationClassification.FAMILY: {
                return activeRole.roleId === ERoleId.ADMINISTRATOR ? (
                    <CurrentRoleFamilyAdmin open={userNavigationIsOpen} />
                ) : (
                    <CurrentRoleFamilyMember open={userNavigationIsOpen} />
                );
            }
            case EOrganizationClassification.COOPERATIVE:
            case EOrganizationClassification.COMPANY:
            case EOrganizationClassification.PUBLIC: {
                switch (activeRole.roleId) {
                    case ERoleId.SMB: {
                        return <CurrentRoleOrganizationSmb open={userNavigationIsOpen} />;
                    }
                    case ERoleId.ADMINISTRATOR: {
                        return <CurrentRoleOrganizationAdmin open={userNavigationIsOpen} />;
                    }
                    default: {
                        return <CurrentRoleOrganizationMemberResident open={userNavigationIsOpen} />;
                    }
                }
            }
            default: {
                return <CurrentRoleUser open={userNavigationIsOpen} />;
            }
        }
    }, [activeRole, userNavigationIsOpen]);

    if (!isRegistered || showUserMenu === false) return null;

    return (
        <ProfileArea ref={domRef as unknown as MutableRefObject<HTMLDivElement>} id={'profileArea'}>
            <ToggleNavigation
                type={'button'}
                onClick={toggleUserNavigation}
                className={userNavigationIsOpen ? 'menu-is-open' : undefined}
            >
                {currentRoleComponent}
            </ToggleNavigation>
            {userNavigationIsOpen && (
                <AccountNavigation
                    userName={merUser?.firstName + ' ' + merUser?.lastName}
                    userEmail={merUser?.email}
                    toggleUserNavigation={toggleUserNavigation}
                />
            )}
        </ProfileArea>
    );
}

type CurrentRoleProps = {
    initials: string;
    name: string;
    open?: boolean;
    role?: string;
    showRoleTitle?: boolean;
};

function CurrentRole(props: CurrentRoleProps): JSX.Element {
    const { role, name, initials, open } = props;
    return (
        <>
            <UserNameHeader>
                {role ? <span className="role">{role}</span> : null}
                <span className="name">{name}</span>
            </UserNameHeader>
            {open ? (
                <CloseIconWrapper>
                    <CloseIcon />
                </CloseIconWrapper>
            ) : (
                <span className="avatar">{initials}</span>
            )}
        </>
    );
}

type CurrentRoleVariantProps = {
    open?: boolean;
    showRoleTitle?: boolean;
};

function CurrentRoleUser(props: CurrentRoleVariantProps): JSX.Element {
    const { merUser } = useUser();
    return (
        <CurrentRole
            name={`${merUser?.firstName} ${merUser?.lastName}`}
            initials={initialsFromUser(merUser ?? undefined)}
            {...props}
        />
    );
}

function CurrentRoleFamilyAdmin(props: CurrentRoleVariantProps): JSX.Element {
    const { merUser, activeRole } = useUser();
    const { t } = useTranslation(['common']);
    return (
        <CurrentRole
            name={`${merUser?.firstName} ${merUser?.lastName}`}
            role={
                activeRole?.organizationClassification && activeRole?.roleId
                    ? t(`userNavigation.roles.${activeRole?.organizationClassification}.${activeRole?.roleId}`, {
                          network: activeRole?.organizationName,
                      })
                    : undefined
            }
            initials={initialsFromUser(merUser ?? undefined)}
            {...props}
        />
    );
}

function CurrentRoleFamilyMember(props: CurrentRoleVariantProps): JSX.Element {
    const { merUser, activeRole } = useUser();
    const { t } = useTranslation(['common']);
    return (
        <CurrentRole
            name={`${merUser?.firstName} ${merUser?.lastName}`}
            role={
                activeRole?.organizationClassification && activeRole?.roleId
                    ? t(`userNavigation.roles.${activeRole?.organizationClassification}.${activeRole?.roleId}`, {
                          network: activeRole?.organizationName,
                      })
                    : undefined
            }
            initials={initialsFromUser(merUser ?? undefined)}
            {...props}
        />
    );
}

function CurrentRoleOrganizationSmb(props: CurrentRoleVariantProps): JSX.Element {
    const { activeRole } = useUser();
    const { organization } = useOrganization({ organizationId: activeRole?.organizationId });
    const { t } = useTranslation(['common']);
    return (
        <CurrentRole
            name={organization?.name ?? ''}
            role={
                activeRole?.organizationClassification && activeRole?.roleId
                    ? t(`userNavigation.roles.${activeRole?.organizationClassification}.${activeRole?.roleId}`, {
                          network: activeRole?.organizationName,
                      })
                    : undefined
            }
            initials={initialsFromOrganizationName(organization?.name)}
            {...props}
        />
    );
}

function CurrentRoleOrganizationAdmin(props: CurrentRoleVariantProps): JSX.Element {
    const { activeRole } = useUser();
    const { t } = useTranslation(['common']);
    return (
        <CurrentRole
            name={activeRole?.organizationName ?? ''}
            role={
                activeRole?.organizationClassification && activeRole?.roleId
                    ? t(`userNavigation.roles.${activeRole?.organizationClassification}.${activeRole?.roleId}`, {
                          network: activeRole?.organizationName,
                      })
                    : undefined
            }
            initials={initialsFromOrganizationName(activeRole?.organizationName)}
            {...props}
        />
    );
}

function CurrentRoleOrganizationMemberResident(props: CurrentRoleVariantProps): JSX.Element {
    const { merUser, activeRole } = useUser();
    const { t } = useTranslation(['common']);
    return (
        <CurrentRole
            name={`${merUser?.firstName} ${merUser?.lastName}`}
            role={
                activeRole?.organizationClassification && activeRole?.roleId
                    ? t(`userNavigation.roles.${activeRole?.organizationClassification}.${activeRole?.roleId}`, {
                          network: activeRole?.organizationName,
                      })
                    : undefined
            }
            initials={initialsFromUser(merUser ?? undefined)}
            {...props}
        />
    );
}

const ProfileArea = styled.div`
    position: fixed;
    margin-left: auto;
    padding-right: 1rem;
    top: 0;
    right: 0;
    grid-area: headerNav;
    z-index: 9001;

    @media screen and (max-width: ${screenWidthMedium}) {
        display: none;
        visibility: hidden;
    }
`;

const UserNameHeader = styled.span`
    display: inline-block;
    visibility: visible;
    text-align: right;
    font-family: 'SharpSans Bold', sans-serif;
    font-size: ${font.size.m};

    .role {
        display: block;
        font-family: 'SharpSans Medium', sans-serif;
        font-size: ${font.size.xs};
    }
`;

const ToggleNavigation = styled.button`
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;

    height: 60px;
    border: none;
    -webkit-appearance: none;
    font-family: 'SharpSans Medium', sans-serif;
    background-color: ${colorNeutralsWhite};
    font-size: ${font.size.s};

    &.menu-is-open {
        .avatar {
            box-shadow: 0 0 0 4px ${colorIllustrationLightBlue};
        }
    }

    @media screen and (min-width: ${screenWidthMini}) {
        font-size: ${font.size.m};
    }

    @media screen and (max-width: ${screenWidthSmall}) {
        display: none;
    }

    .avatar {
        height: 48px;
        width: 48px;
        line-height: 48px;
        text-align: center;
        font-size: ${font.size.l};
        font-weight: ${font.weight.bold};
        background-color: ${colorPrimaryMerBlue};
        color: ${colorNeutralsWhite};
        border-radius: 50%;
        margin-left: 1rem;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px ${colorIllustrationLightBlue};
    }

    &:hover {
        background-color: ${colorOnWhiteLighterGrey};

        span {
            text-decoration: underline;
        }

        .avatar {
            box-shadow: 0 0 0 4px ${colorIllustrationLightBlue};
            text-decoration: none;
        }
    }

    @media screen and (max-width: ${screenWidthMedium}) {
        .avatar {
            height: 32px;
            width: 32px;
            line-height: 32px;
        }
    }
`;

const CloseIconWrapper = styled.div`
    @keyframes pulseIn {
        0% {
            transform: scale(0.9);
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        60% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }

    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    animation: pulseIn 130ms linear forwards;

    & svg {
        width: 40px;
        height: 40px;
        fill: ${colorPrimaryMerBlue};
    }

    @media screen and (min-width: ${screenWidthSmall}) {
        margin-left: 1rem;
    }
`;
