import { NavigationSection } from '~interfaces/Navigation';
import i18next from 'i18next';

export const USER_NAVIGATION_SECTIONS: NavigationSection[] = [
    {
        url: `/account/profile`,
        text: i18next.t('page.account.subpage.info.heading'),
    },
    {
        url: `/account/vehicles`,
        text: i18next.t('page.account.subpage.vehicles.heading'),
    },
    {
        url: `/account/rfid`,
        text: i18next.t('page.account.subpage.rfid.heading'),
    },
    {
        url: `/account/payment`,
        text: i18next.t('page.account.subpage.payment.heading'),
    },
    {
        url: `/account/subscriptions`,
        text: i18next.t('page.account.subpage.subscriptions.heading'),
    },
    {
        url: `/account/settings`,
        text: i18next.t('page.account.subpage.settings.heading'),
    },
];

export const ORGANIZATION_NAVIGATION_SECTIONS: NavigationSection[] = [
    {
        url: `/organization/account/profile`,
        text: i18next.t('page.organization.subpage.profile.heading'),
    },
    {
        url: `/organization/account/payment`,
        text: i18next.t('page.organization.subpage.payment.heading'),
    },
    {
        url: `/organization/account/refund`,
        text: i18next.t('organization:page.refund.heading'),
    },
    {
        url: `/organization/account/subscriptions`,
        text: i18next.t('organization:page.subscriptions.heading'),
    },
    // {
    //     url: `/organization/account/access-control`,
    //     text: i18next.t('organization:page.accessControl.navigation.title'),
    // },
];
