import { screenWidthMedium, screenWidthMini, screenWidthSmall } from '~styles/variables';
import React, { createContext, useContext, useEffect, useState } from 'react';

export enum EDeviceSize {
    UNKNOWN,
    MINI,
    SMALL,
    MEDIUM,
    LARGE,
}

// export const screenWidthMini = '500px';
// export const screenWidthSmall = '768px';
// export const screenWidthMedium = '1024px';
// export const screenWidthLarge = '1200px';
// export const maxWidthContent = '920px';

export interface IDeviceContext {
    deviceSize: EDeviceSize;
}

const DeviceContext = createContext<IDeviceContext>({
    deviceSize: EDeviceSize.UNKNOWN,
});

type DeviceProviderProps = {
    children: React.ReactNode;
};

function DeviceProvider(props: DeviceProviderProps): JSX.Element {
    const { children } = props;
    const [deviceSize, setDeviceSize] = useState<EDeviceSize>(EDeviceSize.UNKNOWN);

    useEffect(() => {
        function setSize() {
            let size;
            const width = document.body.offsetWidth;
            if (width <= parseInt(screenWidthMini)) size = EDeviceSize.MINI;
            else if (width <= parseInt(screenWidthSmall)) size = EDeviceSize.SMALL;
            else if (width <= parseInt(screenWidthMedium)) size = EDeviceSize.MEDIUM;
            else size = EDeviceSize.LARGE;
            setDeviceSize(size);
        }
        function setupListener() {
            window.addEventListener('resize', setSize);
        }
        setSize();
        setupListener();
        return () => window.removeEventListener('resize', setSize);
    }, []);

    const context = {
        deviceSize,
    };
    return <DeviceContext.Provider value={context}>{children}</DeviceContext.Provider>;
}

function useDevice(): IDeviceContext {
    const context = useContext(DeviceContext);
    if (!context) throw new Error('useDevice must be used within a DeviceProvider');
    return context;
}

export { DeviceProvider, useDevice };
