{
  "initializing": "Initialisering",
  "creating": "Legger til betalingsmetode...",
  "authorizing": "Godkjenner betalingsmetode...",
  "challenge": {
    "redirect": "Du vil bli videresendt til din betalingskort tilbyder, vennligst vent",
    "challenge": "Betalingsmetoden venter på informasjon, vennligst vent",
    "identify": "Betalingsmetoden venter på informasjon, vennligst vent"
  },
  "success": {
    "existing": "Eksisterende betalingsmetode fungerte ",
    "authorised": "Betalingen var vellykket",
    "pending": "Betalingsmetode ble godkjent og prosessen fortsetter",
    "received": "Betalingsmetode ble godkjent og prosessen fortsetter"
  },
  "label": {
    "newCard": "Bruk kort",
    "existingCard": "Bruk eksisterende kort",
    "updatePaymentMethod": "Bytt betalingsmetode"
  },
  "error": {
    "refusalCode": {
      "0": "Oisann! Det ser ut til å være noe galt med bankkortet ditt. Vennligst ta kontakt med banken din.",
      "1": "Oisann! Noe gikk galt! Sjekk at du har benyttet riktig legitimasjon, saldo eller benytt et annet kort.",
      "2": "Du kansellerte transaksjonen – vi håper du kommer tilbake!",
      "3": "Oisann! Det er ut til å være en nettverksfeil, beklager ubeleiligheten! Forsøk igjen senere eller ta kontakt med vår kundeservice for assistanse."
    },
    "cancelled": "Betalingsmetoden feilet, bruker kansellerte transaksjonen",
    "error": "Betalingsmetoden feilen, vennligst forsøk igjen",
    "refused": "Betalingsmetoden ble avvist, vennligst forsøk en annen betalingsmetode"
  }
}