import { SWR_CONFIG_RETRY_SHORT } from '~@constants/swr';
import { useAuth } from '~contexts/Auth';
import ApiError from '~classes/ApiError';
import ICreditCard from '~interfaces/CreditCard';
import useApi from '~contexts/Api';
import useSWR, { SWRResponse } from 'swr';

type UsePaymentCardsResponse = {
    cards: ICreditCard[] | null;
    error?: ApiError;
    loading: boolean | undefined;
    mutate: SWRResponse<ICreditCard[] | void, ApiError | void>['mutate'];
};

export const BASE_PATH = '/gk-paymentcard/getpaymentcarddetail';
const REQUEST_OPTIONS = { omitVersion: true };

export default function usePaymentCards(): UsePaymentCardsResponse {
    const { isAuthenticated } = useAuth();
    const { get } = useApi();

    const {
        data: cards = null,
        error,
        mutate,
        isValidating: loading,
    } = useSWR<ICreditCard[] | void, ApiError>(
        isAuthenticated ? BASE_PATH : null,
        (url: string) => get(url, REQUEST_OPTIONS),
        SWR_CONFIG_RETRY_SHORT,
    );

    return {
        cards,
        error,
        loading: isAuthenticated ? loading : undefined,
        mutate,
    };
}
