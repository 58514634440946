import { FieldError as HookFormFieldError } from 'react-hook-form';
import {
    colorNeutralsBlack,
    colorNeutralsWhite,
    colorOnWhiteDarkGrey,
    colorOnWhiteLightGrey,
    colorOnWhiteLighterGrey,
    colorPrimaryMerBlue,
    colorSecondaryRed,
    font,
    screenWidthMini,
    shadowBlueSharp,
    shadowRedSharp,
} from '~styles/variables';
import ErrorComponent from '~components/forms/Error';
import FieldError from '~components/forms/FieldError';
import FormError from '~interfaces/FormError';
import FormFieldMessages from '~interfaces/FormFieldMessages';
import React, { ChangeEvent, ForwardedRef, ReactElement, forwardRef, memo } from 'react';
import styled from '@emotion/styled';
import uuid from '~utils/uuid';

export interface Props {
    adornment?: React.ReactNode;
    autoComplete?: string;
    autoFocus?: boolean;
    className?: string;
    disabled?: boolean;
    error?: FormError;
    fieldErrors?: HookFormFieldError | null;
    flexGrow?: number;
    help?: string | ReactElement;
    id?: string;
    label?: string | ReactElement;
    margin?: string;
    mask?: (e: React.ChangeEvent<HTMLInputElement>) => string;
    maxLength?: number;
    messages?: FormFieldMessages;
    name: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    type?: string;
    value?: string;
    width?: string;
}

const TextInput: React.FC<Props> = forwardRef(
    (
        {
            id = uuid(),
            name,
            maxLength = 128,
            type = 'text',
            required = false,
            disabled = false,
            readOnly = false,
            autoComplete,
            label,
            help,
            width = '100%',
            error,
            fieldErrors,
            autoFocus,
            adornment,
            flexGrow = 1,
            className,
            onChange,
            onFocus,
            onBlur,
            onKeyDown,
            mask,
            placeholder,
            value,
        }: Props,
        ref: ForwardedRef<never>,
    ) => {
        return (
            <Container
                width={width}
                flexGrow={flexGrow}
                disabled={disabled}
                required={required}
                readOnly={readOnly}
                error={!!error || !!fieldErrors}
                className={className}
                adornment={!!adornment}
            >
                {label && <label htmlFor={id}> {label}</label>}
                <input
                    id={id}
                    ref={ref}
                    maxLength={maxLength}
                    name={name}
                    type={type}
                    readOnly={readOnly}
                    required={required}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={
                        mask
                            ? (e: ChangeEvent<HTMLInputElement>) => {
                                  e.target.value = mask(e);
                                  onChange?.(e);
                              }
                            : onChange
                    }
                    autoFocus={autoFocus}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    value={value}
                    autoComplete={autoComplete}
                    aria-required={required ? 'true' : 'false'}
                    aria-invalid={error || fieldErrors ? 'true' : 'false'}
                />
                {adornment ? <span className={'adornment'}>{adornment}</span> : null}
                {help ? <span className={'help'}>{help}</span> : null}
                {fieldErrors ? <FieldError fieldErrors={fieldErrors} /> : null}
                {error?.message ? <ErrorComponent>{error.message}</ErrorComponent> : null}
            </Container>
        );
    },
);

TextInput.displayName = 'TextInput';

export const Container = styled.div<{
    adornment: boolean;
    disabled: boolean;
    error: boolean;
    flexGrow: number;
    margin?: string;
    readOnly: boolean;
    required: boolean;
    width: string;
}>`
    display: flex;
    flex-direction: column;
    flex-grow: ${(props) => props.flexGrow};
    position: relative;
    margin-bottom: ${(props) => (props.margin ? props.margin : '0.75rem')};

    @media screen and (min-width: ${screenWidthMini}) {
        width: ${(props) => props.width};
    }

    label {
        color: ${colorNeutralsBlack};
        font-size: 0.875rem;
        font-weight: ${font.weight.regular};
        margin-bottom: 0.5rem;
        transition: color 0.3s ease-in-out;

        ${(props) => {
            if (props.required == true)
                return `
                :after {
                    content: "*"
                }
            `;
        }}
    }

    input {
        padding: ${(props) => (props.adornment ? '0 3rem 0 1rem' : '0 1rem')};
        margin-bottom: 0.25rem;
        width: 100%;
        height: 3rem;

        color: ${colorNeutralsBlack};
        background-color: ${colorNeutralsWhite};
        border-width: 0.0625rem;
        border-style: solid;
        border-color: ${(props) => (props.error ? colorSecondaryRed : colorPrimaryMerBlue)};
        box-shadow: ${(props) => (props.error && !props.disabled ? shadowRedSharp : null)};
        font-weight: ${font.weight.regular};
        font-family: 'SharpSans Medium', sans-serif;
        font-size: 1rem;
        transition: all 0.3s ease-in-out;
        outline: none;

        :focus {
            box-shadow: ${shadowBlueSharp};
        }

        :disabled {
            color: ${colorOnWhiteDarkGrey};
            border-color: ${colorOnWhiteLightGrey};
            background-color: ${colorOnWhiteLighterGrey};
        }

        :read-only {
            color: ${colorOnWhiteDarkGrey};
            border-color: ${colorOnWhiteLightGrey};
            background-color: ${colorOnWhiteLighterGrey};
        }
    }

    &.paymentCard input {
        :read-only {
            color: ${colorNeutralsBlack};
        }
    }

    & svg {
        fill: ${colorSecondaryRed};
        width: 2rem;
        height: 2rem;
        position: absolute;
        right: 0.5rem;
        top: ${(props) => (props.error ? '2.1rem' : '0.5rem')};
    }

    .adornment {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 3rem;
        text-align: right;
        padding: 0 1rem;
        line-height: 3rem;
        display: ${(props) => (props.error ? 'none' : 'block')};
    }

    .help {
        text-align: right;
        font-size: ${font.size.xs};
        padding-top: 0.25rem;
    }
`;

export default memo(TextInput);
