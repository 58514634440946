{
  "page": {
    "landing": {
      "heading": "Bestill hjemmelader til borettslag/sameie",
      "introductionTitle": "Bestill ladeboks til ditt ladeanlegg",
      "introduction": "Mer er totalleverandør av ladeløsninger og leverer alt fra planlegging og installasjon av ladeanlegg, til drift og fakturering. Vi sørger for å gi rask lading selv når flere lader samtidig, og fordeler og fakturerer kostnadene fordelt på bruk. \n\nHvis ditt boligsameie eller din bedrift har en avtale om ladeløsning fra Mer, så kan du bestille ladeboks her, og få den fiks ferdig installert til din p-plass.",
      "stepsLeadIn": "Slik bestiller du:",
      "steps": [
        "Finn frem prosjektkoden for ditt ladeanlegg. Denne får du av administrator for ladeanlegget (for eksempel styreleder i boligsameiet). Prosjektkoden hjelper oss til å finne frem til ditt ladeanlegg.",
        "Velg ladeboksen som du vil bestille til din p-plass.",
        "Logg inn, slik at vi kan hente opp dine kundedata. Hvis du ikke har registrert deg fra før, så kan du gjøre det her.",
        "Fullfør din bestilling, og vi vil snarlig kontakte deg med tidspunkt for installasjon."
      ],
      "projectCode": {
        "heading": "Bestill her",
        "label": "Prosjektkode",
        "placeholder": "Skriv inn prosjektkode"
      },
      "button": {
        "next": "Neste",
        "cancel": "Avbryt"
      },
      "moreInfo": {
        "text": "Her kan du lese mer om hva du kan forvente med hjemmelader fra Mer: ",
        "link": "https://no.mer.eco/ladelosninger/elbillading-borettslag-sameie/"
      }
    },
    "products": {
      "heading": "Din bestilling av ladeboks til \n{{companyName}}",
      "introduction": "Prisen inkluderer ladeboks og komplett installasjon, slik at du får en trygg og effektiv lader, klar til bruk.",
      "introductionPointsLeadIn": "Du får:",
      "introductionPoints": [
        "Rask, sikker og effektiv lading av elbil",
        "Fiks ferdig installert på din parkeringsplass",
        "Inkludert driftsabonnement (faktureres månedlig)",
        "24/7 teknisk kundeservice",
        "Rabatt på hurtiglading i vårt omfattende ladenettverk"
      ],
      "productsOr": "{{prefix}} eller {{suffix}}",
      "noProducts": "Ingen produkter",
      "button": {
        "proceed": "Gå videre - totalpris {{price}}",
        "cancel": "Avbryt"
      },
      "priceMonthly": " / mnd",
      "selectProduct": "Velg produkt"
    },
    "details": {
      "heading": "Hei {{name}}, fullfør bestillingen din",
      "userInfo": {
        "heading": "Kundeinformasjon",
        "phone": "Mobilnummer: ",
        "email": "E-postadresse: ",
        "address": "Postadresse: \n"
      },
      "installation": {
        "heading": "Levering og installasjon",
        "instructions": {
          "heading": "Frakt og installasjon",
          "text": "Frakt og installasjon er inkludert i totalprisen. Så snart bestillingen er betalt vil vi bestille installatør og du vil få beskjed om installasjonsdato"
        },
        "address": {
          "heading": "Din ladeboks vil installeres ved lokasjonen {{locationName}} borettslag"
        }
      },
      "activate": {
        "heading": "Aktivering av din ladeboks",
        "text": "Etter laderen er installert og aktivert, vil månedlig driftsabonnement starte. \n\nHvis du ikke ønsker å bruke ladeboksen din akkurat nå, kan du velge å ikke aktivere den:"
      },
      "button": {
        "next": "Bekreft informasjon",
        "cancel": "Avbryt"
      },
      "field": {
        "useSameAddress": {
          "label": "Samme som i Kundeinformasjon"
        },
        "addressLine1": {
          "label": "Postadresse"
        },
        "postalCode": {
          "label": "Postnr."
        },
        "city": {
          "label": "Sted"
        },
        "instructions": {
          "label": "Installasjonsinstruksjoner"
        },
        "parkingSlot": {
          "label": "P-plass nr"
        },
        "activateCharger": {
          "label": "Jeg vil at ladeboksen skal monteres, men ikke aktiveres ved montering. Månedlig kostnad tilkommer først når ladeboksen er aktivert."
        }
      }
    },
    "payment": {
      "heading": "Hei {{name}}, fullfør bestillingen din",
      "terms": {
        "heading": "Samtykke",
        "labelPrefix": "Jeg har lest og samtykker til Mer sine ",
        "terms1Link": "generelle vilkår",
        "labelMiddle": " og ",
        "terms2Link": "særskilte vilkår",
        "labelSuffix": ".",
        "required": "Du må godta betingelsene for å fortsette"
      },
      "method": {
        "heading": "Betalingsmetode",
        "update": "",
        "existingCard": {
          "label": "Bruk eksisterende kort"
        },
        "newCard": {
          "label": "Velg et annet kort"
        },
        "card": {
          "label": "Velg et kort"
        },
        "eInvoice": {
          "label": "Faktura"
        }
      },
      "button": {
        "next": "Bekreft ordre",
        "cancel": "Avbryt"
      }
    },
    "complete": {
      "heading": "Tusen takk for din bestilling, {{name}}!",
      "instructions": "Takk for din bestilling. En bekreftelsesmail med ordresammendrag har blitt sendt til den oppgitte epostadressen: {{email}}",
      "customerSupport": "Har du bedt om senere driftstellese må du ta kontakt med kundeservice for å få den driftsatt",
      "continue": "Logg den inn på Mer Hub og last ned ladeappen Mer Connect for tilgang til vårt elektriske univers og oversikt over dine ladinger og kundefordeler.",
      "steps": [
        "Bekreftelse er sendt til din email",
        "Installatør vil ta kontakt på denne mail",
        "Når ladeboks er installert vil den bli driftsatt - med mindre du har bedt om senere driftsettelse."
      ],
      "link": " Gå til Mer Hub"
    }
  },
  "subscription": {
    "name": "Driftsabonnement",
    "description": "Driftsabonnementet etterfaktureres månedlig* og gjelder fra ladeboksen er aktivert. Du kan senere i bestillingsprosessen velge å utsette aktivering av ladeboksen.",
    "list": [
      "Minimum 15% rabatt i Mer sitt hurtigladenettverk**",
      "Fakturering basert på eget forbruk",
      "Full oversikt på forbruk og faktura på Mer Hub",
      "24/7 teknisk support"
    ],
    "annotations": [
      "* Strømforbruk tilkommer",
      "** Gjelder kun på Mer sine offentlig tilgjengelige hurtigladestasjoner"
    ],
    "summary": {
      "heading": "Tilleggstjenester",
      "description": "Månedlig driftsabonnement"
    },
    "priceMonthly": " {{price}} / mnd",
    "chargerActivation": {
      "text": "Din ladeboks driftsettes som {{state}}",
      "active": "aktiv",
      "inactive": "ikke aktiv"
    }
  },
  "stepper": {
    "step1": "Velg produkt",
    "step2": "Kontaktinformasjon",
    "step3": "Betaling"
  },
  "busy": {
    "confirming": {
      "payment": "Bekrefter betaling..."
    },
    "loading": {
      "page": "Laster siden..."
    },
    "completing": [
      "Behandler bestillingen",
      "Dette kan ta litt tid"
    ]
  },
  "orderSummary": {
    "heading": "Ordresammendrag",
    "orderNumber": "Ditt ordrenummer: ",
    "title": "Ordresammendrag",
    "orderValue": "Ordresum",
    "delivery": "Frakt",
    "standardInstallation": "Standard installasjon",
    "rfidTag": "RFID brikke*",
    "rfidTagDescription": "* Bestillingen inkluderer RFID brikke og blir sendt til adressen i kundeinformasjonen.",
    "totalCost": "Totalpris:",
    "taxIncluded": "Inkl. mva",
    "discount": "10% rabatt for deg som Volvo-kunde i Mer sitt offentlige ladenettverk",
    "editOrder": "Endre ordre",
    "proceedButton": "Gå videre - totalpris"
  },
  "delivery": {
    "title": "Levering og installasjon",
    "deliveryMethod": "Standard frakt og installasjon (inkludert i pris)",
    "addressTitle": "Installasjonsadresse",
    "parkingSlot": ", P-plass: {{parkingSlot}} ",
    "addressSummary": "{{addressLine1}}, {{city}}, {{postalCode}}",
    "instructions": "Installasjonsinstruksjoner: {{instructions}}"
  }
}