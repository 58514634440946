import {
    colorIllustrationLightBlue,
    colorModalOverlay,
    colorNeutralsWhite,
    colorOnWhiteDarkGrey,
    colorOnWhiteLighterGrey,
    colorPrimaryMerBlue,
    font,
    screenWidthSmall,
    transitionSnappy,
} from '~styles/variables';
import { useModal } from '~contexts/Modal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CloseIcon from '~assets/icons/close.svg';
import React, { useCallback } from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';

const ModalWrapper: React.FC = () => {
    ReactModal.setAppElement('body');
    const handleModal = useModal();
    const { t } = useTranslation(['common']);

    const ingress = handleModal.ingressIllustration ? (
        <Illustration src={handleModal.ingressIllustration as string} />
    ) : null;

    const closeModal = useCallback(() => {
        handleModal.close();
    }, [handleModal]);

    return (
        <CustomReactModal
            style={{ overlay: { backgroundColor: colorModalOverlay } }}
            isOpen={handleModal.isOpen}
            shouldCloseOnOverlayClick={handleModal.shouldCloseOnOverlayClick}
            shouldCloseOnEsc={handleModal.shouldCloseOnEsc}
            shouldReturnFocusAfterClose
            onRequestClose={() => {
                handleModal.close();
            }}
        >
            {handleModal.showIngress ? (
                <Ingress backgroundColor={handleModal.ingressBackground} closeButton={true}>
                    <CloseButton
                        onClick={closeModal}
                        title={t('modal.close')}
                        disabled={handleModal.isLocked}
                        backgroundColor={handleModal.ingressBackground}
                    >
                        <CloseIcon />
                    </CloseButton>
                    {ingress}
                </Ingress>
            ) : (
                <CloseButton onClick={closeModal} title={t('modal.close')} disabled={handleModal.isLocked}>
                    <CloseIcon />
                </CloseButton>
            )}
            {handleModal.showIngress ? (
                <Children>{handleModal.newModalContent}</Children>
            ) : (
                <CustomChildren>{handleModal.newModalContent}</CustomChildren>
            )}
        </CustomReactModal>
    );
};

type ModalRouteWrapperProps = {
    children: React.ReactNode;
    closeUrl?: string | null;
    ingressBackground?: string;
    ingressIllustration?: React.ReactNode;
    shouldCloseOnOverlayClick?: boolean;
    showIngress?: boolean;
};

export function ModalRouteWrapper(props: ModalRouteWrapperProps): JSX.Element {
    ReactModal.setAppElement('body');
    const { children, closeUrl, ingressBackground, ingressIllustration, shouldCloseOnOverlayClick, showIngress } =
        props;
    const navigate = useNavigate();
    const { t } = useTranslation(['common']);

    const close = () => {
        navigate(closeUrl as string);
    };

    const ingress = ingressIllustration ? <Illustration src={ingressIllustration as string} /> : null;

    return (
        <CustomReactModal
            style={{ overlay: { backgroundColor: colorModalOverlay } }}
            isOpen={true}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            shouldCloseOnEsc
            shouldReturnFocusAfterClose
            onRequestClose={close}
        >
            {showIngress ? (
                <Ingress backgroundColor={ingressBackground} closeButton={!!closeUrl}>
                    {closeUrl ? (
                        <CloseButton onClick={close} title={t('modal.close')} backgroundColor={ingressBackground}>
                            <CloseIcon />
                        </CloseButton>
                    ) : null}
                    {ingress}
                </Ingress>
            ) : closeUrl ? (
                <CloseButton onClick={close} title={t('modal.close')} backgroundColor={ingressBackground}>
                    <CloseIcon />
                </CloseButton>
            ) : null}
            <Children>{children}</Children>
        </CustomReactModal>
    );
}
const Ingress = styled.div<{ backgroundColor?: string; closeButton?: boolean }>`
    padding: 0.3rem 1.5rem 0.3rem 1.5rem;
    margin-bottom: 0 !important;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : colorNeutralsWhite)};
    height: ${(props) => (props.closeButton ? '12rem' : '9rem')};
    display: flex;
    justify-content: center;
`;

const CustomChildren = styled.div`
    padding: 3rem 1.5rem 1rem 1.5rem;
    margin-bottom: 0 !important;
`;

const Children = styled.div`
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    margin-bottom: 0 !important;
`;

const CustomReactModal = styled(ReactModal)`
    position: relative;
    background-color: ${colorNeutralsWhite};
    outline: none;
    z-index: 100;
    max-width: 90%;
    margin: 2rem auto;
    box-shadow: 0 5px 10px 5px rgba(107, 107, 107, 0.5);
    > div,
    > span,
    > p,
    > a,
    > figure {
        margin-bottom: 1.5rem;
    }
    > a {
        display: inline-block;
    }
    p.blue {
        color: ${colorPrimaryMerBlue};
        font-family: 'SharpSans Semibold', sans-serif;
    }
    .note {
        font-size: ${font.size.s};
        color: ${colorOnWhiteDarkGrey};
    }
    .big-blue {
        display: inline-block;
        margin-bottom: 0.5rem;
        color: ${colorPrimaryMerBlue};
        font-family: 'SharpSans Bold', sans-serif;
        font-size: ${font.size.xxl};
        > span {
            font-family: 'SharpSans Semibold', sans-serif;
            font-size: ${font.size.m};
        }
    }
    .big-buttons {
        button {
            margin-bottom: 1rem;
            width: 100%;
            justify-content: center;
        }
    }
    h2 {
        margin-bottom: 1.5rem;
        font-family: 'SharpSans Bold', sans-serif;
        font-size: 1.5rem;
    }
    h4 {
        margin-bottom: 1.5rem;
        font-size: ${font.size.xl};
        font-family: 'SharpSans Bold', sans-serif;
    }
    ul {
        margin-bottom: 2rem;
        font-size: ${font.size.s};
        list-style: inside;
        li {
            margin-bottom: 1rem;
            line-height: ${font.lineHeight.m};
        }
    }
    @media screen and (min-width: ${screenWidthSmall}) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        min-width: 500px;
        max-width: 550px;
        max-height: 95vh;
        overflow-y: auto;
    }
`;

const CloseButton = styled.button<{ backgroundColor?: string }>`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 44px !important;
    height: 44px;
    cursor: pointer;

    border: none;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : colorNeutralsWhite)};
    transition: all ${transitionSnappy};
    & svg {
        width: 2rem;
        fill: ${colorPrimaryMerBlue};
    }
    &:hover:not(:disabled) {
        background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : colorOnWhiteLighterGrey)};
    }
    &:focus {
        outline: 4px solid ${colorIllustrationLightBlue};
    }
    &:disabled {
        svg {
            fill: ${colorOnWhiteLighterGrey};
        }
    }
`;

const Illustration = styled.img`
    margin: auto;
    z-index: 3;
    max-height: 8rem;
`;
export default ModalWrapper;
