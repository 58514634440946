import NavigationGroup, { NavigationGroupProps } from '~components/navigation/NavigationGroup';
import React, { useState } from 'react';

export type NavigationGroupListProps = {
    groups: Pick<NavigationGroupProps, 'title' | 'icon' | 'links'>[];
};

export default function NavigationGroupList(props: NavigationGroupListProps): JSX.Element {
    const { groups } = props;
    const [expanded, setExpanded] = useState<number | undefined>();

    return (
        <>
            {groups.map((group, index) => (
                <NavigationGroup
                    key={index}
                    {...group}
                    expanded={expanded === index}
                    onClick={() => setExpanded(index === expanded ? undefined : index)}
                />
            ))}
        </>
    );
}
