// noinspection JSUnusedGlobalSymbols

export enum ERefundPeriod {
    CURRENT = 'CURRENT',
    PREVIOUS = 'PREVIOUS',
}

export default interface IRefund {
    //TODO: update this to the actual value returned from aorta
    bankAccountNumber: string;
    fromDate: string;
    lastUpdated: string;
    locationName: string;
    projectNumber: string;
    revenue: number;
    revenueSharingCalculated: number;
    revenueSharingPercentage: number;
    status: string;
    toDate: string;
    totalKWH: number;
}
