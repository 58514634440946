{
  "page": {
    "orderPayment": {
      "stepper": {
        "step1": "Din bestilling",
        "step2": "Logg inn / registrer",
        "step3": "Fullfør bestilling",
        "step4": "Betaling"
      },
      "heading": "Fullfør bestilling",
      "title": "Kundeinformasjon",
      "delivery": {
        "title": "Levering og installasjon",
        "deliveryMethod": "Standard frakt og installasjon (inkludert i pris)",
        "instructions": "Vår installatør vil kontakte deg for nærmere avtale. Installatøren tar med seg ladeboksen på installasjonsdagen.",
        "addressTitle": "Installasjonsadresse",
        "addressSummary": "{{addressLine1}}\n{{city}}, {{postalCode}}"
      },
      "payment": {
        "title": "Betalingsmetode"
      },
      "completeOrder": "Bekreft ordre",
      "orderSummary": {
        "title": "Ordresammendrag",
        "orderValue": "Ordresum",
        "delivery": "Frakt",
        "standardInstallation": "Standard installasjon",
        "totalCost": "Totalsum",
        "taxIncluded": "Inkl. mva",
        "discount": "10% rabatt for deg som Volvo-kunde i Mer sitt offentlige ladenettverk",
        "editOrder": "Endre ordre",
        "proceedButton": "Gå videre - totalpris"
      },
      "selectCharger": {
        "heading": "Velg lader",
        "taxIncluded": "Inkl. mva"
      },
      "operatingServices": {
        "heading": "Fordeler og tjenester",
        "listItem1": "10% rabatt for deg som Volvo-kunde i Mer sitt offentlige ladenettverk",
        "listItem2": "Fakturering basert på eget forbruk",
        "listItem3": "Full oversikt over forbruk og faktura",
        "listItem4": "24/7 teknisk support"
      },
      "productDetails": {
        "technicalSpecifications": "Teknisk informasjon",
        "installationInformation": "Informasjon om standard installasjon"
      }
    },
    "orderComplete": {
      "heading": "Tusen takk for din bestilling!",
      "yourOrderNumber": "Ditt ordrenummer:",
      "confirmation": "En epost med bekreftelse har blitt sendt til epostadressen: {{email}}",
      "title": "Velkommen til Mer",
      "message1": "Opplev vår elektriske univers og logg deg inn på Mer Hub! ",
      "message2": "Ikke gå glipp av din eksklusive Volvo-fordel - gå inn på Mer Hub og aktiver den, slik at du får 10% rabatt i vårt offentlige ladenettverk! For å aktivere 10% må du i Mer Hub og legge til bilen din under \"kjøretøy\". \n\nDenne fordelen får du i tillegg til de andre gode kundefordelene hos Mer.",
      "goToHub": "Gå til Min side"
    }
  },
  "payment": {
    "initializing": "Initialisering",
    "creating": "Legger til betalingsmetode...",
    "authorizing": "Godkjenner betalingsmetode...",
    "error": {
      "cancelled": "Betalingsmetoden feilet, bruker kansellerte transaksjonen",
      "error": "Betalingsmetoden feilen, vennligst forsøk igjen",
      "refused": "Betalingsmetoden ble avvist, vennligst forsøk en annen betalingsmetode"
    },
    "challenge": {
      "redirect": "Du vil bli videresendt til din betalingskort tilbyder, vennligst vent",
      "challenge": "Betalingsmetoden venter på informasjon, vennligst vent",
      "identify": "Betalingsmetoden venter på informasjon, vennligst vent"
    },
    "success": {
      "existing": "Eksisterende betalingsmetode fungerte ",
      "authorised": "Betalingen var vellykket",
      "pending": "Betalingsmetode ble godkjent og prosessen fortsetter",
      "received": "Betalingsmetode ble godkjent og prosessen fortsetter"
    },
    "label": {
      "newCard": "Bruk kort",
      "existingCard": "Bruk et annet kort",
      "updatePaymentMethod": "Bytt betalingsmetode"
    }
  },
  "busy": {
    "confirming": {
      "payment": "Bekrefter betaling..."
    },
    "loading": {
      "page": "Laster siden..."
    },
    "completing": [
      "Bestillingen din vil nå bli opprettet i Mer Hub.",
      "Betalingen din blir behandlet.",
      "Dette kan ta omtrent 30 sekunder til 1 minutt."
    ]
  }
}