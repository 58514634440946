export type PollingConfig = {
    delay: number;
    maxAttempts: number;
};

export const POLLING_CONFIG_MINIMUM = {
    // 30 seconds of polling
    maxAttempts: 10,
    delay: 3000,
};

export const POLLING_CONFIG_SHORT = {
    // one minute of polling
    maxAttempts: 15,
    delay: 4000,
};

export const POLLING_CONFIG_MEDIUM = {
    // two minutes of slow polling
    maxAttempts: 20,
    delay: 6000,
};

export const POLLING_CONFIG_LONG = {
    // five minutes of slow polling
    maxAttempts: 50,
    delay: 6000,
};
