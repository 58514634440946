import { EAuth0Provider } from '~interfaces/Auth0';
import { GetTokenWithPopupOptions } from '@auth0/auth0-react';

export const LOGIN_RETURN_PATH = '/return';
export const LOGOUT_RETURN_PATH = '/login';
export const LOGOUT_REDIRECT_PATH = '/redirect';

export const AUTH0_API_AUDIENCE = process.env.REACT_APP_AUTH_0_AUDIENCE;
export const AUTH0_API_DOMAIN = process.env.REACT_APP_AUTH_0_ISSUER;
export const AUTH0_API_CLIENT_ID = process.env.REACT_APP_AUTH_0_CLIENT_ID;
export const AUTH0_API_SCOPE = 'openid email profile read:current_user';

export const AUTH0_API_CLIENT_CONFIG = {
    domain: AUTH0_API_DOMAIN,
    clientId: AUTH0_API_CLIENT_ID,
    audience: AUTH0_API_AUDIENCE,
    scope: AUTH0_API_SCOPE,
    redirect_uri: `${window.location.origin}${LOGIN_RETURN_PATH}`,
};

export const AUTH0_MANAGE_IDENTITIES_TOKEN_CONFIG: GetTokenWithPopupOptions = {
    authorizationParams: {
        audience: `https://${process.env.REACT_APP_AUTH_0_ISSUER}/api/v2/`,
        scope: 'openid profile read:current_user update:current_user_identities',
    },
};

export const AUTH0_LINK_IDENTITY_TOKEN_CONFIG = {
    domain: process.env.REACT_APP_AUTH_0_ISSUER || '',
    clientId: process.env.REACT_APP_AUTH_0_CLIENT_ID || '',
};

export const AUTH0_LINK_ACCOUNTS_TOKEN_CONFIG = {
    domain: process.env.REACT_APP_AUTH_0_ISSUER || '',
    clientId: process.env.REACT_APP_AUTH_0_CLIENT_ID || '',
    scope: 'openid profile read:current_user update:current_user_identities',
};

export const PROVIDER_EXPIRING = EAuth0Provider.SMS;
export const PROVIDER_EXPIRING_DATE = '2021-10-01';

// Tim: Social media providers are currently disabled
// export const ADD_IDENTITY_PROVIDERS: EAuth0Provider[] = [EAuth0Provider.APPLE, EAuth0Provider.GOOGLE, EAuth0Provider.FACEBOOK];
export const ADD_IDENTITY_PROVIDERS: EAuth0Provider[] = [EAuth0Provider.AUTH0];

export const MOBILE_RETURN_URL_CONTAINS = ['eco.mer.chargeapp'];

export const LAST_LOGIN_SECONDS_LIMIT_FOR_REVERIFICATION = 60;
