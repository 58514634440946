import { colorNeutralsBlack, colorNeutralsWhite, colorOnWhiteLighterGrey, colorSecondaryRed } from '~styles/variables';
import FormErrorIcon from '~assets/icons/form_error.svg';
import React from 'react';
import styled from '@emotion/styled';

export const Error = styled.div`
    display: flex;
    position: relative;
    justify-content: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0;
    margin-bottom: 2rem;
    align-items: center;
    font-size: 0.875rem;
    line-height: 0.875rem;
    border-radius: 4px;
    color: ${colorNeutralsBlack};
    border: 1px solid ${colorSecondaryRed};
    background-color: ${colorOnWhiteLighterGrey};
    gap: 1rem;

    &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        bottom: -1px;
        width: 3rem;
        z-index: 0;
        border-radius: 4px 0 0 4px;
        background-color: ${colorSecondaryRed};
    }

    .icon {
        flex-grow: 0;
        padding: 0 0.5rem;
        align-self: center;
        font-size: 0;
        line-height: 0;
        position: relative;
        z-index: 2;

        & svg {
            fill: ${colorNeutralsWhite};
            width: 2rem;
            height: 2rem;
        }
    }
`;

type ErrorProps = {
    children: React.ReactNode;
    showIcon?: boolean;
};

export default function ErrorComponent(props: ErrorProps): JSX.Element {
    const { children, showIcon = true } = props;
    return (
        <Error>
            {showIcon ? (
                <span className={'icon'}>
                    <FormErrorIcon />
                </span>
            ) : null}
            {children}
        </Error>
    );
}
