import React, { ReactNode, createContext, memo, useCallback, useContext, useMemo, useState } from 'react';

type Props = {
    children: ReactNode;
};

export type ModalContextType = {
    close: () => void;
    ingressBackground: string | undefined;
    ingressIllustration: React.ReactNode;
    isLocked: boolean;
    isOpen: boolean;
    lockModal: (v: boolean) => void;
    newModalContent: ReactNode;
    open?: (component: JSX.Element) => void;
    setIngressBackground: (v: string | undefined) => void;
    setIngressIllustration: (component: React.ReactNode) => void;
    setShowIngress: (v: boolean) => void;
    shouldCloseOnEsc: boolean;
    shouldCloseOnOverlayClick: boolean;
    showIngress: boolean;
};

const ModalContext = createContext<ModalContextType>({
    close: () => undefined,
    ingressBackground: undefined,
    ingressIllustration: null,
    isLocked: false,
    isOpen: false,
    lockModal: () => undefined,
    newModalContent: null,
    setIngressBackground: () => undefined,
    setIngressIllustration: () => undefined,
    setShowIngress: () => undefined,
    shouldCloseOnEsc: true,
    shouldCloseOnOverlayClick: true,
    showIngress: false,
});
const ModalProvider = memo(function ModalProvider(props: Props): JSX.Element {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState<JSX.Element>();
    const [isLocked, setIsLocked] = useState(false);
    const [showIngress, setShowIngress] = useState(false);
    const [ingressIllustration, setIngressIllustration] = useState<React.ReactNode>();
    const [ingressBackground, setIngressBackground] = useState<string | undefined>(undefined);

    const closeModal = useCallback(() => {
        setShowIngress(false);
        setIngressIllustration(null);
        setIngressBackground(undefined);
        setModalOpen(false);
    }, [setModalOpen]);

    const openModal = useCallback(
        (newContent: JSX.Element) => {
            setModalOpen(true);
            setModalContent(newContent);
        },
        [setModalOpen, setModalContent],
    );

    const lockModal = useCallback(
        (v: boolean) => {
            setIsLocked(v);
        },
        [setIsLocked],
    );

    const context = useMemo(
        () => ({
            isOpen: modalOpen,
            close: closeModal,
            open: openModal,
            newModalContent: modalContent,
            shouldCloseOnEsc: !isLocked,
            shouldCloseOnOverlayClick: !isLocked,
            lockModal: lockModal,
            isLocked: isLocked,
            setShowIngress,
            showIngress,
            ingressIllustration,
            setIngressIllustration,
            ingressBackground,
            setIngressBackground,
        }),
        [
            modalOpen,
            closeModal,
            openModal,
            modalContent,
            isLocked,
            lockModal,
            showIngress,
            ingressIllustration,
            ingressBackground,
        ],
    );

    return <ModalContext.Provider value={context} {...props} />;
});

const useModal = (): ModalContextType => useContext(ModalContext);
export { ModalProvider, useModal };
