import { DEFAULT_ERROR_CODE, DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_STATUS } from '~@constants/errors';
import { UserRole } from '~@types';
import { useAuth } from '~contexts/Auth';
import { useAuth0 } from '@auth0/auth0-react';
import ApiError from '~classes/ApiError';
import ApiResponse from '~interfaces/ApiResponse';
import ApiResponseError from '~interfaces/ApiResponseError';
import React, { ReactNode, createContext, useContext } from 'react';

export enum EApiVersion {
    V1 = 'v1',
    V2 = 'v2',
}

export type ApiRequestOptions = {
    headers?: ApiRequestHeaders;
    isPublic?: boolean;
    omitVersion?: boolean;
    raw?: boolean;
    version?: EApiVersion;
};
export type ApiRequestHeaders = Record<string, string>;

export type ApiRequestMethod = <ResponseData = ApiResponse>(
    url: string,
    options?: ApiRequestOptions,
) => Promise<ResponseData>;

export type ApiRequestPayloadMethod<> = <ResponseData = ApiResponse, Payload = unknown>(
    url: string,
    payload: Payload,
    options?: ApiRequestOptions,
) => Promise<ResponseData>;

interface IApiContext {
    del: ApiRequestMethod;
    delWithPayload: ApiRequestPayloadMethod;
    get: ApiRequestMethod;
    patch: ApiRequestPayloadMethod;
    post: ApiRequestPayloadMethod;
}

type ApiProviderProps = {
    apiUrl: string;
    apiVersion?: string;
    children: ReactNode;
    isPublic?: boolean;
};

export enum EHttpMethod {
    DELETE = 'DELETE',
    GET = 'GET',
    PATCH = 'PATCH',
    POST = 'POST',
}

const DEFAULT_HEADERS = {
    accept: 'application/json',
    assumedRole: UserRole.DRIVER,
    'x-epower-authentication-provider': 'auth0',
    'User-Client': 'MerPortal/v1',
};

const WITH_PAYLOAD_HEADERS = {
    'Content-Type': 'application/json',
};

const httpMethodHeaders = {
    [EHttpMethod.GET]: DEFAULT_HEADERS,
    [EHttpMethod.POST]: { ...DEFAULT_HEADERS, ...WITH_PAYLOAD_HEADERS },
    [EHttpMethod.PATCH]: { ...DEFAULT_HEADERS, ...WITH_PAYLOAD_HEADERS },
    [EHttpMethod.DELETE]: DEFAULT_HEADERS,
};

const httpMethodSuccessCodes = {
    [EHttpMethod.GET]: [200],
    [EHttpMethod.POST]: [200, 201, 202, 204],
    [EHttpMethod.PATCH]: [200, 204],
    [EHttpMethod.DELETE]: [200, 204],
};

const statusErrorCodes: Record<number, string> = {
    404: 'NOT_FOUND',
};

const httpStatusCodesWithResponseData = [200];

enum EResponseType {
    NONE,
    TEXT,
    JSON,
    BLOB,
}

const getResponseTypeFromContentType = (contentType?: string) => {
    if (!contentType) return EResponseType.NONE;
    if (/text/i.test(contentType)) return EResponseType.TEXT;
    if (/json/i.test(contentType)) return EResponseType.JSON;
    if (/pdf/i.test(contentType)) return EResponseType.BLOB;
};

async function getResponseBody<ResponseType = string>(
    response: Response,
    responseType: EResponseType.TEXT,
): Promise<ResponseType>;
async function getResponseBody<ResponseType = Record<string, unknown>>(
    response: Response,
    responseType: EResponseType.JSON,
): Promise<ResponseType>;
async function getResponseBody<ResponseType = Blob>(
    response: Response,
    responseType: EResponseType.BLOB,
): Promise<ResponseType>;
async function getResponseBody<ResponseType = undefined>(
    response: Response,
    responseType: EResponseType.NONE,
): Promise<ResponseType>;
async function getResponseBody(
    response: Response,
    responseType?: EResponseType,
): Promise<string | Record<string, unknown> | Blob | undefined>;
async function getResponseBody(response: Response, responseType?: EResponseType) {
    responseType = responseType || getResponseTypeFromContentType(response.headers.get('content-type') || undefined);
    switch (responseType) {
        case EResponseType.TEXT: {
            return await response.text();
        }
        case EResponseType.JSON: {
            return (await response.json()) as Record<string, unknown>;
        }
        case EResponseType.BLOB: {
            return await response.blob();
        }
        default: {
            return undefined;
        }
    }
}

type GenerateApiErrorProps = {
    additionalParams?: Record<string, unknown>;
    errorCode?: string;
    message?: string;
    path?: string;
    status?: number;
};

export const generateApiError = ({
    errorCode,
    message = DEFAULT_ERROR_MESSAGE,
    status = DEFAULT_ERROR_STATUS,
    path = 'api.generateApiError',
    additionalParams,
}: GenerateApiErrorProps = {}) =>
    new ApiError(message, {
        error: message,
        message,
        path,
        status,
        errorCode: errorCode ?? statusErrorCodes[status] ?? DEFAULT_ERROR_CODE,
        timestamp: new Date().getTime(),
        ...(additionalParams || {}),
    });

export type DoFetchOptions = {
    headers?: ApiRequestHeaders;
    method?: EHttpMethod;
    methodHeaders?: ApiRequestHeaders;
    payload?: unknown;
    raw?: boolean;
    token?: string | null;
};

export async function doFetch<ResponseData>(url: string, options: DoFetchOptions): Promise<ResponseData> {
    const { token, method = EHttpMethod.GET, payload, headers, raw = false, methodHeaders } = options;

    /*
    BUILD REQUEST
     */
    let response;
    const requestHeaders = {
        ...(methodHeaders ? methodHeaders : httpMethodHeaders[method]),
        ...(token ? { authorization: `Bearer ${token}` } : {}),
        ...(headers ? headers : {}),
    };

    /*
    MAKE REQUEST
     */
    try {
        response = await fetch(url, {
            method,
            headers: requestHeaders,
            body: payload ? JSON.stringify(payload) : undefined,
        });
    } catch (e) {
        console.error(e);
        throw generateApiError({
            message: 'Fetching data failed',
            path: 'api.fetch',
        });
    }

    /*
    DETERMINE SUCCESS

    If we dont get a successful response status try and process the error response body and throw an error
     */
    if (!httpMethodSuccessCodes[method].includes(response?.status)) {
        let error;

        try {
            const errorResponse = await getResponseBody<ApiResponseError>(response, EResponseType.JSON);
            error = generateApiError({
                message: errorResponse?.status?.toString() || response.statusText || undefined,
                status: response?.status || undefined,
                errorCode: errorResponse?.errorCode,
                path: `${url}->${errorResponse?.status}`,
                additionalParams: errorResponse,
            });
        } catch (e) {
            console.error(e);
            error = generateApiError({
                message: 'Error response content could not be read',
                path: url,
                errorCode: 'UNREADABLE_RESPONSE',
            });
        }

        throw error;
    }

    /*
    BUILD RESPONSE

    If there data expected from the response type try to return it
     */
    if (response?.status && httpStatusCodesWithResponseData.includes(response.status))
        // Catch response read errors in case the api sends no content when content is expected
        try {
            return raw
                ? ((await response.blob()) as unknown as ResponseData)
                : ((await response.json()) as ResponseData);
        } catch (e) {
            throw generateApiError({
                message: 'The response type from the server is incorrect',
                errorCode: 'UNEXPECTED_RESPONSE',
                path: `${method}->${url}`,
            });
        }
    else return undefined as unknown as ResponseData;
}

const ApiContext = createContext<IApiContext>({
    get: () => {
        throw new Error('get has not been implemented');
    },
    post: () => {
        throw new Error('post has not been implemented');
    },
    patch: () => {
        throw new Error('patch has not been implemented');
    },
    del: () => {
        throw new Error('del has not been implemented');
    },
    delWithPayload: () => {
        throw new Error('delWithPayload has not been implemented');
    },
});

const ApiProvider: React.FC<ApiProviderProps> = (props: ApiProviderProps) => {
    const { children, apiUrl, apiVersion, isPublic: apiIsPublic = false } = props;
    const useVersioning = !!apiVersion;
    const { isAuthenticated } = useAuth();
    const { getAccessTokenSilently } = useAuth0();

    const getToken = async () => {
        if (!isAuthenticated) {
            console.error('ApiProvider:getToken called when user is unauthenticated for', apiUrl);
            throw new Error(
                'ApiProvider:getToken called when user is unauthenticated. ' +
                    'Authenticated api requests must check for authentication before being made.',
            );
        }
        try {
            return await getAccessTokenSilently();
        } catch (e) {
            console.error('ApiProvider:getAccessTokenSilently failed', e);
            throw generateApiError({
                message: 'Unable to get auth0 access token',
                errorCode: 'AUTH0_TOKEN_REQUEST_FAILED',
                path: 'api.getToken',
            });
        }
    };

    async function get<ResponseData = ApiResponse>(
        url: string,
        options: ApiRequestOptions = {},
    ): Promise<ResponseData> {
        const { omitVersion = !useVersioning, isPublic = apiIsPublic, headers = {}, raw, version } = options;
        const token = isPublic ? null : await getToken();
        const completeUrl = url.includes('http://')
            ? url
            : `${apiUrl}${omitVersion ? '' : `/${version ?? apiVersion}`}${url}`;
        return await doFetch<ResponseData>(completeUrl, { token, method: EHttpMethod.GET, headers, raw });
    }

    async function post<ResponseData, Payload = unknown>(
        url: string,
        payload: Payload,
        options: ApiRequestOptions = {},
    ): Promise<ResponseData> {
        const { omitVersion = !useVersioning, isPublic = apiIsPublic, headers = {}, version } = options;
        const token = isPublic ? null : await getToken();
        const completeUrl = `${apiUrl}${omitVersion ? '' : `/${version ?? apiVersion}`}${url}`;
        return await doFetch<ResponseData>(completeUrl, { token, method: EHttpMethod.POST, payload, headers });
    }

    async function patch<ResponseData, Payload = unknown>(
        url: string,
        payload: Payload,
        options: ApiRequestOptions = {},
    ): Promise<ResponseData> {
        const { omitVersion = !useVersioning, isPublic = apiIsPublic, headers = {}, version } = options;
        const token = isPublic ? null : await getToken();
        const completeUrl = `${apiUrl}${omitVersion ? '' : `/${version ?? apiVersion}`}${url}`;
        return await doFetch<ResponseData>(completeUrl, { token, method: EHttpMethod.PATCH, payload, headers });
    }

    async function del<ResponseData>(url: string, options: ApiRequestOptions = {}): Promise<ResponseData> {
        const { omitVersion = !useVersioning, isPublic = apiIsPublic, headers = {}, version } = options;
        const token = isPublic ? null : await getToken();
        const completeUrl = `${apiUrl}${omitVersion ? '' : `/${version ?? apiVersion}`}${url}`;
        return await doFetch<ResponseData>(completeUrl, { token, method: EHttpMethod.DELETE, headers });
    }

    async function delWithPayload<ResponseData, Payload = unknown>(
        url: string,
        payload: Payload,
        options: ApiRequestOptions = {},
    ): Promise<ResponseData> {
        const { omitVersion = !useVersioning, isPublic = apiIsPublic, headers = {}, version } = options;
        const token = isPublic ? null : await getToken();
        const completeUrl = `${apiUrl}${omitVersion ? '' : `/${version ?? apiVersion}`}${url}`;
        return await doFetch<ResponseData>(completeUrl, { token, method: EHttpMethod.DELETE, payload, headers });
    }

    return <ApiContext.Provider value={{ get, post, patch, del, delWithPayload }}>{children}</ApiContext.Provider>;
};

const useApi: () => IApiContext = () => useContext(ApiContext);

export { ApiProvider };
export default useApi;

async function delayBy(ms: number) {
    // return await for better async stack trace support in case of errors.
    return await new Promise((resolve) => setTimeout(resolve, ms));
}

type ApiPollingProps<ResponseData = unknown> = {
    delay: number;
    maxAttempts: number;
    options: ApiRequestOptions;
    stopPolling: (error: ApiError | null, result: ResponseData | null) => boolean;
    url: string;
};

type UseApiPollingResponse = {
    poll: <ResponseData>(
        url: string,
        options: ApiRequestOptions,
        stopPolling: (error: ApiError | null, result: ResponseData | null) => boolean,
        maxAttempts: number,
        delay: number,
    ) => Promise<ResponseData | null>;
    pollMultiple: (configs: ApiPollingProps[]) => Promise<void>;
};

export function useApiPolling(): UseApiPollingResponse {
    const { get } = useApi();

    async function poll<ResponseData>(
        url: string,
        options: ApiRequestOptions,
        stopPolling: (error: ApiError | null, result: ResponseData | null) => boolean,
        maxAttempts = 10,
        delay = 1000,
    ): Promise<ResponseData | null> {
        let stop = false;
        let count = 0;
        let response: ResponseData | undefined = undefined;

        const doPoll = async () => {
            try {
                response = (await get<ResponseData>(url, options)) || undefined;
                return stopPolling(null, response || null);
            } catch (e) {
                return stopPolling(e as ApiError, null);
            }
        };

        while (!stop && count < maxAttempts) {
            stop = await doPoll();
            if (stop) break;
            await delayBy(delay);
            count++;
        }

        return response || null;
    }

    async function pollMultiple(configs: ApiPollingProps[]) {
        await Promise.all(
            configs.map((config) =>
                poll(config.url, config.options, config.stopPolling, config.maxAttempts, config.delay),
            ),
        );
    }

    return {
        poll,
        pollMultiple,
    };
}
