import { Outlet } from 'react-router-dom';
import { SectionLayout } from '~components/layout';
import { screenWidthMinimum } from '~styles/variables';
import Header from '~components/layout/Header';
import MenuPanel from '~components/layout/MenuPanel';
import React from 'react';
import UserProfile from '~components/layout/UserProfile';
import styled from '@emotion/styled';

export const LayoutDefault = () => {
    return (
        <>
            <Header />
            <UserProfile />
            <AppContents>
                <MenuPanel />
                <MainContainer>
                    <Outlet />
                </MainContainer>
            </AppContents>
        </>
    );
};

export const LayoutWithoutNav = () => {
    return (
        <>
            <Header />
            <MainContainer>
                <Outlet />
            </MainContainer>
        </>
    );
};

export const LayoutIsolated = () => {
    return (
        <>
            <Header />
            <MainContainer>
                <Outlet />
            </MainContainer>
        </>
    );
};

export const LayoutInterrupt = () => {
    return (
        <InterruptContainer>
            <Outlet />
        </InterruptContainer>
    );
};

export const LayoutBlocked = () => {
    return (
        <main>
            <Outlet />
        </main>
    );
};

export const LayoutSubsection = () => {
    return (
        <SectionLayout>
            <Outlet />
        </SectionLayout>
    );
};

const InterruptContainer = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const AppContents = styled.div`
    min-height: calc(100vh - 5rem);
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;

    & > main {
        flex: 1;
    }
`;

const MainContainer = styled.main`
    min-width: ${screenWidthMinimum};
    padding-bottom: 60px;
    min-height: calc(100vh - 5rem);
`;
