import { colorNeutralsBlack, colorNeutralsWhite, colorPrimaryMerBlue } from '~styles/variables';
import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export enum EIconSize {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
}

export enum EIconColor {
    BLACK = 'black',
    INHERIT = 'inherit',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    WHITE = 'white',
}

export type IconProps = HTMLAttributes<HTMLDivElement> & {
    color?: EIconColor;
    size?: EIconSize;
};

export default function Icon(props: IconProps): JSX.Element {
    const { size = EIconSize.MEDIUM, color = EIconColor.INHERIT, children, className, ...divProps } = props;
    return (
        <IconContainer className={clsx('icon', size, color, className)} {...divProps}>
            {children}
        </IconContainer>
    );
}

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0;

    &.${EIconSize.LARGE} {
        & svg {
            height: 2rem;
            width: 2rem;
        }
    }
    &.${EIconSize.MEDIUM} {
        & svg {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
    &.${EIconSize.SMALL} {
        & svg {
            height: 1rem;
            width: 1rem;
        }
    }
    &.${EIconColor.INHERIT} {
        & svg {
            fill: currentColor;
        }
    }
    &.${EIconColor.BLACK} {
        & svg {
            fill: ${colorNeutralsBlack};
        }
    }
    &.${EIconColor.WHITE} {
        & svg {
            fill: ${colorNeutralsWhite};
        }
    }
    &.${EIconColor.PRIMARY} {
        & svg {
            fill: ${colorPrimaryMerBlue};
        }
    }
`;
