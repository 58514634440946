{
    "verifyPhone": {
        "heading": "Bekreft ditt mobilnummer",
        "sending": "Sending verification code...",
        "sent": "Vi har sendt en bekreftelseskode til din mobil {{phoneNumber}}. Vennligst oppgi koden for å fortsette.",
        "send": "For å være sikker på at riktig informasjon er registrert, vil vi sende deg en bekreftelseskode på telefon {{phoneNumber}}.",
        "majorError": "Det oppstod et problem ved sending av bekreftelseskode.",
        "button": {
            "send": "Send bekreftelseskode",
            "sendBlocked": "Send bekreftelseskode på {{timeRemaining}} sekunder",
            "submit": "Fortsett",
            "cancel": "Lukk",
            "tryAgain": "Send kode på nytt",
            "tryAgainBlocked": "Send kode på nytt {{timeRemaining}} seconds"
        },
        "field": {
            "code": {
                "placeholder": "Angi verifikasjonskode"
            }
        },
        "error": {
            "PHONE_NUMBER_INVALID": "Ditt mobilnummer er ugyldig",
            "PHONE_VALIDATION_EXPIRED": "Bekreftelseskoden er utgått",
            "PHONE_VALIDATION_INVALID": "Valideringskoden er ugyldig",
            "PHONE_VALIDATION_REQUIRED": "Valideringskode kreves",
            "PHONE_NUMBER_TOO_MANY_REQUESTS": "For mange forsøk. Prøv igjen senere."
        }
    },
    "verifyEmail": {
        "heading": "Bekreft din e-postadresse",
        "button": {
            "send": "Send",
            "reload": "Jeg har verifisert e-postadressen min"
        },
        "instructions": {
            "send": "Før du går videre trenger vi å bekrefte din epostadresse. Vi har sendt en lin til eposten: {{email}}, følg linken i eposten for å fortsette.",
            "sent": "Vi har sendt deg en e-post til {{email}}.\n\nGå til mailen og klikk på linken for å verifisere e-postadressen din. Deretter fullfører du verifisering ved å klikke \"jeg har verifisert e-postadressen min\" under.",
            "verified": "Din e-postadresse {{email}} er verifisert."
        }
    },
    "emailVerified": {
        "heading": "Din e-postadresse er verifisert!",
        "message": "Du kan nå lukke dette vinduet.",
        "continueToAftermarket":{
            "prefix":"Hvis du er i ferd med å bestille hjemmelader kan du  ",
            "link":"returnere til bestillingen",
            "suffix":" for å fullføre."
        }
    }
}
