import {
    EOrganizationClassification,
    EOrganizationPaymentType,
    EOrganizationStatus,
    IOrganization,
} from '~interfaces/Organization';
import { ETrackingAccountType, ETrackingEvent } from '~@types/tracking';
import { SWR_CONFIG_RETRY_LONG } from '~@constants/swr';
import { isCardExpired, isCardExpiring } from '~utils/payment';
import { useMemo } from 'react';
import { useUser } from '~contexts/User';
import ApiError from '~classes/ApiError';
import useAnalytics from '~hooks/useAnalytics';
import useApi from '~contexts/Api';
import useOrganizationPaymentCards from '~hooks/useOrganizationPaymentCards';
import useSWR from 'swr';

export type UseOrganizationProps = {
    organizationId?: string;
};

export type UseOrganizationsResponse = {
    canAddTags?: boolean;
    canInviteMembers?: boolean;
    canOrderTags?: boolean;
    canUpdateTags?: boolean;
    error?: ApiError;
    hasExpiredPaymentCard?: boolean;
    hasExpiringPaymentCard?: boolean;
    hasValidPaymentMethod?: boolean;
    isAddressRequired?: boolean;
    loading?: boolean;
    organization?: IOrganization;
    organizationBlocked?: boolean;
    organizationDeleting?: boolean;
    update: (payload: Partial<IOrganization>) => Promise<IOrganization>;
};

export const BASE_API_PATH = '/organizations';

export default function useOrganization(props?: UseOrganizationProps): UseOrganizationsResponse {
    const { get, patch } = useApi();
    const { activeOrganizationId } = useUser();
    const { organizationId = activeOrganizationId } = props || {};
    const { trackGA4Event } = useAnalytics();
    const { cards, loading: organizationPaymentCardsLoading } = useOrganizationPaymentCards({ organizationId });

    const {
        data: organization,
        error: error,
        isValidating: organizationLoading,
        mutate,
    } = useSWR<IOrganization | undefined, ApiError>(
        organizationId ? `${BASE_API_PATH}/${organizationId}` : null,
        get,
        SWR_CONFIG_RETRY_LONG,
    );

    const update: UseOrganizationsResponse['update'] = async (payload) => {
        const updatedOrganization = await patch<IOrganization>(`${BASE_API_PATH}/${organizationId}`, payload);
        const combinedUpdatedOrganization = {
            ...organization,
            ...updatedOrganization,
        };
        if (updatedOrganization.paymentType)
            trackGA4Event(ETrackingEvent.PAYMENT_UPDATE, {
                type: ETrackingAccountType.ORGANIZATION,
                value: updatedOrganization.paymentType,
            });

        await mutate(combinedUpdatedOrganization);
        return combinedUpdatedOrganization;
    };

    const hasValidPaymentMethod = useMemo(() => {
        switch (organization?.paymentType) {
            case EOrganizationPaymentType.CARD: {
                // TODO: should be checking the expiry here...
                return !!cards && cards?.length >= 0;
            }
            case EOrganizationPaymentType.EHF: {
                return !!(organization.ehfNumber && organization.ehfNumber !== '');
            }
            case EOrganizationPaymentType.E_INVOICE: {
                return !!(organization.invoiceEmail && organization.invoiceEmail !== '');
            }
            case EOrganizationPaymentType.POSTAL: {
                return true;
            }
            default: {
                return false;
            }
        }
    }, [cards, organization?.ehfNumber, organization?.invoiceEmail, organization?.paymentType]);

    const organizationBlocked = useMemo(
        () => organization?.status === EOrganizationStatus.BLOCKED,
        [organization?.status],
    );

    const organizationDeleting = useMemo(
        () => organization?.status === EOrganizationStatus.DELETE_REQUESTED,
        [organization?.status],
    );

    const canAddTags = useMemo(
        () => !organizationBlocked && !organizationDeleting && hasValidPaymentMethod,
        [hasValidPaymentMethod, organizationBlocked, organizationDeleting],
    );

    const canOrderTags = useMemo(
        () => !organizationBlocked && !organizationDeleting && hasValidPaymentMethod,
        [hasValidPaymentMethod, organizationBlocked, organizationDeleting],
    );
    const canUpdateTags = useMemo(() => canAddTags || canOrderTags, [canAddTags, canOrderTags]);

    const canInviteMembers = useMemo(
        () => !organizationBlocked && !organizationDeleting && hasValidPaymentMethod,
        [hasValidPaymentMethod, organizationBlocked, organizationDeleting],
    );

    const [hasExpiringPaymentCard, hasExpiredPaymentCard]: (boolean | undefined)[] = useMemo(() => {
        if (
            organization?.paymentType !== EOrganizationPaymentType.CARD ||
            organizationPaymentCardsLoading ||
            cards === null
        )
            return [undefined, undefined];

        return [isCardExpiring(cards?.[0]), isCardExpired(cards?.[0])];
    }, [cards, organizationPaymentCardsLoading, organization?.paymentType]);

    const isAddressRequired = useMemo(() => {
        const hasOrganization = !!organization;
        const isNotFamilyOrg =
            hasOrganization && organization.organizationClassification !== EOrganizationClassification.FAMILY;
        const hasMatchingPaymentType = hasOrganization
            ? [
                  EOrganizationPaymentType.EHF,
                  EOrganizationPaymentType.E_INVOICE,
                  EOrganizationPaymentType.POSTAL,
                  EOrganizationPaymentType.CARD,
              ].includes(organization.paymentType ?? EOrganizationPaymentType.NONE)
            : false;
        const isMissingBusinessAddress = hasOrganization
            ? !organization.businessAddress?.addressLine1 ||
              !organization.businessAddress?.city ||
              !organization.businessAddress?.postalCode ||
              !organization.businessAddress?.country
            : false;
        const isMissingInvoiceAddress = hasOrganization
            ? !organization?.invoiceAddress?.addressLine1 ||
              !organization.invoiceAddress?.city ||
              !organization.invoiceAddress?.postalCode ||
              !organization.invoiceAddress?.country
            : false;
        return (
            hasOrganization &&
            isNotFamilyOrg &&
            hasMatchingPaymentType &&
            (isMissingBusinessAddress || isMissingInvoiceAddress)
        );
    }, [organization]);

    const loading = organizationPaymentCardsLoading || organizationLoading;

    return {
        canAddTags,
        canInviteMembers,
        canOrderTags,
        canUpdateTags,
        error,
        hasExpiredPaymentCard,
        hasExpiringPaymentCard,
        hasValidPaymentMethod,
        isAddressRequired,
        loading,
        organization,
        organizationBlocked,
        organizationDeleting,
        update,
    };
}
