import { colorNeutralsWhite } from '~styles/variables';
import React from 'react';

type Props = {
    color?: string;
    height?: string;
    width?: string;
};

const MerLogotype: React.FC<Props> = ({ color = colorNeutralsWhite, width = '33px', height = '11px' }: Props) => (
    <svg style={{ width, height, fill: color }} xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 11">
        <path d="M23.945 9.935c.238-.19.46-.4.66-.631a5.47 5.47 0 00.846-1.299h-.914c-.21.366-.472.698-.78.984-.36.318-.766.58-1.204.777a4.225 4.225 0 01-1.801.35 3.994 3.994 0 01-1.542-.301 4.248 4.248 0 01-1.292-.81 4.047 4.047 0 01-.881-1.248 4.621 4.621 0 01-.407-1.593l-.022-.303h9.209v-.237a5.42 5.42 0 00-.392-2.062 5.122 5.122 0 00-1.064-1.649A4.88 4.88 0 0022.762.826a5.281 5.281 0 00-4.018 0c-.601.253-1.146.625-1.601 1.096a5.252 5.252 0 00-1.064 1.674 5.911 5.911 0 000 4.24c.246.62.607 1.187 1.064 1.67a4.8 4.8 0 001.6 1.096 5.13 5.13 0 002.02.398c.434.004.867-.044 1.29-.143a4.924 4.924 0 001.053-.377c.301-.149.586-.33.85-.538l-.011-.007zm-7.3-5.166c.076-.469.23-.921.454-1.339.224-.423.522-.801.88-1.115a4.26 4.26 0 011.236-.737 4.332 4.332 0 013.058 0c.448.173.863.423 1.227.739.351.315.641.693.857 1.116.218.422.36.88.42 1.354l.042.32h-8.223l.049-.338zM10.81 1.343c.35 0 .697.074 1.018.22.32.147.605.36.838.628.548.598.825 1.486.825 2.644v6.047h.936V4.73a5.775 5.775 0 00-.275-1.865 3.843 3.843 0 00-.766-1.34A3.246 3.246 0 0012.22.694a4.3 4.3 0 00-2.826 0A3.219 3.219 0 007.89 1.956l-.235.37-.234-.37A3.339 3.339 0 005.918.682a4.3 4.3 0 00-2.825 0 3.22 3.22 0 00-1.163.828c-.342.39-.604.845-.768 1.339a5.842 5.842 0 00-.273 1.866v6.167h.936V4.835c0-1.158.277-2.046.825-2.644a2.45 2.45 0 013.713 0c.548.598.826 1.486.826 2.644v6.047h.936V4.835c0-1.158.277-2.046.826-2.644a2.439 2.439 0 011.856-.848M30.445.446c-.48 0-.959.08-1.413.238a3.22 3.22 0 00-1.163.826c-.343.39-.604.845-.769 1.339a5.842 5.842 0 00-.273 1.865v6.168h.936V4.835c0-1.158.278-2.046.826-2.644a2.439 2.439 0 011.856-.848h1.716V.45L30.445.446z" />
    </svg>
);

export default MerLogotype;
