export interface ICoupons {
    coupons: ICoupon[];
    remainingAmount: number;
}

export interface ICoupon {
    amount: number;
    freeMinutes?: number;
    name: string;
    validFrom?: string;
    validTo?: string;
}

export interface IDiscount {
    description: string;
    kwhDiscount: number;
    minuteDiscount: number;
    name: string;
    validFrom: string;
    validTo?: string;
    volumeDiscount?: IVolumeDiscount;
}

export interface IVolumeDiscount {
    amount: number;
    discountLevels: {
        amount: number;
        discount: number;
        name: string;
    }[];
    level: string;
}

export enum EMembershipType {
    COOP = 'COOP',
    GO_EASY = 'GoEasy',
    LADEFORDEL = 'Ladefordel',
}

export interface IMembership {
    accountId?: string;
    coopId?: string;
    memberNumber: string;
    type: EMembershipType;
    validFrom: string;
    validTo: string;
}

export interface IOrganizationMembership {
    accountId?: string;
    coopId?: string;
    memberNumber?: string;
    type: EMembershipType;
    validFrom: string;
    validTo?: string;
}

export enum ECoopMembershipType {
    GK = 'GK',
}

export interface ICoopMembershipBonus {
    benefitDetails: [
        {
            baseAmount: number;
            benefitAmount: number;
            type: ECoopMembershipType;
        },
    ];
    coopId: string;
    day: number;
    memberId: string;
    month: number;
    timestamp: string;
    type: string;
    week: number;
    year: number;
}
