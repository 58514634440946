import { INavigationGroup, INavigationGroupLink } from '~components/navigation/NavigationGroup';
import { useTranslation } from 'react-i18next';
import { useUser } from '~contexts/User';
import IconBenefits from '~components/icons/IconBenefits';
import IconOverview from '~components/icons/IconOverview';
import IconStatistics from '~components/icons/IconStatistics';
import IconUser from '~components/icons/IconUser';
import NavigationGroupList from '~components/navigation/NavigationGroupList';
import NavigationLink from '~components/navigation/NavigationLink';
import React, { useMemo } from 'react';
import useNavigationSections from '~hooks/useNavigationSections';

export default function UserNavigation(): JSX.Element {
    const { t } = useTranslation('user');
    const { isFamilyAdmin } = useUser();
    const { userNavigation } = useNavigationSections();

    const navigationGroups: INavigationGroup[] = useMemo(() => {
        return [
            {
                title: t('navigation.account'),
                icon: <IconUser />,
                links: [
                    {
                        to: '/account/profile',
                        title: t('navigation.profile'),
                    },
                    {
                        to: '/account/vehicles',
                        title: t('navigation.vehicles'),
                    },
                    {
                        to: '/account/rfid',
                        title: t('navigation.rfidNetwork'),
                    },
                    {
                        to: '/account/payment',
                        title: t('navigation.payment'),
                    },
                    userNavigation?.showSubscriptions
                        ? {
                              to: '/account/subscriptions',
                              title: t('navigation.subscriptions'),
                          }
                        : null,
                    {
                        to: '/account/settings',
                        title: t('navigation.settings'),
                    },
                ].filter((l): l is INavigationGroupLink => !!l),
            },
        ];
    }, [t, userNavigation?.showSubscriptions]);

    return (
        <>
            <NavigationLink to={'/'} heading={t('navigation.home')} icon={<IconOverview />} exact />
            <NavigationLink
                to={'/charging-journey'}
                heading={isFamilyAdmin ? t('navigation.chargingJourneyFamilyAdmin') : t('navigation.chargingJourney')}
                icon={<IconStatistics />}
            />
            {userNavigation?.showBenefits ? (
                <NavigationLink
                    to={'/my-benefits'}
                    heading={isFamilyAdmin ? t('navigation.benefitsFamilyAdmin') : t('navigation.benefits')}
                    icon={<IconBenefits />}
                />
            ) : null}
            <NavigationGroupList groups={navigationGroups} />
        </>
    );
}
