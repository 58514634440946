import { Navigate } from 'react-router-dom';
import { useAuth } from '~contexts/Auth';
import { useTranslation } from 'react-i18next';
import FullPageWorkingIndicator from '~components/indicators/FullPageWorkingIndicator';
import React, { ReactElement } from 'react';
import useQueryParams from '~hooks/useQueryParams';

type AuthReturnPageQueryParams = {
    next?: string;
};

export default function AuthReturnPage(): ReactElement {
    const { t } = useTranslation(['common']);
    const { next } = useQueryParams<AuthReturnPageQueryParams>();
    const { isAuthenticated, isReady } = useAuth();

    return !isReady ? (
        <FullPageWorkingIndicator text={t('general.loadingProfile')} />
    ) : isAuthenticated === false ? (
        <Navigate to={`/login${next ? `?next=${next}` : ''}`} />
    ) : (
        <Navigate to={next ?? '/'} />
    );
}
