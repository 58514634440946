import { useLocation } from 'react-router-dom';
import FullPageWorkingIndicator from '~components/indicators/FullPageWorkingIndicator';
import React from 'react';

type WaitPageState = {
    message?: string;
};

export default function WaitPage(): JSX.Element {
    const location = useLocation();
    const { message } = (location.state as WaitPageState) ?? {};

    return <FullPageWorkingIndicator text={message} />;
}
