import React from 'react';
import SimpleCheckIcon from '~assets/icons/simple_check.svg';
import styled from '@emotion/styled';

import {
    colorNeutralsWhite,
    colorPrimaryMerBlue,
    font,
    screenWidthMini,
    screenWidthSmall,
    transitionSnappy,
} from '~styles/variables';

export enum EStepperStepStatus {
    CURRENT = 'isCurrent',
    DONE = 'isDone',
    NOT_STARTED = 'notStarted',
}

export type StepperStep = {
    status: EStepperStepStatus;
    step: number;
    title: string;
};

type StepperProps = {
    steps: StepperStep[];
};

export default function Stepper(props: StepperProps): JSX.Element {
    const { steps } = props;
    return (
        <Wrapper>
            {steps.map((item) => {
                return (
                    <Step key={`stepper-${item.step}`}>
                        {item.status === EStepperStepStatus.DONE && (
                            <Circle>
                                <SimpleCheckIcon />
                            </Circle>
                        )}
                        {item.status === EStepperStepStatus.CURRENT && (
                            <Circle className={'is-current'}>{item.step}</Circle>
                        )}
                        {item.status === EStepperStepStatus.NOT_STARTED && <Circle>{item.step}</Circle>}

                        {item.title}
                    </Step>
                );
            })}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 3.5rem;
    gap: 1rem;

    @media screen and (max-width: ${screenWidthSmall}) {
        margin: 0 auto;
        max-width: calc(100% - 2rem);
    }
`;

const Step = styled.div`
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 1rem;
    transition: background-color ${transitionSnappy};
    font-family: 'SharpSans Semibold', sans-serif;

    width: 100%;
    justify-content: stretch;

    @media screen and (min-width: ${screenWidthMini}) {
        width: auto;
    }
    @media screen and (min-width: ${screenWidthSmall}) {
        margin-right: 0;
        margin-bottom: 0;
    }

    & svg {
        width: 20px;
        height: 18px;
        fill: ${colorPrimaryMerBlue};
    }
`;

const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    width: 40px;
    height: 40px;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid ${colorPrimaryMerBlue};
    font-size: ${font.size.xl};
    font-family: 'SharpSans Bold', sans-serif;
    color: ${colorPrimaryMerBlue};

    &.is-current {
        background-color: ${colorPrimaryMerBlue};
        color: ${colorNeutralsWhite};
    }
`;
