import { EOrganizationClassification } from './Organization';
import Address from '~interfaces/Address';
import BankAccount from '~interfaces/BankAccount';
import UserSettings from '~interfaces/UserSettings';

export enum EUserPaymentType {
    // Frigg string payment types
    CARD = 'card',
    EHF = 'EHF',
    E_INVOICE = 'E-invoicing',
    KLARNA = 'klarna',
    NONE = 'none',
    POSTAL = 'Postal',
}

// We use 'user mode' vs 'user type' as at some point the user will be able to swap between roles into different UI modes
export enum EUserMode {
    // admin of a cooperative
    COOPERATIVE_ADMIN = 'COOPERATIVE_ADMIN',
    // admin of an organization
    COOPERATIVE_MEMBER = 'COOPERATIVE_MEMBER', // member of a FAMILY organization
    FAMILY_ADMIN = 'FAMILY_ADMIN',
    // not part of an organization
    FAMILY_MEMBER = 'FAMILY_MEMBER',
    // admin of a cooperative
    ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
    // admin of an organization
    ORGANIZATION_MEMBER = 'ORGANIZATION_MEMBER',
    // member of an organization
    SMB = 'SMB',
    USER = 'USER',
}

export enum EUserStatus {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    DELETE_REQUESTED = 'DELETE_REQUESTED',
}

export default interface IUser {
    accountClassification: EOrganizationClassification;
    accountNumber?: string;
    auth0UserId: string;
    bankAccount: BankAccount;
    businessAddress: Partial<Address>;
    businessPhone: string;
    email: string;
    firstName: string;
    id: number;
    invoiceAddress: Partial<Address>;
    lastName: string;
    paymentType: EUserPaymentType;
    privateAddress: Partial<Address>;
    privatePhone: string;
    salutation: string;
    status: EUserStatus;
    title: string;
    userSettings: UserSettings;
}
