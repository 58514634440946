import './i18n';
import { AUTH0_API_CLIENT_CONFIG } from '~@constants/authentication';
import { ApiProvider } from '~contexts/Api';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from '~contexts/Auth';
import { DeviceProvider } from '~contexts/Device';
import { Global } from '@emotion/react';
import { GlobalStyle } from '~styles/globalStyle';
import { ModalProvider } from '~contexts/Modal';
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import { StateMachineProvider, createStore } from 'little-state-machine';
import { UserProvider } from '~contexts/User';
import { createRoot } from 'react-dom/client';
import App from '~App';
import React, { useCallback } from 'react';
import ReactGA from 'react-ga4';
import RoutesPublic, { publicPaths } from './RoutesPublic';

const trackingArgs = {
    trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS4_ID || 'missing-google-analytics4-id',
    gtagOptions: { debug_mode: process.env.REACT_APP_GOOGLE_ANALYTICS4_DEBUG || false },
};

ReactGA.initialize([trackingArgs]);

createStore(
    {
        payment: {},
        productOrder: {},
    },
    {
        name: 'mer',
        middleWares: [],
        storageType: localStorage,
    },
);

const API_URL = process.env.REACT_APP_AORTA_GATEWAY || 'API URL IS NOT SET';
const API_VERSION = process.env.REACT_APP_AORTA_VERSION;

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <DeviceProvider>
        <StateMachineProvider>
            <Global styles={GlobalStyle} />
            <Router>
                <Routes>
                    {publicPaths.map((path) => (
                        <Route key={path} path={path} element={<RoutesPublic />} />
                    ))}
                    <Route path={'*'} element={<Auth0ProviderWithRedirectHandler />}></Route>
                </Routes>
            </Router>
        </StateMachineProvider>
    </DeviceProvider>,
);

function Auth0ProviderWithRedirectHandler(): JSX.Element {
    const navigate = useNavigate();

    const handleRedirect = useCallback(
        (appState?: AppState) => {
            if (appState?.returnTo && navigate) navigate(decodeURIComponent(appState.returnTo));
        },
        [navigate],
    );
    return (
        <Auth0Provider
            domain={AUTH0_API_CLIENT_CONFIG.domain}
            clientId={AUTH0_API_CLIENT_CONFIG.clientId}
            authorizationParams={{
                audience: AUTH0_API_CLIENT_CONFIG.audience,
                scope: AUTH0_API_CLIENT_CONFIG.scope,
                redirect_uri: AUTH0_API_CLIENT_CONFIG.redirect_uri,
            }}
            cacheLocation="localstorage"
            useRefreshTokens={true}
            onRedirectCallback={handleRedirect}
        >
            <AuthProvider>
                <ApiProvider apiVersion={API_VERSION} apiUrl={API_URL}>
                    <UserProvider>
                        <ModalProvider>
                            <App />
                        </ModalProvider>
                    </UserProvider>
                </ApiProvider>
            </AuthProvider>
        </Auth0Provider>
    );
}
