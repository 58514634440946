{
    "verifyPhone": {
        "heading": "Validér ditt mobilnummer",
        "sending": "Sending verification code...",
        "sent": "En kode er sendt til ditt nummer {{phoneNumber}}, Legg inn mottatt kode for å fortsette.",
        "send": "For å være sikker på at du er deg har vi sendt deg kode til nummer {{phoneNumber}}.",
        "majorError": "Det var et problem med din kode for verifisering.",
        "button": {
            "send": "Send kode",
            "sendBlocked": "Send kode på {{timeRemaining}} sekunder",
            "submit": "Fortsett",
            "cancel": "Lukk",
            "tryAgain": "Send kode på nytt",
            "tryAgainBlocked": "Send kode på nytt {{timeRemaining}} seconds"
        },
        "error": {
            "PHONE_NUMBER_INVALID": "Ditt mobilnummer er ugyldig",
            "PHONE_VALIDATION_EXPIRED": "Valideringen er utløpt",
            "PHONE_VALIDATION_INVALID": "Valideringskoden er ugyldig",
            "PHONE_VALIDATION_REQUIRED": "Valideringskode kreves",
            "PHONE_NUMBER_TOO_MANY_REQUESTS": "For mange forsøk, prøv igjen om 10 minutter"
        }
    }
}
