import { ETrackingAccountType, ETrackingEvent, ETrackingStep } from '~@types/tracking';
import { useCallback } from 'react';
import ReactGA from 'react-ga4';

type TrackingEventProps = {
    action?: string;
    category?: string;
    label?: string;
    value?: string | number;
};

type TrackingGA4EventProps = {
    action?: string;
    category?: string;
    label?: string;
    step?: ETrackingStep;
    type?: ETrackingAccountType;
    value?: string | number;
};

type UseAnalyticsResponse = {
    trackEvent: (event: ETrackingEvent, eventProps?: TrackingEventProps) => void;
    trackGA4Event: (event: ETrackingEvent, eventProps?: TrackingGA4EventProps) => void;
};

export default function useAnalytics(): UseAnalyticsResponse {
    const trackEvent = useCallback((event: ETrackingEvent, eventProps?: TrackingEventProps) => {
        const [category, ...action] = event.split('_');

        ReactGA.event('gHub_' + category, {
            category: action.join('_'),
            ...eventProps,
        });
    }, []);

    const trackGA4Event = useCallback((event: ETrackingEvent, eventProps?: TrackingGA4EventProps) => {
        ReactGA.event('gHub_' + event, {
            ...eventProps,
        });
    }, []);

    return {
        trackEvent,
        trackGA4Event,
    };
}
