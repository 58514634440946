import {
    colorNeutralsBlack,
    colorOnWhiteLightGrey,
    colorOnWhiteLighterGrey,
    screenWidthMedium,
} from '~styles/variables';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '~contexts/User';
import AccountNavigation, {
    EAccountNavigationVariant,
    TextNavLinksContainer,
} from '~components/navigation/AccountNavigation';
import AppGenericLinks from '~components/navigation/AppGenericLinks';
import CloseIcon from '~assets/icons/close.svg';
import MainNavigation from '~components/navigation/MainNavigation';
import MenuIcon from '~assets/icons/menu.svg';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

export default function MenuPanel(): JSX.Element {
    const { t } = useTranslation(['common']);
    const { merUser } = useUser();
    const { pathname } = useLocation();
    const [smallScreen, setSmallScreen] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(true); //todo: set this back to false
    const [currentPath, setCurrentPath] = useState<string>('');

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 1024) {
                setSmallScreen(true);
            } else {
                setSmallScreen(false);
                setOpen(false);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (open) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
    }, [open]);

    const toggleMobileMenuPanel = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (pathname !== currentPath) {
            setOpen(false);
            setCurrentPath(pathname);
        }
    }, [open, setOpen, pathname, currentPath]);

    return (
        <>
            {smallScreen ? (
                <ToggleMenu
                    onClick={() => toggleMobileMenuPanel()}
                    type={'button'}
                    title={t('userNavigation.toggleMenu')}
                    className={'toggle-menu-panel'}
                >
                    {open ? <CloseIcon /> : <MenuIcon />}
                </ToggleMenu>
            ) : null}
            <MenuContainer className={open ? 'open' : undefined} id={'menuContainer'}>
                <MenuContents className={smallScreen ? 'small' : undefined}>
                    {smallScreen && open ? (
                        <AccountNavigation
                            userName={merUser?.firstName + ' ' + merUser?.lastName}
                            userEmail={merUser?.email}
                            variant={EAccountNavigationVariant.SIMPLE}
                        />
                    ) : null}
                    <MainNavigation />
                    {smallScreen && open ? (
                        <>
                            <TextNavLinksContainer>
                                <AppGenericLinks />
                            </TextNavLinksContainer>
                        </>
                    ) : null}
                </MenuContents>
            </MenuContainer>
            {smallScreen && open ? <MenuBackground onClick={() => setOpen(false)} /> : null}
        </>
    );
}

const MenuBackground = styled.div`
    @keyframes fadeInBackground {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9001;
    background-color: rgba(33, 33, 33, 0.6);

    animation: fadeInBackground 150ms linear forwards;
`;

const MenuContainer = styled.div<{ isAnimating?: boolean }>`
    @keyframes showMobilePanel {
        from {
            right: -100%;
        }
        to {
            right: 0;
        }
    }

    min-width: 220px;
    background-color: ${colorOnWhiteLighterGrey};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    h3 {
        margin-left: 1rem;
    }

    nav {
        padding: 0 1rem;
    }

    @media screen and (max-width: ${screenWidthMedium}) {
        position: fixed;
        top: 0;
        right: 100%;
        padding-top: 4rem;
        height: 100vh;
        z-index: 9002;
        width: 100%;
        max-width: 375px;

        &.open {
            animation: showMobilePanel 150ms linear forwards;
            overflow: auto;
        }

        nav {
            padding: 0;
        }
    }
`;

const MenuContents = styled.div`
    @media screen and (max-width: ${screenWidthMedium}) {
        &.small {
            border-top: 1px solid ${colorOnWhiteLightGrey};
            width: 100%;
            height: 100%;
            overflow-y: auto;
            padding-bottom: 2rem;
        }
    }
`;

const ToggleMenu = styled.button`
    position: fixed;
    top: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    border: none;
    background: transparent;
    z-index: 9999;
    display: none;
    visibility: hidden;

    & svg {
        width: 24px;
        height: 24px;
        fill: ${colorNeutralsBlack};
    }

    @media screen and (max-width: ${screenWidthMedium}) {
        display: flex;
        visibility: visible;
    }
`;

const SelectRoleContainer = styled(TextNavLinksContainer)`
    padding-bottom: 1rem;
`;

const SelectRoleLink = styled.a`
    cursor: pointer;
`;
