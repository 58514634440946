import { Link } from 'react-router-dom';
import {
    colorNeutralsWhite,
    colorOnBlueLightBlue,
    colorPrimaryMerBlue,
    font,
    screenWidthMini,
    screenWidthSmall,
} from '~styles/variables';
import { useTranslation } from 'react-i18next';
import AppStore from '~assets/appstore.svg';
import MerLogo from '~components/visuals/MerLogo';
import MerLogotype from '~components/visuals/MerLogotype';
import PlayStore from '~assets/playstore.svg';
import React from 'react';
import styled from '@emotion/styled';

const Footer: React.FC = () => {
    const { t } = useTranslation(['common']);
    return (
        <Container>
            <Top>
                <LinkList>
                    <h4>{t('footer.heading.customerSupport')}</h4>
                    <Link to="/contact">{t('link.getInTouch')}</Link>
                    <Link to="/faq">{t('link.faq')}</Link>
                </LinkList>
                <MoreInfo>
                    <h4>{t('footer.heading.downloadTheApp')}</h4>
                    <StoreLinks>
                        <a href="https://play.google.com/store/apps/details?id=no.giantleap.gronn.kontakt">
                            <PlayStore />
                        </a>
                        <a href="https://apps.apple.com/no/app/mer-connect/id1011180245">
                            <AppStore />
                        </a>
                    </StoreLinks>
                </MoreInfo>
            </Top>
            <Bottom>
                <div>
                    <MerLogo size="16px" />
                    <MerLogotype />
                    <span>© Copyright {new Date().getFullYear()}</span>
                </div>
                <div>
                    <Link to="/terms">{t('link.terms')}</Link>
                    <Link to="/privacy">{t('link.privacy')}</Link>
                </div>
            </Bottom>
        </Container>
    );
};

const Container = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
    background-color: ${colorPrimaryMerBlue};
    color: ${colorNeutralsWhite};

    h4 {
        margin-bottom: 0.5rem;
        font-family: 'SharpSans Semibold', sans-serif;
    }

    a {
        display: block;
        color: ${colorNeutralsWhite};
        outline: none;
        -webkit-tap-highlight-color: transparent;
        transition: color 0.1s ease-in-out;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            background-color: ${colorPrimaryMerBlue};
        }

        & svg {
            fill: transparent;

            path:first-of-type {
                stroke: currentColor;
            }

            path:nth-of-type(2) {
                fill: currentColor;
            }
        }
    }
`;

const Top = styled.div`
    padding: 1rem;
    border-bottom: 1px solid ${colorOnBlueLightBlue};
    @media screen and (min-width: ${screenWidthMini}) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 2.5rem;
    }
    @media screen and (min-width: ${screenWidthSmall}) {
        width: 100%;
    }
`;

const LinkList = styled.div`
    margin-bottom: 1.5rem;
    @media screen and (min-width: ${screenWidthMini}) {
        margin-bottom: 0;
    }
    a {
        font-family: 'SharpSans Book', sans-serif;
        font-size: ${font.size.s};
        line-height: ${font.lineHeight.m};
    }
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 1rem;
    @media screen and (min-width: ${screenWidthMini}) {
        padding: 0.625rem 2.5rem;
    }
    font-size: ${font.size.xs};

    div:first-of-type {
        display: flex;
        align-items: baseline;
        gap: 5px;
    }
    div:last-child {
        display: flex;
        gap: 1rem;
    }
    @media screen and (min-width: ${screenWidthSmall}) {
        width: 100%;
    }
`;

const MoreInfo = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${screenWidthMini}) {
        align-items: flex-end;
    }
`;

const StoreLinks = styled.div`
    display: flex;

    a {
        width: 120px;
        height: 38px;
    }

    a:last-child {
        width: 106px;
        margin-left: 8px;
    }
`;

export default Footer;
