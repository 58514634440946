import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

type QueryParams = Record<string, string | string[]>;

export default function useQueryParams<Params extends QueryParams>(): Params {
    const search = useLocation().search;

    const queryParams: QueryParams = useMemo(() => {
        const searchParams = new URLSearchParams(search);
        const params: QueryParams = {};

        searchParams.forEach((value, key) => {
            params[key] = value;
        });

        return params;
    }, [search]);

    return queryParams as Params;
}
