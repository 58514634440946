import { Link } from 'react-router-dom';
import { colorNeutralsWhite, colorOnWhiteLightGrey, screenWidthSmall } from '~styles/variables';
import { useTranslation } from 'react-i18next';
import MerLogo from '~components/visuals/MerLogo';
import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

enum HeaderState {
    DEFAULT = 'default',
    PINNED = 'pinned',
}

export default function Header(): JSX.Element {
    const { t } = useTranslation(['common']);
    const [headerState, setHeaderState] = useState(HeaderState.DEFAULT);

    const handleScroll = useCallback(() => {
        const offset = window.scrollY;
        setHeaderState((state) =>
            offset > 10 && state !== HeaderState.PINNED ? HeaderState.PINNED : HeaderState.DEFAULT,
        );
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <Container className={headerState}>
            <LogoLink to="/" title={t('link.title.home')}>
                <MerLogo size="64px" />
            </LogoLink>
        </Container>
    );
}

const Container = styled.header`
    background-color: ${colorNeutralsWhite};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: box-shadow 0.67s ease-in-out;

    z-index: 9000;
    grid-area: header;

    @media screen and (min-width: ${screenWidthSmall}) {
        border-bottom: 1px solid ${colorOnWhiteLightGrey};
        z-index: 7000;

        &&.pinned {
            box-shadow: 0 1px 1px rgba(32, 32, 32, 0.1);
        }
    }
`;

const LogoLink = styled(Link)`
    display: inline-block;
    margin: 0;
    height: 64px;
`;
