export enum ELanguage {
    EN = 'en',
    NO = 'no',
    SV = 'sv',
}

export enum WidgetVariant {
    NORMAL,
    COMPACT,
}

export enum UserRole {
    DRIVER = 'ROLE_DRIVER',
    FLEET_MANAGER = 'ROLE_FLEET_MANAGER',
    GROA_USER = 'ROLE_GROA_USER',
}

export interface SubColor {
    hex: string;
    name: string;
}

export interface ColorGroup {
    extraClass?: string;
    groupName: string;
    subColors: SubColor[];
}

export interface RadioButtonType {
    id: string;
}

export interface RegistrationNavigationProps {
    back?: string;
    next: string;
    onBack?: () => void;
}

export enum UIState {
    CLOSED = 'UISTATE_CLOSED',
    OPEN = 'UISTATE_OPEN',
}

export enum ToggleState {
    OFF,
    ON,
}

export enum ButtonVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TEXT = 'text',
}

export enum ButtonSize {
    DEFAULT = 'default',
    SMALL = 'small',
}

export enum ButtonType {
    BUTTON = 'button',
    RESET = 'reset',
    SUBMIT = 'submit',
}

export enum Membership {
    ACTIVE,
    NOT_ACTIVE,
    CANCELLED,
}

export enum FreeChargeMembershipType {
    MONTHLY = 'MONTHLY',
    ONE_TIME = 'ONE_TIME',
}

export enum LogStatus {
    NO_CONTACT,
    OK,
}

export enum VolumeDiscountLevel {
    LEVEL_0,
    LEVEL_1,
    LEVEL_2,
}

export enum EStatisticFlag {
    CHARGING_TIME = 'CHARGING_TIME',
    CO2_SAVING = 'CO2_SAVING',
    ENERGY_CONSUMPTION = 'ENERGY_CONSUMPTION',
    MONETARY_COST = 'MONETARY_COST',
    MONETARY_COST_NO_VAT = 'MONETARY_COST_NO_VAT',
    MONETARY_SAVING = 'MONETARY_SAVING',
    MONETARY_SAVING_NO_VAT = 'MONETARY_SAVING_NO_VAT',
}

export enum EStatistic {
    CHARGING_TIME = 'chargingTime',
    CO2_SAVING = 'co2Saving',
    ENERGY_CONSUMPTION = 'energyConsumption',
    MONETARY_COST = 'monetaryCosts',
    MONETARY_COST_NO_VAT = 'monetaryCostsNoVat',
    MONETARY_SAVING = 'monetarySavings',
    MONETARY_SAVING_NO_VAT = 'monetarySavingsNoVat',
    TOTAL_CHARGING_AMOUNT = 'totalChargingAmount',
    TOTAL_ENERGY = 'totalEnergy',
    TOTAL_REIMBURSEMENT = 'totalReimbursement',
}

export enum EChargingAccessCategory {
    BUSINESS = 'BUSINESS',
    HOME = 'HOME',
    PUBLIC = 'PUBLIC',
}

export enum EStatisticAccessCategory {
    BUSINESS = 'business',
    PRIVATE = 'private',
    PUBLIC = 'public',
}

export enum EDatePeriod {
    ALL = 'all',
    MONTH = 'month',
    YEAR = 'year',
}

export const MonthsConvertedToAPIFormat = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export enum Locale {
    ENGLISH = 'en-gb',
    NORWEGIAN = 'no-no',
    SWEDISH = 'sv-se',
}

export enum EInvitationStep {
    ACCEPT = 'accept',
    ACCEPT_LOGIN = 'accept_login',
    AUTHENTICATE = 'authenticate',
    COMPLETE = 'complete',
    CONTINUE = 'continue',
    ERROR = 'error',
    EXPIRED = 'expired',
    INVALID = 'invalid',
    NEXT = 'next',
    REGISTER = 'register',
    SKIP_ACCEPT = 'skip_accept',
}

export enum EInvitationActions {
    LOGIN = 'login',
    REGISTER = 'register',
}

export enum EOrganizationRegistrationStep {
    CANCEL = 'cancel',
    COMPLETE = 'complete',
    CONTACT = 'contact',
    CONTACT_SAVE = 'contactSave',
    CONTINUE = 'continue',
    ERROR = 'error',
    PAYMENT = 'payment',
    PROFILE = 'profile',
    PROFILE_SAVE = 'profileSave',
}

export enum ECooperativeRegistrationStep {
    ACCEPT_INVITATION = 'accept',
    AUTHENTICATE = 'authenticate',
    CANCEL = 'cancel',
    COMPLETE = 'complete',
    CONTACT = 'contact',
    CONTINUE = 'continue',
    ERROR = 'error',
    PAYMENT = 'payment',
    PROFILE = 'profile',
}

export enum EUserDetail {
    ADDRESS = 'address',
    PAYMENT = 'payment',
    PAYMENT_CARD_EXPIRED = 'paymentCardExpired',
    PAYMENT_CARD_EXPIRING = 'paymentCardExpiring',
    PHONE = 'phone',
}

export enum EOrganizationDetail {
    PAYMENT = 'payment',
    PAYMENT_CARD_EXPIRED = 'paymentCardExpired',
    PAYMENT_CARD_EXPIRING = 'paymentCardExpiring',
}

export enum ECoopMembershipAction {
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
}
