{
    "component": {
        "chargeDetailsGraph": {
            "heading": "",
            "noData": {
                "title": "Du har ikke ladet hos Mer i denne perioden",
                "message": "Når du lader får du statistikk og oversikt her"
            }
        }
    },
    "navigation": {
        "home": "Oversikt",
        "account": "Kundeforhold",
        "profile": "Personlig informasjon",
        "payment": "Betaling og faktura",
        "rfidNetwork": "Ladebrikker og nettverk",
        "subscriptions": "Abonnementer",
        "settings": "Kontoinnstillinger",
        "vehicles": "Kjøretøy",
        "chargingJourney": "Min ladereise",
        "chargingJourneyFamilyAdmin": "Din ladereise",
        "benefits": "Mine fordeler",
        "benefitsFamilyAdmin": "Dine fordeler"
    }
}
