import { Navigate } from 'react-router-dom';
import FullPageWorkingIndicator from '~components/indicators/FullPageWorkingIndicator';
import React, { useEffect } from 'react';
import useQueryParams from '~hooks/useQueryParams';

type AuthRedirectPageQueryParams = {
    next?: string;
};

export default function AuthRedirectPage(): JSX.Element {
    const { next } = useQueryParams<AuthRedirectPageQueryParams>();
    const externalRedirect = next?.startsWith('http');

    useEffect(() => {
        if (externalRedirect && next?.length) window.location.href = next;
    }, [externalRedirect, next]);

    return externalRedirect ? <FullPageWorkingIndicator /> : <Navigate to={next ?? '/'} />;
}
