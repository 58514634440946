export const downloadUrl = (url: string, name: string): void => {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        }),
    );
    document.body.removeChild(link);
    setTimeout(() => URL.revokeObjectURL(url), 1000);
};
