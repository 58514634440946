import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FullPageWorkingIndicator from '~components/indicators/FullPageWorkingIndicator';
import React, { useEffect, useMemo, useState } from 'react';
import useAuth from '~hooks/useAuth';
import useLocalState from '~hooks/useLocalState';
import useQueryParams from '~hooks/useQueryParams';

type LogoutPageState = {
    login_hint?: string;
    next?: string;
    screen_hint?: string;
};

export default function LogoutPage(): JSX.Element {
    const { t } = useTranslation(['common']);
    const location = useLocation();
    const locationState = useMemo(() => (location.state as LogoutPageState) ?? {}, [location.state]);

    const queryParams = useQueryParams<LogoutPageState>();
    const { auth0Logout } = useAuth();
    const {
        actions: { clear },
    } = useLocalState();
    const [performingLogoutRequest, setPerformingLogoutRequest] = useState(false);

    useEffect(() => {
        if (!performingLogoutRequest) {
            setPerformingLogoutRequest(true);
            // Clear local state
            clear(undefined);
            // Logout
            auth0Logout({
                ...queryParams,
                ...locationState,
            });
        }
    }, [auth0Logout, clear, locationState, performingLogoutRequest, queryParams]);

    return <FullPageWorkingIndicator text={t('busy.loading.data')} />;
}
