// Primary
export const colorPrimaryMerBlue = '#0000c2';
export const colorPrimaryMerTurquoise = '#14e6e6';

// Secondary
export const colorSecondaryRed = '#e51c5c';
export const colorSecondaryLightTurquoise = '#6ffeff';
export const colorSecondaryDarkTurquoise = '#3b9ac3';

// Neutrals
export const colorNeutralsBlack = '#212121';
export const colorNeutralsWhite = '#ffffff';
export const colorNeutralsLighterBlue = '#deedfd';

// On white
export const colorOnWhiteDarkGrey = '#767676';
export const colorOnWhiteLightGrey = '#cccccc';
export const colorOnWhiteLighterGrey = '#f9f9f9';

// On blue

export const colorOnBlueLightBlue = '#4954f2';
export const colorOnBlueDarkGrey = '#767676';
export const colorOnBlueWhite = '#ffffff';

// Illustration
export const colorIllustrationGreen = '#14e664';
export const colorIllustrationDarkGreen = '#327290';
export const colorIllustrationPink = '#e614aa';
export const colorIllustrationDarkPink = '#6922af';
export const colorIllustrationYellow = '#fff61e';
export const colorIllustrationDarkYellow = '#e8984c';
export const colorIllustrationBlue = '#000d82';
export const colorIllustrationDarkBlue = '#000d82';
export const colorIllustrationDarkerBlue = '#00044a';
export const colorIllustrationLightBlue = '#b1d4f8';
export const colorIllustrationLighterBlue = '#deedfd';

// Status
export const statusActive = '#4596F7';
export const statusCancelled = '#9A9999';

// Border radius
export const borderRadiusXs = '0.125rem'; // 2px
export const borderRadiusS = '0.25rem'; // 4px
export const borderRadiusM = '0.5rem'; // 8px

// Other
export const colorModalOverlay = 'rgba(33, 33, 33, 0.6)';
export const busyIndicatorMerTheme = {
    from: '#0000C8',
    to: '#10ECEC',
};
export const busyIndicatorGreyTheme = {
    from: '#ccc',
    to: '#ffffff00',
};

export const colorGrey = '#333333';

export const shadowBlue = '0px 0px 4px 2px #b1d4f8';
export const shadowBlueSharp = '0 0 0 4px #b1d4f8';
export const shadowRedSharp = '0 0 0 1px #e51c5c';

export const spaceXxs = '0.25rem'; // 4px
export const spaceXs = '0.5rem'; // 8px
export const spaceS = '1rem'; // 16px
export const spaceM = '1.5rem'; // 24px
export const spaceL = '2rem'; // 32px
export const spaceXl = '3rem'; // 48px

// Screen Widths
export const screenWidthMinimum = '320px';
export const screenWidthMini = '500px';
export const screenWidthSmall = '768px';
export const screenWidthMedium = '1024px';
export const screenWidthLarge = '1200px';
export const maxWidthContent = '920px';

export const readingWidth = '600px';

export const errorColor = colorSecondaryRed;
export const colorLighterBlue = '#deedfd';

export const lightRed = '#fad2de';
export const lightRedHover = '#ee9eb8';

export const redIconButton = lightRed;
export const redIconButtonHover = lightRedHover;

export const iconSizeS = '0.5rem';
export const iconSize = '1.5rem';
export const iconSizeM = '3.5rem'; // 56px

// Font Weights
export const font = {
    weight: {
        light: 300,
        regular: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
    },
    size: {
        mainHeadline: '2.2rem',
        xxl: '2rem',
        xl: '1.5rem',
        l: '1.25rem',
        m: '1rem',
        s: '0.875rem',
        xs: '0.75rem',
        xxs: '0.625rem',
    },
    lineHeight: {
        xxxl: '3rem',
        xxl: '2.5rem',
        xl: '2rem',
        l: '1.5rem',
        m: '1.25rem',
        s: '1rem',
    },
    body: "'SharpSans Medium', sans-serif",
    color: {
        default: colorNeutralsBlack,
        grey: colorOnWhiteDarkGrey,
    },
};

// Transitions
export const transitionDefault = '0.3s ease-in-out';
export const transitionSnappy = '0.1s ease-in 25ms';
