import { downloadUrl } from '~utils/download';
import { useCallback, useState } from 'react';
import { useUser } from '~contexts/User';
import ApiError from '~classes/ApiError';
import IRefund, { ERefundPeriod } from '~interfaces/Refund';
import useApi from '~contexts/Api';
import useSWR from 'swr';

export type UseOrganizationRefundsResponse = {
    download: (fileName: string) => Promise<void>;
    downloading: boolean;
    error: ApiError | undefined;
    loading: boolean | undefined;
    refunds: IRefund[];
};

export type UseOrganizationRefundsProps = {
    organizationId?: string;
    refundPeriod?: ERefundPeriod;
};

const REFUND_DOWNLOAD_PERIOD: Record<ERefundPeriod, string> = {
    [ERefundPeriod.CURRENT]: 'C',
    [ERefundPeriod.PREVIOUS]: 'P',
};

const BASE_PATH = '/organizations';

export default function useOrganizationRefunds(props?: UseOrganizationRefundsProps): UseOrganizationRefundsResponse {
    const { activeOrganizationId } = useUser();
    const { organizationId = activeOrganizationId, refundPeriod } = props ?? {};
    const { get } = useApi();
    const [downloading, setDownloading] = useState(false);

    const {
        data: refunds = [],
        error,
        isValidating: loading,
    } = useSWR<IRefund[], ApiError>(
        organizationId && refundPeriod ? `${BASE_PATH}/${organizationId}/refunds?refundPeriod=${refundPeriod}` : null,
        get,
        {
            revalidateOnFocus: false,
            errorRetryCount: 0,
        },
    );

    const download = useCallback(
        async (fileName: string) => {
            if (!refundPeriod) return;
            setDownloading(true);
            const url = `/organizations/${organizationId}/facility/statistics/revenue/export?period=${REFUND_DOWNLOAD_PERIOD[refundPeriod]}`;

            try {
                const blob = await get<Blob>(`${url}`, {
                    raw: true,
                    headers: { Accept: '*' },
                });
                const fileUrl = URL.createObjectURL(blob);
                // This method forces the download
                downloadUrl(fileUrl, fileName);
            } catch (e) {
                console.error(e);
            } finally {
                setDownloading(false);
            }
        },
        [get, organizationId, refundPeriod],
    );

    return {
        refunds,
        error,
        loading,
        download,
        downloading,
    };
}
