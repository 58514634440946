import {
    colorOnWhiteLightGrey,
    maxWidthContent,
    readingWidth,
    screenWidthLarge,
    screenWidthMedium,
    screenWidthMini,
    screenWidthSmall,
} from '~styles/variables';
import styled from '@emotion/styled';

export const SectionLayout = styled.section`
    padding: 2rem 2rem;

    margin: 0 auto;
    max-width: calc(${maxWidthContent} + 4rem);

    @media screen and (max-width: ${screenWidthMini}) {
        padding: 2rem 1rem;
    }
`;

export const SectionWidthMaxWidth = styled(SectionLayout)`
    margin-left: auto;
    margin-right: auto;
    max-width: ${screenWidthLarge};
`;

export const SmallCenteredContent = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: ${screenWidthSmall};
`;

export const SectionReadingWidth = styled.div`
    max-width: ${readingWidth};
`;

export const SectionLayoutWithLeftNav = styled(SectionLayout)`
    @media screen and (min-width: ${screenWidthMedium}) {
        //padding-left: 3.5rem;
        display: grid;
        grid-template-columns: 1fr 3fr;
        column-gap: 5rem;
    }
    // @media screen and (min-width: ${screenWidthLarge}) {
    //     column-gap: 9rem;
    // }
`;

export const SectionCentered = styled.section`
    box-sizing: content-box;
    max-width: 560px;
    margin: 2.5rem auto 7.75rem auto;
    padding: 0 1.5em;
`;

export const Section = styled.section`
    margin-bottom: 2.5rem;
`;

export const TwoColumnLayout = styled.div`
    @media screen and (min-width: ${screenWidthSmall}) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1.5rem;
    }
`;

export const ThreeColumnLayout = styled.div`
    @media screen and (min-width: ${screenWidthSmall}) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 1rem;
    }
    @media screen and (min-width: ${screenWidthMedium}) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`;

export const KPIContainer = styled.div<{ margin?: string }>`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
    margin-bottom: ${(props) => (props.margin ? props.margin : '2.5rem')};
    min-height: 60px;
    align-items: flex-end;
    @media screen and (min-width: ${screenWidthMini}) {
        flex-wrap: nowrap;
    }
`;

export const Actions = styled.div<{ centered?: boolean; margin?: string; right?: boolean }>`
    display: flex;
    justify-content: ${(props) => (props.centered ? 'center' : props.right ? 'flex-end' : 'flex-start')};
    gap: 1rem;
    margin: ${(props) => (props.margin ? props.margin : '1.5rem 0 2.5rem 0')};
    width: 100%;
`;

export const HorizontalDivider = styled.hr`
    border: none;
    height: 1px;
    background-color: ${colorOnWhiteLightGrey};
    margin-bottom: 1rem;
`;

export const FormRow = styled.div<{ margin?: string }>`
    margin-bottom: ${(props) => (props.margin ? props.margin : '0')};
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${screenWidthMini}) {
        flex-direction: row;
        gap: 1rem;
    }
`;

export const FormRowActions = styled.div<{ margin?: string }>`
    margin-top: 1.5rem;
`;

export const FormSectionHeading = styled.h3<{ margin?: string }>`
    margin: ${(props) => (props.margin ? props.margin : '2.5rem 0 2rem 0')};
    line-height: 1.5rem;
`;

export const FormSectionDescription = styled.p<{ margin?: string }>`
    margin: ${(props) => (props.margin ? props.margin : '0 0 1.125rem 0')};
    white-space: pre-wrap;
`;

export const EllipsisText = styled.span`
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0.5rem;
    width: 100%;
`;

export const Divider = styled.hr`
    border: none;
    height: 0.0625rem;
    background-color: ${colorOnWhiteLightGrey};
`;

export const ActionButtons = styled.div<{ gap?: string; inline?: boolean; justify?: string; margin?: string }>`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: ${(props) => (props.gap ? props.gap : '1rem')};
    justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
    margin-bottom: ${(props) => (props.margin ? props.margin : '2.5rem')};

    button {
        justify-content: center;
    }

    @media screen and (min-width: ${screenWidthMini}) {
        flex-direction: ${(props) => (props.inline === false ? 'column' : 'unset')};
    }
`;
