{
    "translationsFor": "Mer Portal English",
    "form": {
        "input": {
            "inviteeEmail": {
                "invalid": "",
                "required": ""
            },
            "rfidSerialNumber": {
                "invalid": "",
                "required": "",
                "label": ""
            },
            "rfidDescription": {
                "invalid": "",
                "label": ""
            },
            "memberNumber": {
                "invalid": "",
                "required": ""
            },
            "useSameInformation": {
                "label": ""
            },
            "firstName": {
                "label": "",
                "invalid": "",
                "required": ""
            },
            "lastName": {
                "label": "",
                "required": ""
            },
            "privatePhone": {
                "label": "",
                "invalid": "",
                "required": ""
            },
            "email": {
                "label": "",
                "invalid": "",
                "required": ""
            },
            "addressLine1": {
                "label": "",
                "invalid": "",
                "required": ""
            },
            "postalCode": {
                "label": "",
                "invalid": "",
                "required": ""
            },
            "city": {
                "label": ""
            },
            "country": {
                "label": "",
                "required": ""
            },
            "useSameAddress": {
                "label": ""
            },
            "installationInstructions": {
                "label": "",
                "help": ""
            },
            "lastname": {
                "invalid": ""
            },
            "region": {
                "invalid": "",
                "required": ""
            },
            "vin": {
                "invalid": "",
                "required": ""
            },
            "volvoOrder": {
                "invalid": "",
                "required": ""
            },
            "vehicleLicencePlate": {
                "invalid": "",
                "label": ""
            },
            "vehicleDescription": {
                "required": "",
                "invalid": "",
                "label": ""
            },
            "paymentCardNumber": {
                "label": "",
                "invalid": ""
            },
            "paymentCardExpiry": {
                "label": "",
                "invalid": ""
            },
            "paymentCardCvc": {
                "label": "",
                "invalid": ""
            },
            "termsAccepted": {
                "required": "",
                "label": "",
                "labelLink": ""
            },
            "emailConsented": {
                "label": ""
            },
            "rfidChoice": {
                "required": "",
                "none": {
                    "label": ""
                },
                "order": {
                    "label": ""
                },
                "existing": {
                    "label": ""
                }
            }
        },
        "messages": {
            "input": {
                "required": "",
                "invalid": "",
                "unknown": ""
            }
        },
        "heading": {
            "contactInfo": "Kontaktinformasjon",
            "myVehicle": "",
            "charger": "",
            "consent": "",
            "choosePaymentMethod": "",
            "invoiceEmail": "",
            "paymentEhf": "",
            "companyInfo": "",
            "companyContactInfo": "",
            "paymentCard": ""
        },
        "description": {
            "charger": "",
            "choosePaymentMethod": "",
            "invoiceFee": "",
            "paymentCard": ""
        }
    },
    "modal": {
        "addFamily": {
            "heading": "Add family member",
            "bodyConsent": "You can add up to four family members. If the person you invite is a customer of Mer, they will be offered to be connected to your account. If they are not a customer of Mer, they will be invited to create a new account, already connected to you.",
            "bodyNoConsent": "Together we are Mer! Invite up to 4 members so you can save up a volume discount faster and get everything together on one invoice.",
            "info": "Once they have accepted the link or created a user, you will be charged for their usage on the next invoice",
            "checkboxLabel": "I agree to pay for these users",
            "buttonState1": "Send invitations by email",
            "textInputLabel": "Email address",
            "buttonAddAddresses": "Add more email addresses",
            "buttonState2": "Send invitations"
        },
        "close": "Close",
        "addChip": {
            "heading": "Add chip",
            "body": "Here you can add RFID chips from Mer and our partners. Remember to choose the right supplier in the list.",
            "button": "Activate chip"
        },
        "cancel": "Cancel",
        "editChip": {
            "heading": "RFID",
            "body": "Private chip for",
            "buttonMain": "Save changes",
            "buttonSecondary": "Remove this chip from my account"
        },
        "orderChip": {
            "heading": "Order new chip",
            "linkTitle": "Go to the profile page to change personal information. You will exit this flow.",
            "link": "Change personal information",
            "dropdownLabel": "Number of chips",
            "info": "The price for VARIABEL is VARIABEL kr, you will be charged on your next bill.",
            "button": "Order now"
        },
        "removeFamily": {
            "heading": "Remove family member",
            "body": "Are you sure you want to remove the link to",
            "info": "VARIABEL NAME has charged for VARIABEL AMOUNT, the amount will be charged to you via the invoice for VARIABEL MONTH",
            "button": "Remove link"
        },
        "removeOwnFamilyMembership": {
            "heading": "Remove family link?",
            "body": "Are you sure you want to cancel your membership in the family VARIABLE FAMILY NAME",
            "info": "You have charged for VARIABEL X NOK in VARIABEL MONTH, the amount will be charged Admin Family via invoice VARIABEL MONHT ",
            "button": "Remove me as a member"
        }
    },
    "general": {
        "numberOf": "",
        "shortMonth": "mth",
        "currency": "",
        "minutes": "minutes",
        "error": {
            "fetchingData": "",
            "tryAgain": "Try again"
        },
        "busy": {
            "fetchingData": ""
        },
        "carousel": {
            "next": "Next",
            "prev": "Previous",
            "selected": "Selected",
            "itemNumber": "Slide number"
        },
        "learnMore": "",
        "zeroPrice": ""
    },
    "userNavigation": {
        "roles": {
            "admin": "Administrator",
            "user": "User",
            "member": "Member of"
        },
        "links": {
            "userProfile": "My user profile",
            "faq": "FAQ",
            "support": "Support"
        },
        "closeMenu": "Close menu",
        "logOut": "Log out",
        "more": "More"
    },
    "page": {
        "myBenefits": {
            "coupons": {
                "addDiscount": {
                    "heading": "Discount codes",
                    "body": "You can add your discount codes in the field below",
                    "buttonText": "Activate discount code"
                },
                "coop": {
                    "blueBody": "By activating your Coop Membership at Mer, you can get an 8% discount paid as a bonus at Coop - every time you charge at one of Mer's public charging stations!",
                    "link": "",
                    "note": "You can find the number on your membership card (last 10 digits), in the Coop app, or on My Page at coop.no.",
                    "orderNow": "Order now",
                    "cancel": "Cancel",
                    "cancelBonus": "Cancel bonus saving",
                    "heading": "charging bonus",
                    "subHeading": "Coop membership bonus",
                    "memberBody": "Thank you for taking advantage of the membership bonus. You can see more information about your bonus savings at Coop.",
                    "buttonTextActive": "Cancel membership",
                    "body": "As a member of Coop, you get an 8% bonus every time you charge your electric car at our charging stations",
                    "buttonText": "Become a member"
                },
                "couponAmount": {
                    "validUntil": "Valid until",
                    "stackHeading": "Total for your active coupons",
                    "body": "You can use all or part of these at all our charging stations. We have over 220 charging stations in Norway, which makes it easy to charge along the road.",
                    "stackSubHeading": "Active coupons",
                    "leftOf": "left of",
                    "period": "Invoicing period"
                },
                "discount": {
                    "validUntil": "Valid until",
                    "body": "You get this benefit in addition to your other coupons when you charge in our charging network."
                },
                "freeCharge": {
                    "freeChargeMonthly": "Free charge every month",
                    "freeCharge": "Free charge",
                    "monthlyBody": "",
                    "oneTimeBody": "If you own your own charger, you'll get 60 minutes free charging."
                }
            },
            "heading": ""
        },
        "chargingJourney": {
            "chargeDetailRecords": {
                "price": "Price",
                "total": "Total",
                "saved": "Saved",
                "type": "Type",
                "effect": "kWh",
                "avgEffect": "Average effect",
                "parkingLot": "Parking lot",
                "heading": "",
                "location": "Location",
                "date": "Date",
                "chargingTime": "Charging time",
                "paid": "Paid"
            },
            "heading": "My Charging Journey",
            "subHeading": "Charging history",
            "downloadButtonText": "Download"
        },
        "home": {
            "monthlyCharging": {
                "urlHeading": "Monthly charging"
            },
            "lastChargingSessions": {
                "heading": "Last charging sessions"
            },
            "aside": {
                "heading1": "News for you",
                "heading2": "Your active benefits"
            },
            "heading": "Welcome to Mer",
            "nameInMenu": "Overview"
        },
        "myChargers": {
            "heading": "My Chargers",
            "myChargerItem": {
                "graphTitle": "Consumer overview",
                "listHeadings": {
                    "charingCode": "Charging code",
                    "location": "Location",
                    "parkingLot": "Parking lot",
                    "modell": "Modell",
                    "maxEffect": "Max effect",
                    "status": "Status",
                    "cooperativesPrice": "Cooperative price",
                    "pricePerKwh": "NOK per kWh"
                },
                "kpis": {
                    "totalThisMonth": "Total this month",
                    "totalChargingTime": "Total charging time",
                    "totalCost": "Total cost"
                }
            }
        },
        "contact": {
            "heading": ""
        },
        "faq": {
            "heading": ""
        },
        "noMatch": {
            "heading": "Wops! This page does not exist..."
        },
        "orderPayment": {
            "stepper": {
                "step1": "",
                "step2": "",
                "step3": ""
            },
            "heading": "",
            "title": "",
            "delivery": {
                "title": "",
                "deliveryMethod": "",
                "addressTitle": ""
            },
            "payment": {
                "title": ""
            },
            "completeOrder": "",
            "orderSummary": {
                "title": "",
                "orderValue": "",
                "delivery": "",
                "standardInstallation": "",
                "totalCost": "",
                "taxIncluded": "",
                "discount": "",
                "editOrder": "",
                "proceedButton": ""
            },
            "selectCharger": {
                "heading": "",
                "taxIncluded": ""
            },
            "operatingServices": {
                "heading": "",
                "listItem1": "",
                "listItem2": "",
                "listItem3": "",
                "listItem4": ""
            },
            "productDetails": {
                "technicalSpecifications": "",
                "installationInformation": ""
            }
        },
        "orderComplete": {
            "heading": "",
            "yourOrderNumber": "",
            "confirmation": "",
            "title": "",
            "message1": "",
            "message2": "",
            "goToHub": ""
        },
        "privacy": {
            "heading": ""
        },
        "account": {
            "subpage": {
                "info": {
                    "heading": ""
                },
                "rfid": {
                    "heading": "Charging chips and network",
                    "rfidTags": "Ladebrikker",
                    "introduction": "Here is an overview of your RFID chips. You can also order more if you need to.",
                    "orderNewTag": "Order new RFID chip",
                    "addExistingTag": "Add new RFID chip",
                    "rfidTag": {
                        "status": {
                            "active": "Active"
                        },
                        "edit": "Edit"
                    }
                },
                "payment": {
                    "heading": "Payment and invoice"
                },
                "subscriptions": {
                    "heading": "Subscriptions",
                    "other": "Other subscriptions"
                },
                "settings": {
                    "heading": "Account settings",
                    "login": {
                        "yourLogin": "Your ",
                        "introduction": "Mer uses Auth0 to give you a secure login. To change your login information, visit Auth0.",
                        "changeLogin": "Change login information at Auth0"
                    },
                    "language": {
                        "heading": "Language"
                    },
                    "agreement": {
                        "heading": "Agreement"
                    },
                    "deleteAccount": {
                        "heading": "Delete account",
                        "button": "Delete account"
                    },
                    "deleteFamilyAccount": {
                        "info": "You have X active member in your network. Before you delete your account, you must remove all of the active connections in RFID chis and network."
                    },
                    "personalData": {
                        "heading": "Privacy",
                        "introduction": "Her kan du laste ned alle dine personlige data samlet.",
                        "downloadAccountData": "Last ned alle kontoens ladedata (.csv)",
                        "downloadInvoices": "Last ned alle fakturaer (.csv)",
                        "downloadUserData": "Last ned all brukerdata (.csv)"
                    }
                }
            }
        },
        "invitation": {
            "loading": {
                "message": "Loading Invitation..."
            },
            "error": {
                "message": "An error occurred, the invitation my be invalid, please try again or request a new invitation"
            },
            "invalid": {
                "message": "The invitation is not valid, please request a new invitation"
            },
            "expired": {
                "message": "The invitation has expired or is no longer valid, please request a new invitation"
            },
            "accepting": {
                "message": "Accepting Invitation..."
            },
            "accepted": {
                "message": "The invitation has been accepted"
            },
            "declined": {
                "message": "The invitation has been declined"
            }
        },
        "registration": {
            "subpage": {
                "userService": {
                    "heading": ""
                },
                "memberService": {
                    "heading": ""
                },
                "companyContact": {
                    "heading": ""
                },
                "companyPayment": {
                    "heading": ""
                },
                "company": {
                    "heading": ""
                },
                "userPayment": {
                    "heading": ""
                },
                "user": {
                    "heading": ""
                }
            }
        },
        "terms": {
            "linkLeadIn": "",
            "link": "",
            "heading": ""
        }
    },
    "label": {
        "kpi": {
            "totalLastMonth": "",
            "totalChargingTime": "",
            "totalCost": "",
            "totalSavings": "",
            "chargedIn": "Charged in"
        },
        "userType": {
            "private": ""
        },
        "input": {
            "rfidState": {
                "none": "",
                "order": "",
                "existing": ""
            },
            "rfidSuppliers": {
                "mer": ""
            },
            "rfidSupplier": "",
            "rfidSerialNumber": "",
            "rfidDescription": "",
            "vehicleRegistration": "",
            "vehicleName": "",
            "firstName": "",
            "lastName": "",
            "phoneNumber": "",
            "email": "",
            "receiveOffersAndNewsViaEmail": "",
            "paymentMethod": {
                "card": "",
                "invoice": "",
                "ehf": ""
            },
            "paymentCardNumber": "",
            "paymentCardExpiry": "",
            "paymentCardCVC": "",
            "emailConfirmation": "",
            "companyNumber": "",
            "companyName": "",
            "companyPublic": "",
            "address": "",
            "postCode": "",
            "postArea": "",
            "country": ""
        }
    },
    "footer": {
        "heading": {
            "customerSupport": "Support",
            "downloadTheApp": "Download the app"
        }
    },
    "link": {
        "getInTouch": "Contact us",
        "faq": "FAQ",
        "terms": "",
        "privacy": "Privacy",
        "title": {
            "home": "Home",
            "profile": "Account"
        }
    },
    "payment": {
        "error": {
            "unknown": "",
            "existing": "",
            "cancelled": "",
            "error": "",
            "refused": ""
        },
        "challenge": {
            "redirect": "",
            "challenge": "",
            "identify": ""
        },
        "success": {
            "existing": "",
            "authorised": "",
            "pending": "",
            "received": ""
        },
        "label": {
            "existingCard": "",
            "updatePaymentMethod": ""
        }
    },
    "order": {
        "error": {
            "unknown": "",
            "missingProduct": ""
        }
    },
    "btn": {
        "text": {
            "saveChanges": "",
            "nextStep": "",
            "completeRegistration": "",
            "backToPayment": "",
            "goToContactPerson": "",
            "goToPayment": ""
        }
    }
}
