import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

export type AppGenericLinksProps = {
    children?: React.ReactNode | React.ReactNode[];
};

export default function AppGenericLinks(props: AppGenericLinksProps): JSX.Element {
    const { children } = props;
    const { t } = useTranslation(['common']);

    return (
        <>
            {children}
            <NavLink to="/faq" title={t('userNavigation.links.faq')} target={'_blank'} rel="noopener noreferrer">
                {t('userNavigation.links.faq')}
            </NavLink>
            <NavLink
                to="/support"
                title={t('userNavigation.links.support')}
                target={'_blank'}
                rel="noopener noreferrer"
            >
                {t('userNavigation.links.support')}
            </NavLink>
            <NavLink to={'/logout'} className={'logout'}>
                {t('userNavigation.logOut')}
            </NavLink>
        </>
    );
}
