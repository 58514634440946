import React from 'react';
import styled from '@emotion/styled';

type BusyElectricityProps = {
    size?: string;
};

export default function BusyVehicle(props: BusyElectricityProps): JSX.Element {
    const { size = '128px' } = props;
    return (
        <Box size={size}>
            <svg width="55" height="124" viewBox="0 0 55 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.7">
                    <path
                        id="busy-vehicle-gradient-box-one"
                        d="M4.83142 18.4581C4.72735 9.17983 12.0232 1.50221 21.2947 1.1333V121.705L14.1144 120.715C9.74557 119.304 6.61253 115.459 6.11291 110.895L4.97578 100.508L5.30216 89.9083V60.427L4.83142 18.4581Z"
                        fill="url(#busy-vehicle-gradient-one)"
                    />
                    <rect
                        id="busy-vehicle-gradient-box-two"
                        x="19.9893"
                        y="1.1333"
                        width="16.0134"
                        height="120.575"
                        fill="url(#busy-vehicle-gradient-two)"
                    />
                    <path
                        id="busy-vehicle-gradient-box-three"
                        d="M34.7881 1.46436C42.8842 1.64837 49.3452 8.27592 49.3231 16.3741L49.0704 108.906C49.0499 116.432 42.4762 122.26 35.0022 121.378L34.7881 1.46436Z"
                        fill="url(#busy-vehicle-gradient-three)"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M45.8056 117.886C49.136 115.422 49.876 100.987 49.876 100.987L49.6984 89.193L49.3431 39.1993L49.3431 12.4422C48.9729 7.16118 40.625 3.46447 40.625 3.46447C38.7753 2.0562 35.075 1.35207 35.075 1.35207L27.331 1.00106V1L27.3179 1.0007L27.3044 1V1.00106L19.5607 1.35207C19.5607 1.35207 15.8605 2.0562 14.0104 3.46447C14.0104 3.46447 5.66282 7.16118 5.29265 12.4422L5.29265 39.1993L4.9374 89.193L4.75979 100.987C4.75979 100.987 5.49976 115.422 8.82983 117.886C8.82983 117.886 11.7887 122.109 27.3179 121.759C42.8467 122.109 45.8056 117.886 45.8056 117.886Z"
                    stroke="#0000C2"
                />
                <path
                    d="M49.1709 12.9849C49.1709 12.9849 50.239 13.3375 50.5951 15.8061C50.5951 15.8061 51.3071 29.9122 50.9511 31.6754C50.9511 31.6754 50.0475 35.1783 49.2532 35.543"
                    stroke="#0000C2"
                />
                <path
                    d="M5.46484 12.9849C5.46484 12.9849 4.39675 13.3375 4.04072 15.8061C4.04072 15.8061 3.32866 29.9122 3.68469 31.6754C3.68469 31.6754 4.58794 35.1783 5.3826 35.543"
                    stroke="#0000C2"
                />
                <path
                    d="M4.78441 83.7271C4.78441 83.7271 2.74556 84.7004 2.74556 86.9416C2.74556 86.9416 0.443496 101.882 2.74556 104.497C2.74556 104.497 4.79153 107.204 5.47754 107.509"
                    stroke="#0000C2"
                />
                <path
                    d="M49.9042 83.7271C49.9042 83.7271 51.7165 84.6834 51.7165 86.8852C51.7165 86.8852 53.7628 101.564 51.7165 104.133C51.7165 104.133 49.8979 106.792 49.2881 107.092"
                    stroke="#0000C2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.46047 45.0575C4.46047 45.0575 0.999849 46.115 0.999849 49.6397C0.999849 50.3446 1.76887 50.3446 1.76887 50.3446L5.22949 48.9347L5.22949 44.7051L4.46047 45.0575Z"
                    stroke="#0000C2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M50.5394 45.0575C50.5394 45.0575 54 46.115 54 49.6397C54 50.3446 53.231 50.3446 53.231 50.3446L49.7704 48.9347V44.7051L50.5394 45.0575Z"
                    stroke="#0000C2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.7347 43.036V43.0794C26.834 43.0648 26.9538 43.0554 27.0822 43.0481C27.2108 43.0554 27.3307 43.0648 27.43 43.0794V43.036C30.8276 42.9836 40.6425 44.4679 40.6425 44.4679C43.7718 45.1621 44.1196 44.1207 44.1196 44.1207L46.9011 30.9301C47.2489 29.8887 46.9011 29.1944 46.9011 29.1944C43.8496 21.8819 30.6201 21.2554 27.43 21.2113V21.2106C27.43 21.2106 27.3063 21.2082 27.0822 21.2085C26.8584 21.2082 26.7347 21.2106 26.7347 21.2106V21.2113C23.5446 21.2554 10.3155 21.8819 7.26363 29.1944C7.26363 29.1944 6.91578 29.8887 7.26363 30.9301L10.0451 44.1207C10.0451 44.1207 10.3926 45.1621 13.5222 44.4679C13.5222 44.4679 23.3371 42.9836 26.7347 43.036Z"
                    stroke="white"
                    strokeWidth="1.032"
                />
                <path
                    d="M41.8531 4.45215C41.8531 4.45215 41.5521 4.72018 42.1541 5.79268C42.1541 5.79268 45.8907 11.8183 49.2881 13.6313"
                    stroke="white"
                    strokeWidth="0.794"
                />
                <path
                    d="M11.9732 4.52539C11.9732 4.52539 12.2561 4.78612 11.6906 5.82942C11.6906 5.82942 8.18336 11.6909 4.99454 13.4546"
                    stroke="white"
                    strokeWidth="0.794"
                />
                <path
                    d="M47.9961 113.79C47.9961 113.79 43.8565 115.994 43.7665 119.43"
                    stroke="white"
                    strokeWidth="1.032"
                />
                <path
                    d="M11.3389 119.901C11.3389 119.901 10.1292 115.678 6.63926 114.731"
                    stroke="white"
                    strokeWidth="1.032"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.92922 102.369L9.92922 55.6607C9.92922 51.9629 12.9847 48.9375 16.7192 48.9375L37.4464 48.9375C41.1809 48.9375 44.2363 51.9629 44.2363 55.6607L44.2363 102.369C44.2363 106.067 41.1809 109.092 37.4464 109.092H16.7192C12.9847 109.092 9.92922 106.067 9.92922 102.369Z"
                    stroke="white"
                    strokeWidth="1.032"
                />
                <defs>
                    <linearGradient
                        className="gradient"
                        id="busy-vehicle-gradient-one"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#0000C8" offset="0.2">
                            <animate attributeName="offset" values="0.2; 0.1; 0.2" dur="6s" repeatCount="indefinite" />
                        </stop>
                        <stop stopColor="#42FEFF" offset="0.8">
                            <animate attributeName="offset" values="0.8; 0.5; 0.8" dur="6s" repeatCount="indefinite" />
                        </stop>
                        <stop stopColor="#FFFFFF" offset="1.2">
                            <animate attributeName="offset" values="1.2; 0.6; 1.2" dur="6s" repeatCount="indefinite" />
                        </stop>
                    </linearGradient>
                    <linearGradient
                        className="gradient"
                        id="busy-vehicle-gradient-two"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#0000C8" offset="0.2">
                            <animate
                                attributeName="offset"
                                values="0.2; 0.1; 0.2"
                                dur="6s"
                                repeatCount="indefinite"
                                begin="1s"
                            />
                        </stop>
                        <stop stopColor="#42FEFF" offset="0.8">
                            <animate
                                attributeName="offset"
                                values="0.8; 0.5; 0.8"
                                dur="6s"
                                repeatCount="indefinite"
                                begin="1s"
                            />
                        </stop>
                        <stop stopColor="#FFFFFF" offset="1.2">
                            <animate
                                attributeName="offset"
                                values="1.2; 0.6; 1.2"
                                dur="6s"
                                repeatCount="indefinite"
                                begin="1s"
                            />
                        </stop>
                    </linearGradient>
                    <linearGradient
                        className="gradient"
                        id="busy-vehicle-gradient-three"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#0000C8" offset="0.2">
                            <animate
                                attributeName="offset"
                                values="0.2; 0.1; 0.2"
                                dur="6s"
                                repeatCount="indefinite"
                                begin="2s"
                            />
                        </stop>
                        <stop stopColor="#42FEFF" offset="0.8">
                            <animate
                                attributeName="offset"
                                values="0.8; 0.5; 0.8"
                                dur="6s"
                                repeatCount="indefinite"
                                begin="2s"
                            />
                        </stop>
                        <stop stopColor="#FFFFFF" offset="1.2">
                            <animate
                                attributeName="offset"
                                values="1.2; 0.6; 1.2"
                                dur="6s"
                                repeatCount="indefinite"
                                begin="2s"
                            />
                        </stop>
                    </linearGradient>
                </defs>
            </svg>

            <span />
        </Box>
    );
}

const Box = styled.div<{ size: string }>`
    @keyframes gradient-one {
        0% {
            transform: scaleX(0);
        }
        100% {
            transform: scaleX(100);
        }
    }

    @keyframes gradient-two {
        0% {
            height: 100%;
        }
        100% {
            height: 150%;
        }
    }

    @keyframes gradient-three {
        0% {
            height: 100%;
        }
        100% {
            height: 200%;
        }
    }

    width: ${(props) => props.size};
    height: ${(props) => props.size};
    display: flex;
    overflow-y: hidden;
    border-radius: 50%;
    position: relative;

    & svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        //.gradient {
        //    animation-timing-function: ease-in-out;
        //    animation-iteration-count: infinite;
        //    animation-direction: normal;
        //    animation-duration: 2000ms;
        //    fill: blue;
        //}
        //
        //#busy-vehicle-gradient-one {
        //    animation-name: gradient-one;
        //}
        //
        //#busy-vehicle-gradient-two {
        //    animation-name: gradient-two;
        //}
        //
        //#busy-vehicle-gradient-three {
        //    animation-name: gradient-three;
        //}
    }
`;
