{
    "component": {
        "chargeDetailsGraph": {
            "heading": "",
            "noData": {
                "title": "Du har ikke ladet hos Mer i denne perioden",
                "message": "Når du lader får du statistikk og oversikt her"
            }
        }
    }
}
