import { FieldError as HookFormFieldError } from 'react-hook-form';
import { colorSecondaryRed, font } from '~styles/variables';
import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from '@emotion/styled';

type FieldErrorProps = {
    className?: string;
    fieldErrors: HookFormFieldError;
    showIcon?: boolean;
};

export default function FieldError(props: FieldErrorProps): JSX.Element {
    const { fieldErrors, className } = props;
    const { t } = useTranslation(['common']);
    const type = fieldErrors?.type;
    const message = fieldErrors?.message;
    const getMessage = () => {
        switch (type) {
            case 'required':
                return message || t('form.messages.input.required');
            case 'match':
            case 'pattern':
                return message || t('form.messages.input.invalid');
            default:
                return message || t('form.messages.input.unknown');
        }
    };
    return <FieldErrorContainer className={className}>{getMessage()}</FieldErrorContainer>;
}

const FieldErrorContainer = styled.div`
    color: ${colorSecondaryRed};
    font-size: ${font.size.s};
`;
