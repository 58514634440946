import { EOrganizationClassification, EOrganizationType } from '~interfaces/Organization';
import { ERoleId } from '~interfaces/Invitation';
import { ETrackingEvent } from '~@types/tracking';
import { SWR_CONFIG_RETRY_LONG } from '~@constants/swr';
import { VISIBLE_ORGANIZATION_CLASSIFICATION_ROLES, userRoleConstraints } from '~@constants/roles';
import { organizationIdIsTemporary } from '~utils/organization';
import { useAuth } from '~contexts/Auth';
import { useCallback, useMemo } from 'react';
import { useUser } from '~contexts/User';
import ApiError from '~classes/ApiError';
import IUser, { EUserStatus } from '~interfaces/User';
import IUserRole, { ERoleStatus, UserRoleConstraint } from '~interfaces/UserRoles';
import useAnalytics from '~hooks/useAnalytics';
import useApi, { useApiPolling } from '~contexts/Api';
import useSWR, { mutate } from 'swr';

export type RoleConstraint = { constraint: UserRoleConstraint; role: IUserRole };

type UseRolesResponse = {
    error: ApiError | undefined;
    hasRoleConstraint: (
        roleId: ERoleId,
        organizationClassification?: EOrganizationClassification,
        organizationType?: EOrganizationType,
    ) => RoleConstraint | undefined;
    loading: boolean;
    remove: (id: string) => Promise<void>;
    roles?: IUserRole[];
};

export const BASE_PATH = '/users/me/roles';

export default function useRoles(): UseRolesResponse {
    const { isAuthenticated } = useAuth();
    const { isRegistered, isBlocked } = useUser();
    const { get, del } = useApi();
    const { poll } = useApiPolling();
    const { trackGA4Event } = useAnalytics();

    const {
        data,
        error,
        isValidating: loading,
        mutate: mutateRoles,
    } = useSWR<IUserRole[] | void, ApiError>(
        isAuthenticated && (isRegistered ?? false) ? BASE_PATH : null,
        get,
        SWR_CONFIG_RETRY_LONG,
    );

    const roles = useMemo(() => filterRoles(data), [data]);

    const remove: UseRolesResponse['remove'] = async (userRoleId) => {
        await del(`${BASE_PATH}/${userRoleId}`);
        await mutateRoles();
        if (isBlocked)
            await poll<IUser>(
                '/users/me',
                {},
                (error, result) => {
                    if (error) return true;
                    return result?.status !== EUserStatus.BLOCKED;
                },
                5,
                2000,
            );

        await mutate('/users/me');
        trackGA4Event(ETrackingEvent.MEMBERSHIP_OWN_REMOVE);
    };

    /**
     * A helper function that determines if the current user can become the provided role
     */
    const hasRoleConstraint = useCallback(
        (
            roleId: ERoleId,
            organizationClassification?: EOrganizationClassification,
        ): { constraint: UserRoleConstraint; role: IUserRole } | undefined => {
            const constraints =
                roles?.map((r) => {
                    const rule = userRoleConstraints.find(
                        (urc) =>
                            urc.roleId === r.roleId && urc.organizationClassification === r.organizationClassification,
                    );
                    const constraint = rule?.constraints.find(
                        (c) => c.roleId === roleId && c.organizationClassification === organizationClassification,
                    );
                    return constraint ? { constraint, role: r } : undefined;
                }) ?? [];
            return constraints.find((c) => !!c && !!c.constraint && !!c.role);
        },
        [roles],
    );

    return { roles, error, loading, remove, hasRoleConstraint };
}

export function filterRoles(roles: void | IUserRole[] | undefined) {
    return roles
        ? roles?.filter(
              (r) =>
                  r.status === ERoleStatus.ACTIVE &&
                  !organizationIdIsTemporary(r.organizationId) &&
                  VISIBLE_ORGANIZATION_CLASSIFICATION_ROLES.includes(r.organizationClassification),
          )
        : undefined;
}
