import Icon, { IconProps } from '~components/clickables/Icon';
import React from 'react';
import Svg from '~assets/icons/overview.svg';

export default function IconOverview(props: IconProps): JSX.Element {
    return (
        <Icon {...props}>
            <Svg />
        </Icon>
    );
}
