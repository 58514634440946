import ApiResponseError from '~interfaces/ApiResponseError';

export default class ApiError extends Error {
    name: string;
    payload: ApiResponseError;

    constructor(message: string, payload: ApiResponseError) {
        super(message);
        this.name = 'ApiError';
        this.payload = payload;
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}
