import React from 'react';

type Props = {
    size?: string;
};

const MerLogo: React.FC<Props> = ({ size = '64px' }: Props) => (
    <svg
        style={{ width: size, height: size, display: 'block' }}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 64 64"
    >
        <path fill="url(#mer_logo_paint0_linear)" d="M21.33 0H0v64h21.33V0z" />
        <path fill="url(#mer_logo_paint1_linear)" d="M42.67 0H21.33v64h21.34V0z" />
        <path fill="url(#mer_logo_paint2_linear)" d="M64 0H42.67v64H64V0z" />
        <defs>
            <linearGradient
                id="mer_logo_paint0_linear"
                x1="10.66"
                x2="10.66"
                y1="2.86"
                y2="61.74"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0000C8" />
                <stop offset=".17" stopColor="#0003C8" />
                <stop offset=".3" stopColor="#010DCA" />
                <stop offset=".43" stopColor="#021ECD" />
                <stop offset=".54" stopColor="#0436D0" />
                <stop offset=".65" stopColor="#0655D5" />
                <stop offset=".76" stopColor="#087BDB" />
                <stop offset=".87" stopColor="#0BA7E2" />
                <stop offset=".97" stopColor="#0FDAE9" />
                <stop offset="1" stopColor="#10ECEC" />
            </linearGradient>
            <linearGradient
                id="mer_logo_paint1_linear"
                x1="32"
                x2="32"
                y1="2.86"
                y2="61.74"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0000C8" />
                <stop offset="1" stopColor="#10ECEC" />
            </linearGradient>
            <linearGradient
                id="mer_logo_paint2_linear"
                x1="53.34"
                x2="53.34"
                y1="2.86"
                y2="61.74"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0000C8" />
                <stop offset=".03" stopColor="#0112CB" />
                <stop offset=".13" stopColor="#0545D2" />
                <stop offset=".24" stopColor="#0871D9" />
                <stop offset=".35" stopColor="#0A97DF" />
                <stop offset=".46" stopColor="#0CB6E4" />
                <stop offset=".57" stopColor="#0ECEE7" />
                <stop offset=".7" stopColor="#0FDFEA" />
                <stop offset=".83" stopColor="#10E9EC" />
                <stop offset="1" stopColor="#10ECEC" />
            </linearGradient>
        </defs>
    </svg>
);

export default MerLogo;
